import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tooltip from 'react-tooltip-lite';
import { applicationError } from '../../actions/notificationActions';
import { openModal } from '../../actions/modalActions';
import modalTypes from '../../constants/modalTypes';

const RoleToggler = ({
  user,
  roleLevel,
  handleRoleChange,
  roleLevelId,
  disabled,
  isSending,
  team,
  currentUser,
  handleAdminAssignment,
  applicationError,
  openModal
}) => {
  const [activeObserver, setActiveObserver] = useState(false);
  const [activeWorker, setActiveWorker] = useState(false);
  const [activeAdmin, setActiveAdmin] = useState(false);

  const [showObserverTooltip, setShowObserverTooltip] = useState(false);
  const [showWorkerTooltip, setShowWorkerTooltip] = useState(false);
  const [showAdminTooltip, setShowAdminTooltip] = useState(false);

  const teamRoleCondition = (role, roleType) =>
    role.type === roleType && role.team_id === roleLevelId;
  const orgRoleCondition = (role, roleType) =>
    role.type === roleType && role.organization_id === roleLevelId;

  useEffect(() => {
    let workerRole;
    let adminRole;
    let observerRole;

    if (roleLevel === 'team') {
      const { team_roles = [], organization_roles = [] } = user;

      workerRole = team_roles.find((role) => teamRoleCondition(role, 'Worker'));
      adminRole = team_roles.find((role) =>
        teamRoleCondition(role, 'Administrator')
      );
      observerRole = team_roles.find((role) =>
        teamRoleCondition(role, 'Observer')
      );
    } else if (roleLevel === 'organization') {
      const { organization_roles = [] } = user;
      workerRole = organization_roles.find((role) =>
        orgRoleCondition(role, 'Worker')
      );
      adminRole = organization_roles.find((role) =>
        orgRoleCondition(role, 'Administrator')
      );
      observerRole = organization_roles.find((role) =>
        orgRoleCondition(role, 'Observer')
      );
    }

    if (workerRole) setActiveWorker(true);
    else setActiveWorker(false);

    if (adminRole) setActiveAdmin(true);
    else setActiveAdmin(false);

    if (adminRole || workerRole || observerRole) setActiveObserver(true);
    else setActiveObserver(false);
  }, [user]);

  const handleButtonClick = (roleType) => {
    if (disabled || isSending) return;
    if (roleLevel === 'team' && roleType === 'Observer') {
      openModal(modalTypes.message, {
        message:
          'You cannot remove observer roles from team members; remove the members from the team instead.',
        actions: {},
        closeButtonTitle: 'Close',
        id: 'message'
      });
      return;
    }

    // prevent user from removing their team admin status unless they are an org admin
    if (
      roleLevel === 'team' &&
      roleType === 'Administrator' &&
      user.id === currentUser.id
    ) {
      const { organization_roles = [] } = user;
      const isOrgAdmin = !!organization_roles.find(
        (role) =>
          role.organization_id === team.organization_id &&
          role.type === 'Administrator'
      );
      if (roleLevel === 'team' && !isOrgAdmin) {
        const message = `Are you sure you want to remove your Team Administrator role for ${team.short_name}?`;
        const actions = {
          Confirm: {
            name: 'Confirm',
            function: () => handleRoleChange(user.id, roleType)
          }
        };

        openModal(modalTypes.message, {
          message,
          actions,
          closeButtonTitle: 'CANCEL',
          id: 'message'
        });

        return;
      }
    }

    // prevent user from removing their own org admin role
    // an org admin can only remove another org admin's role
    if (
      roleLevel === 'organization' &&
      (roleType === 'Administrator' || roleType === 'Observer') &&
      user.id === currentUser.id
    ) {
      openModal(modalTypes.message, {
        message:
          'Only another organization administrator can configure your organization roles.',
        actions: {},
        closeButtonTitle: 'Close',
        id: 'message'
      });
      return;
    }

    // renders confirmation message for organization admin roles
    if (roleType === 'Administrator' && roleLevel === 'organization') {
      return handleAdminAssignment(user, activeAdmin);
    }

    handleRoleChange(user.id, roleType);
  };

  const generateObserverClass = () =>
    activeObserver ? 'rt-observer assigned' : 'rt-observer';

  const generateWorkerClass = () =>
    activeWorker ? 'rt-worker assigned' : 'rt-worker';

  const generateAdminClass = () =>
    activeAdmin ? 'rt-admin assigned' : 'rt-admin';

  const isOrganization = roleLevel === 'organization';

  return (
    <div className={`role-toggler ${disabled ? 'disabled' : ''}`}>
      <Tooltip
        direction="up"
        isOpen={showObserverTooltip}
        className="role-tooltip"
        content={`Observer: ${
          isOrganization
            ? 'able to view all work items organization-wide'
            : 'able to view work assigned to the team'
        }`}
      >
        <div
          className={generateObserverClass()}
          onClick={() => handleButtonClick('Observer')}
          onMouseEnter={() => {
            if (!showObserverTooltip) {
              setShowObserverTooltip(true);
            }
          }}
          onMouseLeave={() => {
            if (showObserverTooltip) {
              setShowObserverTooltip(false);
            }
          }}
        >
          O
        </div>
      </Tooltip>
      {!isOrganization && (
        <Tooltip
          direction="up"
          isOpen={showWorkerTooltip}
          className="role-tooltip"
          content="Worker: able to own and modify work assigned to the team"
        >
          <div
            className={generateWorkerClass()}
            onClick={() => handleButtonClick('Worker')}
            onMouseEnter={() => {
              if (!showWorkerTooltip) {
                setShowWorkerTooltip(true);
              }
            }}
            onMouseLeave={() => {
              if (showWorkerTooltip) {
                setShowWorkerTooltip(false);
              }
            }}
          >
            W
          </div>
        </Tooltip>
      )}
      <Tooltip
        direction="up"
        isOpen={showAdminTooltip}
        className="role-tooltip"
        content={`Administrator: ${
          isOrganization
            ? 'able to manage teams, users and all work items organization-wide'
            : 'able to assign work, modify team, manage cadences'
        }`}
      >
        <div
          className={generateAdminClass()}
          onClick={() => handleButtonClick('Administrator')}
          onMouseEnter={() => {
            if (!showAdminTooltip) {
              setShowAdminTooltip(true);
            }
          }}
          onMouseLeave={() => {
            if (showAdminTooltip) {
              setShowAdminTooltip(false);
            }
          }}
        >
          A
        </div>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state) => ({ currentUser: state.userReducer.user });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      applicationError,
      openModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RoleToggler);

RoleToggler.propTypes = {
  user: PropTypes.object,
  roleLevel: PropTypes.string,
  handleRoleChange: PropTypes.func,
  roleLevelId: PropTypes.number,
  disabled: PropTypes.bool,
  isSending: PropTypes.bool
};
