import React from 'react';
import { Line } from '@vx/shape';
import { Text } from '@vx/text';
import { withTooltip, Tooltip, TooltipWithBounds } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import { taskId } from '../../utils/string';

const TOP_SPAN = 20;

class WorkDensity extends React.Component {
  constructor() {
    super();

    this.handleTooltip = this.handleTooltip.bind(this);
  }

  handleTooltip(event, datum) {
    const coords = localPoint(event.target.ownerSVGElement, event);
    this.props.showTooltip({
      tooltipLeft: coords.x + 100,
      tooltipTop: coords.y,
      tooltipData: datum
    });
  }

  render() {
    const {
      tooltipData,
      tooltipLeft,
      tooltipTop,
      tooltipOpen,
      hideTooltip,
      width = 400,
      height = 150,
      graphData = {},
      workPackage,
      tasks = []
    } = this.props;

    // Amount of days since work package was created
    const totalDays = Object.keys(graphData).length;
    // Size of x lines
    const xSize = totalDays > 0 ? (width - 5) / totalDays : 0;
    // Each up and down has an amount of days
    const dayLabels = [];
    let count = 0;
    let lastChange = 0;
    const graphValues = Object.values(graphData);
    const series = graphValues.reduce((data, work, index) => {
      // Has work, graph is up otherwise down
      const idle = Object.keys(work).length === 0;
      const graph_y = idle ? 70 : 20;
      if (index === 0) {
        data.push({ x: (index + 1) * xSize, y: graph_y, work });
      } else {
        const previousPlot = data[data.length - 1];

        if (graph_y !== previousPlot.y) {
          // this will be an up or down line.
          // store this X value to use as baseline for next
          data.push({
            x: index * xSize,
            y: graph_y,
            work:
              graph_y > previousPlot.y /* down line at right */
                ? previousPlot.work
                : work
          });
          // Count the amount of days on the top or bottom
          dayLabels.push({
            count,
            x: lastChange + (count * xSize) / 2, // calculate the position of text
            y: idle ? previousPlot.y : previousPlot.y + TOP_SPAN + 10,
            width: count * xSize
          });
          count = 0;
          lastChange = index * xSize;
        }
        if (index === graphValues.length - 1) {
          count += 1;
          dayLabels.push({
            count,
            x: lastChange + (count * xSize) / 2, // calculate the position of text
            y: idle ? 100 : TOP_SPAN + 10,
            width: count * xSize
          });
        }
        data.push({ x: (index + 1) * xSize, y: graph_y, work });
      }
      count += 1;
      return data;
    }, []);

    let lastXPoint = 0;
    let lastYPoint = series.length > 0 ? series[0].y : 0;
    const graph = [];
    series.forEach((day) => {
      graph.push(
        <Line
          key={Math.random()}
          from={{ x: lastXPoint, y: lastYPoint }}
          to={day}
          stroke="#000000"
          strokeWidth={2}
          onMouseOver={(e) => this.handleTooltip(e, day.work)}
          onMouseOut={() => hideTooltip}
          onFocus={(e) => this.handleTooltip(e, day.work)}
          onBlur={() => hideTooltip}
          style={{ cursor: 'pointer' }}
        />
      );
      lastXPoint = day.x;
      lastYPoint = day.y;
    });
    return (
      <>
        <strong>Work Density (Flow of Value)</strong>
        <div className="work-density-graph">
          <svg width={width} height={height}>
            <rect x={0} y={0} width={width} height={height} fill="#DBDCDD" />
            <g transform="translate(2.5, 20)">{graph}</g>
            {dayLabels.map((day, index) => (
              <Text
                key={`${day.x.toString() + day.y.toString()}-${Math.random()}`}
                x={day.x < 7 ? 7 : day.x + 2.5}
                y={day.y}
                textAnchor="middle"
                verticalAnchor="start"
                style={{ fontWeight: 'bold', fontSize: '10px' }}
              >
                {`${day.count}d`}
              </Text>
            ))}
          </svg>
          {tooltipOpen && Object.keys(tooltipData).length > 0 && (
            <TooltipWithBounds
              top={tooltipTop}
              left={tooltipLeft}
              style={{
                backgroundColor: '#FFF3C9',
                fontSize: '12px',
                width: 'auto',
                overflowWrap: 'normal'
              }}
            >
              {Object.keys(tooltipData).length > 0 && (
                <ul
                  style={{
                    listStyle: 'none',
                    paddingLeft: '5px',
                    paddingRight: '5px'
                  }}
                >
                  {Object.entries(tooltipData).map((work) => (
                    <li>
                      <span className="work-item">{work[0]}</span>: {work[1]}{' '}
                      hours
                    </li>
                  ))}
                </ul>
              )}
            </TooltipWithBounds>
          )}
        </div>
      </>
    );
  }
}

export default withTooltip(WorkDensity);
