import React from 'react';
import WallErrorBoundary from './WallErrorBoundary';

const ErrorBoundaryWrapper = (ChildComponent) => {
  class WrappedComponent extends React.Component {
    render() {
      return (
        <WallErrorBoundary>
          <ChildComponent {...this.props} />
        </WallErrorBoundary>
      );
    }
  }
  return WrappedComponent;
};

export default ErrorBoundaryWrapper;
