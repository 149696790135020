import React from 'react';
import { connect } from 'react-redux';
import { applicationError } from '../../actions/notificationActions';

class WallErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, showError: false };
  }

  async componentDidCatch(error) {
    this.setState({
      error,
      showError: true
    })
  }

  componentDidUpdate() {
    if (this.state.showError && this.state.error) {
      const { applicationError } = this.props;
      applicationError(this.state.error, true);
      this.setState({ error: null });
    }
  }

  render() {
    if (this.state.showError) {
      return <div className='component-replacement'>Content Unavailable</div>
    }

    return this.props.children;
  }
}

export default connect(null, { applicationError })(WallErrorBoundary);
