import React from 'react';
import PropTypes from 'prop-types';
import Default from '../../constants/defaults';
import StoryList from '../PlanWall/storyList';

const detailPlanTab = ({ unshipped, stories, deployment, add, remove }) => {
  const visibleFields = Default.tableFields[1].fields.filter(
    field => field.visible
  );
  return (
    <div className="table">
      <div className="col-sm-8 title-container">
        <StoryList
          results={unshipped}
          source="DeploymentDetail"
          fields={visibleFields}
          parentId={deployment.id}
          level={1}
        />
      </div>
      <div className="col-sm-4">
        <div className="panel">
          <div className="panel-heading">
            <div className="panel-title">
              Drag items here to add to this deployment
            </div>
          </div>
          <div className="panel-body">
            <StoryList
              results={stories}
              source="side"
              parentId={deployment.id}
              level={1}
              add={add}
              delete={remove}
              mini
            />
          </div>
        </div>
      </div>
    </div>
  );
};

detailPlanTab.propTypes = {
  unshipped: PropTypes.array,
  stories: PropTypes.array,
  deployment: PropTypes.object,
  add: PropTypes.func,
  remove: PropTypes.func
};

export default detailPlanTab;
