export const convertHexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

const brightness = (r, g, b) => {
  return (r * 299 + g * 587 + b * 114) / 1000;
  // returns a value from 0 to 255 that indicates brightness,
  // with zero being the darkest and 255 the brightest value.
};

export const isColorBright = (r, g, b, x = 123) => {
  return brightness(r, g, b) > x;
};
