import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import {
  authorizedWorkersForTeam,
  authorizedWorkersForProduct,
  authorizedOwnersForTeam,
  authorizedOwnersForProduct
} from '../utils/userHelpers';

const getUsers = (state) => state.userReducer.users;

const getTeams = (state) =>
  state.teamReducer.teams.filter((t) => !t.deleted_at);

const authorizationScopeFromResult = (_, result) => {
  if (result?.team_id) {
    return {
      scope: 'team',
      entityId: result.team_id
    };
  }
  if (result?.product_id) {
    return {
      scope: 'product',
      entityId: result.product_id
    };
  }
  return {
    scope: null
  };
};

const authorizedWorkerIds = (state, product) => {
  let { teams } = product;

  if (!teams.length) teams = getTeams(state);
  return teams.reduce((ids, team) => {
    return Array.from(new Set([...ids, ...team.authorized_result_owner_ids]));
  }, []);
};

const authorizedResultOwnerIdsForTeam = (state, team) => {
  const { members } = team;

  if (!members.length) return [];
  return members
    .filter((m) => team.authorized_result_owner_ids.some((oid) => oid === m.id))
    .map((m) => m.id);
};

const authorizedOwnerIds = (state, product) => {
  let { teams } = product;

  if (!teams.length) teams = getTeams(state);
  return teams.reduce((ids, team) => {
    return Array.from(new Set([...ids, ...team.authorized_product_owner_ids]));
  }, []);
};

const authorizedWorkers = (scope, entityId, users) => {
  if (!scope) return [];
  if (isEmpty(users)) return [];

  if (scope === 'team') {
    return authorizedWorkersForTeam(users, entityId);
  }

  if (scope === 'product') {
    return authorizedWorkersForProduct(users, entityId);
  }
};

const authorizedOwners = (scope, entityId, users) => {
  if (!scope) return [];
  if (isEmpty(users)) return [];

  if (scope === 'team') {
    return authorizedOwnersForTeam(users, entityId);
  }

  if (scope === 'product') {
    return authorizedOwnersForProduct(users, entityId);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const filterWorkersByAuthority = createSelector(
  [getUsers, authorizationScopeFromResult],
  (users, authorizationScope) => {
    const { scope, entityId } = authorizationScope;
    return authorizedWorkers(scope, entityId, users);
  }
);

export const filterWorkersByProductTeams = createSelector(
  [getUsers, authorizedWorkerIds],
  (users, workerIds) => {
    return users.filter(
      (user) => workerIds.includes(user.id) && !user.deleted_at
    );
  }
);

export const filterOwnersByTeam = createSelector(
  [getUsers, authorizedResultOwnerIdsForTeam],
  (users, workerIds) => {
    return users.filter(
      (user) => workerIds.includes(user.id) && !user.deleted_at
    );
  }
);

export const filterOwnersByAuthority = createSelector(
  [getUsers, authorizationScopeFromResult],
  (users, authorizationScope) => {
    const { scope, entityId } = authorizationScope;
    return authorizedOwners(scope, entityId, users);
  }
);

export const filterOwnersByProductTeams = createSelector(
  [getUsers, authorizedOwnerIds],
  (users, ownerIds) => {
    return users.filter(
      (user) => ownerIds.includes(user.id) && !user.deleted_at
    );
  }
);
