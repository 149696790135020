import React from 'react';
import modalTypes from '../../constants/modalTypes';

const Settings = ({ signOut, openModal }) => (
  <div className="dropdown btn-component-div">
    <button
      type="button"
      id="detail-dropdown"
      className="btn btn-link"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span className="glyphicon glyphicon-cog" />
    </button>
    <ul
      className="dropdown-menu dropdown-menu-right"
      aria-labelledby="dropdown"
    >
      <li
        onClick={() => {
          openModal(modalTypes.user, {}, 'user');
        }}
      >
        <button type="button" className="btn btn-link">
          Profile
        </button>
      </li>
      <li onClick={signOut}>
        <button type="button" className="btn btn-link">
          Sign Out
        </button>
      </li>
    </ul>
  </div>
);

export default Settings;
