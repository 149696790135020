import { createSelector } from 'reselect';
import { sortBy } from 'lodash';

const getSelectedSpace = (state) => state.workPackageReducer.selectedSpace;

const getTrash = (state) => state.trashReducer.showTrash;

const getTargets = (state) => state.targetReducer.targets;

const getVisibleTargets = createSelector(
  [getTargets, getTrash],
  (targets, showTrash) => {
    if (showTrash) {
      return sortBy(targets, 'id');
    }

    const undeletedTargets = targets.filter((t) => !t.deleted_at);
    return sortBy(undeletedTargets, 'id');
  }
);

export const filterTargetsBySpace = createSelector(
  [getSelectedSpace, getVisibleTargets],
  (selectedSpace, targets) =>
    targets.filter((target) => {
      if (selectedSpace.type === 'team') {
        const { product_ids } = selectedSpace;
        return product_ids ? product_ids.includes(target.product_id) : false;
      }
      if (selectedSpace.type === 'product') {
        return selectedSpace.id === target.product_id;
      }
    })
);
