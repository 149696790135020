import React, { useState, useEffect, useCallback } from 'react';

const useDropDownOpen = () => {
  const [open, setOpen] = useState(false);

  /*
   useCallback hook makes sure the below function is memoized.
   This is useful for making sure the same function object is passed into the
   below useEffect eventListeners between component rerenders
  */

  const handleWindowClick = useCallback(() => {
    setOpen(false);
  }, []);

  /*
   when user clicks off of dropdowns, the open value is set to false
   and the event listener is removed. Make sure that the container element
   within the component this hook is being used in has an onClick event listener
   with the function (e) => e.stopPropagation()

   That way, clicking in the dropdown doesn't trigger the window event. 
  */

  useEffect(() => {
    if (open) {
      window.addEventListener('click', handleWindowClick);
    } else {
      window.removeEventListener('click', handleWindowClick);
    }
  }, [open]);

  return { open, setOpen };
};

export default useDropDownOpen;
