import React from 'react';
import { connect } from 'react-redux';
import { applicationError } from '../../actions/notificationActions';
import { closeAllModals } from '../../actions/modalActions';

class ModalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, showError: false };
  }

  async componentDidCatch(error) {
    this.setState({
      error,
      showError: true
    });
  }

  componentDidUpdate() {
    if (this.state.showError) {
      const { closeAllModals, applicationError } = this.props;
      closeAllModals();
      applicationError(this.state.error);
      this.setState({ showError: false, error: null });
    }
  }

  render() {
    if (this.state.showError) {
      return null;
    }

    return this.props.children;
  }
}

export default connect(null, { applicationError, closeAllModals })(
  ModalErrorBoundary
);
