import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { IoCloseSharp } from 'react-icons/io5';
import { connect } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import {
  fetchUserInfo,
  uploadUserImage,
  updateCurrentUser,
  updateUser,
  resetPassword,
  confirmEmail,
  updateFormError,
  getAuthorizedProductIds
} from '../../actions/userActions';
import { getAllProducts } from '../../actions/productActions';
import { closeModal } from '../../actions/modalActions';
import { createUser } from '../../actions/organizationActions';
import Holon from '../Buttons/holon';
import modalTypes from '../../constants/modalTypes';
import Profile from './profile';
import Default from '../../constants/defaults';
import { filterTeamsByOrg } from '../../selectors/teamSelector';
import { filterProductsByOrg } from '../../selectors/productSelector';
import { allows } from '../../utils/authorization';

const UNASSIGNED_OBJECT = { short_name: '' };

class User extends React.Component {
  constructor(props) {
    super(props);

    const { user, isNew } = props;

    this.state = {
      colorPickerIsOpen: false,
      teamSelected: isNew ? UNASSIGNED_OBJECT : user.default_team,
      productSelected: isNew
        ? UNASSIGNED_OBJECT
        : user.default_product || UNASSIGNED_OBJECT,
      user: isNew ? this.generateNewUserTemplate() : { ...user },
      productOptions: [],
      userInitialsStatic: false,
      userNicknameStatic: false
    };
  }

  componentDidMount() {
    const { products, isNew, user } = this.props;
    if (!isNew) {
      this.props.getAuthorizedProductIds(this.state.user.id);
    }
    if (!products || products.length === 0) this.props.getAllProducts();
  }

  componentWillReceiveProps(nextProps) {
    const { user, isNew } = nextProps;

    if (!isEqual(this.props.user.teams, user.teams)) {
      this.props.getAuthorizedProductIds(user.id);
    }

    if (this.props.isFetching && !nextProps.isFetching && !isNew) {
      if (nextProps.formError) {
        // need to recalculate product options from state, not props on form error
        const { user: modifiedUser } = this.state;
        if (modifiedUser.default_team)
          this.populateProductOptions(modifiedUser.default_team);
      } else {
        const productOptions = this.productOptions(user);
        const defaultProduct = user.default_product
          ? user.default_product
          : UNASSIGNED_OBJECT;

        this.setState({
          user,
          productSelected: defaultProduct,
          productOptions
        });
      }
    }
    if (this.props.isUploading && !nextProps.isUploading && !isNew) {
      this.setState({ user, productSelected: user.default_product });
    }

    if (
      (!isEqual(
        this.props.user.authorizedProductIds,
        user.authorizedProductIds
      ) ||
        this.props.user.authorizedProductIds) &&
      !this.props.isFetching
    ) {
      const productOptions = this.productOptions(user);

      this.setState({ productOptions });
    }
  }

  generateNewUserTemplate = () => ({
    name: '',
    email: '',
    default_team: {},
    default_product: UNASSIGNED_OBJECT,
    nickname: '',
    initials: '',
    location: '',
    color: '',
    phone: '',
    picture: {
      thumbnail: null,
      url: ''
    }
  });

  productOptions = (user) =>
    user.default_team?.products?.filter((product) =>
      user.authorizedProductIds.includes(product.id)
    );

  onInputChange = ({ target }) => {
    let { value } = target;
    const { name } = target;
    const { isNew } = this.props;
    const { user, userInitialsStatic, userNicknameStatic } = this.state;
    const userCopy = { ...user };

    if (name === 'initials') {
      if (isNew) {
        if (!value) {
          this.setState({ userInitialsStatic: false });
        } else if (value && !userInitialsStatic) {
          this.setState({ userInitialsStatic: true });
        }
      }
      value = value.toLocaleUpperCase();
    }

    if (name === 'nickname' && isNew) {
      if (!value) {
        this.setState({ userNicknameStatic: false });
      } else if (value && !userNicknameStatic) {
        this.setState({ userNicknameStatic: true });
      }
    }

    userCopy[name] = value;

    this.setState({ user: userCopy });
  };

  onNameBlur = () => {
    if (!this.props.isNew) return;
    const { user, userInitialsStatic, userNicknameStatic } = this.state;

    if (userInitialsStatic && userNicknameStatic) return;

    const userCopy = { ...user };

    const splitName = user.name.split(' ');
    if (userInitialsStatic && !userNicknameStatic) {
      userCopy.nickname = user.name;
      this.setState({ user: userCopy });
    } else if (!userInitialsStatic && userNicknameStatic) {
      const initials = splitName.map((name) => name[0]).join('');
      const capitalInitials = initials.toLocaleUpperCase();
      userCopy.initials = capitalInitials.slice(0, 3);
      this.setState({ user: userCopy });
    } else if (!userInitialsStatic && !userNicknameStatic) {
      const initials = splitName.map((name) => name[0]).join('');
      const capitalInitials = initials.toLocaleUpperCase();
      userCopy.initials = capitalInitials.slice(0, 3);
      userCopy.nickname = user.name;
      this.setState({ user: userCopy });
    }
  };

  onSubmitProfile = (e) => {
    e.preventDefault();
    const { user } = this.state;
    const { parentId, isNew, editExistingUser, currentUser } = this.props;
    if (!this.validateFormData()) return;

    const userCopy = { ...user };

    // if creating a new profile
    if (isNew) {
      // if this is a new user w out a selected color
      // then assign one randomly
      if (userCopy.color === '') {
        const getRandomColor = () => {
          const n = (Math.random() * 0xfffff * 1000000).toString(16);
          return `#${n.slice(0, 6)}`;
        };
        userCopy.color = getRandomColor();
      }

      this.props.createUser(parentId, { user: userCopy });
      return;
    }
    // if updating a profile of another person
    if (editExistingUser && currentUser.id !== user.id) {
      this.props.updateUser({ user: userCopy }, user.id);
      return;
    }
    // if updating your own profile
    delete userCopy.saved_space_view;
    this.props.updateCurrentUser({ user: userCopy });
  };

  onCapacityChange = (e) => {
    const { name, value } = e.target;
    const { user } = this.state;
    user.capacities[name] = value;
    this.setState({ user });
  };

  closeForm = () => {
    this.props.closeModal(modalTypes.user, this.props.modalId || 'user');
  };

  validateFormData = () => {
    const {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
      initials,
      current_password
    } = this.state.user;
    const { isNew } = this.props;
    const errors = {};

    if (!email) errors.email = ['Email field is required.'];
    if (!name) errors.name = ['Name field is required.'];
    if (initials && initials.length > Default.USER_INITIALS_LEN)
      errors.initials = [
        `Too long; max ${Default.USER_INITIALS_LEN} characters allowed.`
      ];

    // if (isNew) {
    //   if (!password) errors.password = ['Password field is required'];
    //   if (password !== passwordConfirmation)
    //     errors.password_confirmation = [
    //       'Password and password confirmation do not match'
    //     ];
    //   if (!passwordConfirmation)
    //     errors.password_confirmation = ['Password confirmation is required.'];
    // } else

    const pattern =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]+$/;
    if (password || passwordConfirmation) {
      if (!password) errors.password = ['Password is required'];
      else if (password.length < 8)
        errors.password = ['Passwords must be at least 6 characters'];
      else if (!pattern.test(password))
        errors.password = [
          'Passwords must include a mix of symbols, letters, and numbers'
        ];
      if (password !== passwordConfirmation)
        errors.password_confirmation = [
          'Password and password confirmation do not match'
        ];
      if (!passwordConfirmation)
        errors.password_confirmation = ['Password confirmation is required.'];
    }

    this.props.updateFormError(errors);
    return Object.keys(errors).length === 0;
  };

  selectProduct = (productOption) => {
    const productId = productOption.value;

    if (productId === 'unassigned') {
      this.setState((prevState) => ({
        productSelected: UNASSIGNED_OBJECT,
        user: {
          ...prevState.user,
          default_product: UNASSIGNED_OBJECT
        }
      }));
      return;
    }

    const product = this.props.products.find((p) => p.id === productId);
    if (product) {
      this.setState((prevState) => ({
        productSelected: product,
        user: {
          ...prevState.user,
          default_product: product
        }
      }));
    }
  };

  populateProductOptions = (team) => {
    const { products } = team;
    const productRemains = products.some(
      (p) => p.id === this.state.user.default_product?.id
    );

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        default_team: team,
        default_product: productRemains
          ? prevState.user.default_product
          : UNASSIGNED_OBJECT
      },
      productOptions: team.products,
      productSelected: productRemains
        ? prevState.productSelected
        : UNASSIGNED_OBJECT
    }));
  };

  selectTeam = (teamOption) => {
    const { value } = teamOption;
    const { isNew, currentUser } = this.props;

    const org = currentUser.organizations[0]; // TODO - handle multiple organizations per user
    const orgAdmin = org && allows('administer', org, 'organization');

    let teams;

    if (value === 'unassigned') {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          default_team: {},
          default_product: UNASSIGNED_OBJECT
        },
        productOptions: [],
        productSelected: UNASSIGNED_OBJECT
      }));
      return;
    }

    if (isNew || orgAdmin) {
      teams = this.props.teams;
    } else {
      teams = this.state.user.teams;
    }

    const team = teams.find((t) => t.id === value);
    if (team) this.populateProductOptions(team);
  };

  addTeamToNewUser = (teamOption) => {
    const { user } = this.state;
    const { teams } = this.props;

    const userClone = cloneDeep(user);
    let team = teams.find((t) => t.id === teamOption.value);
    userClone.default_product =
      team && team.products[0] ? team.products[0] : UNASSIGNED_OBJECT;
    const productSelected =
      team && team.products[0] ? team.products[0] : UNASSIGNED_OBJECT;

    if (!team) {
      team = UNASSIGNED_OBJECT;
    }

    userClone.teams = [team];
    userClone.default_team = team;
    this.setState({
      user: userClone,
      teamSelected: team,
      productOptions: team.products,
      productSelected
    });
  };

  toggleColorPicker = () => {
    this.setState((prevState) => ({
      colorPickerIsOpen: !prevState.colorPickerIsOpen
    }));
  };

  changeUserColor = (color) => {
    const { user } = this.state;
    user.color = color.hex;
    this.setState({ user });
  };

  uploadFile = (file) => {
    const reader = new FileReader();
    const { user } = this.state;
    reader.onloadend = () => {
      const droppedFileUrl = reader.result;
      const droppedFile = file;
      if (user.id > 0) {
        this.props.uploadUserImage(
          droppedFile,
          droppedFileUrl,
          this.props.user.id
        );
      } else {
        const image = {
          droppedFile,
          droppedFileUrl
        };
        user.image = image;
        this.setState({ user });
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleFileUpload = (event) => {
    const droppedFiles = event.target.files;
    if (droppedFiles.length > 0) {
      this.uploadFile(droppedFiles[0]);
    }
  };

  handleFileDrop = (item, monitor) => {
    if (monitor) {
      const droppedFiles = monitor.getItem().files;
      this.uploadFile(droppedFiles[0]);
    }
  };

  handlePasswordReset = () => {
    this.props.resetPassword(this.state.user.email);
  };

  handleConfirmEmail = () => {
    this.props.confirmEmail(this.state.user.email);
  };

  changeUserName = (e) => {
    const { user } = this.state;
    user.email = e.target.value;
    this.setState({ user });
  };

  render() {
    const {
      formError,
      teams,
      isUploading,
      isNew,
      editExistingUser,
      currentUser
    } = this.props;
    const { user, productOptions } = this.state;
    return (
      <div className="detail-modal user-modal" tabIndex="-1" role="dialog">
        <div className="detail-dialog" role="document">
          <div className="profile-content modal-content">
            <div className="modal-header profile-header detail-header">
              <div className="col-sm-5">
                <h4 className="modal-title">
                  {isNew ? 'New User' : `Profile of ${user.name}`}
                </h4>
              </div>
              <div className="col-sm-2 text-center holon">
                <Holon disabled />
              </div>
              <div className="col-sm-5 detail-close">
                <button
                  type="button"
                  className="btn-close"
                  onClick={this.closeForm}
                >
                  <IoCloseSharp size="2em" />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="nav nav-tabs" role="tablist">
                    <li role="presentation" className="active">
                      <a
                        href="#profile"
                        aria-controls="details"
                        role="tab"
                        data-toggle="tab"
                      >
                        PROFILE
                      </a>
                    </li>
                    {/**
                    <li role="presentation">
                      <a
                        href="#work"
                        aria-controls="details"
                        role="tab"
                        data-toggle="tab"
                      >
                        WORK
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        href="#team"
                        aria-controls="details"
                        role="tab"
                        data-toggle="tab"
                      >
                        TEAM
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        href="#products"
                        aria-controls="details"
                        role="tab"
                        data-toggle="tab"
                      >
                        PRODUCTS
                      </a>
                    </li> */}
                  </ul>
                  <div className="tab-content row">
                    {this.props.isFetching && <div id="loader" />}
                    {!this.props.isFetching && (
                      <div
                        role="tabpanel"
                        className="tab-pane active"
                        id="profile"
                      >
                        <Profile
                          user={user}
                          teams={teams}
                          isUploading={isUploading}
                          handleFileDrop={this.handleFileDrop}
                          handleFileUpload={this.handleFileUpload}
                          onInputChange={this.onInputChange}
                          onSubmit={this.onSubmitProfile}
                          changeUserName={this.changeUserName}
                          changeUserColor={this.changeUserColor}
                          colorPickerIsOpen={this.state.colorPickerIsOpen}
                          toggleColorPicker={this.toggleColorPicker}
                          errors={
                            !!formError && !!formError.errors
                              ? formError.errors
                              : {}
                          }
                          teamSelected={this.state.teamSelected}
                          productSelected={this.state.productSelected}
                          selectTeam={this.selectTeam}
                          selectProduct={this.selectProduct}
                          onCapacityChange={this.onCapacityChange}
                          products={productOptions}
                          isNew={isNew}
                          editExistingUser={editExistingUser}
                          addTeamToNewUser={this.addTeamToNewUser}
                          resetPassword={this.handlePasswordReset}
                          confirmEmail={this.handleConfirmEmail}
                          currentUser={currentUser}
                          onNameBlur={this.onNameBlur}
                        />
                      </div>
                    )}
                    {/**
                    <div role="tabpanel" className="tab-pane" id="work">
                      YET TO BE IMPLEMENTED
                    </div>
                    <div role="tabpanel" className="tab-pane" id="team">
                      YET TO BE IMPLEMENTED
                    </div>
                    <div role="tabpanel" className="tab-pane" id="products">
                      YET TO BE IMPLEMENTED
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { users } = state.userReducer;
  const { user: propsUser, isNew, editExistingUser } = ownProps;
  const currentUser = state.userReducer.user;

  let user = propsUser && propsUser.id ? propsUser : currentUser;
  if (editExistingUser) user = users.find((u) => u.id === user.id);

  // TODO: if there are multiple organizations with multiple products between them,
  // a user that belongs to one organizaiton can be assigned to products of the
  // other organization. This will become an issue when we become more intentional
  // with having users in multiple organizations.
  const teams = filterTeamsByOrg(state, {
    ...ownProps,
    parentId: isNew
      ? currentUser.organizations[0].id
      : user.organization_ids
      ? user.organization_ids[0]
      : user.organizations[0].id // depends on which serializer used
  });

  const products = isNew
    ? filterProductsByOrg(state, ownProps)
    : state.productReducer.products;

  return {
    isUploading: state.userReducer.isUploading,
    isFetching: state.userReducer.isFetching,
    formError: state.userReducer.formError,
    currentUser,
    products,
    teams,
    user
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserInfo,
      closeModal,
      uploadUserImage,
      updateCurrentUser,
      updateUser,
      getAllProducts,
      createUser,
      resetPassword,
      confirmEmail,
      updateFormError,
      getAuthorizedProductIds
    },
    dispatch
  );

User.propTypes = {
  user: PropTypes.object,
  isUploading: PropTypes.bool,
  formError: PropTypes.object,
  products: PropTypes.array,
  isNew: PropTypes.bool,
  editExistingUser: PropTypes.bool
};

User.defaultProps = {
  user: {
    teams: []
  },
  products: [],
  isUploading: false,
  formError: null,
  isNew: false
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
