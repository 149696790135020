import moment from 'moment';

const generateGraph = (data = {}, height) => {
  // this.setState({ loading: false });
  const currentDate = moment().format('YYYY-MM-DD');
  const dates = Object.keys(data);

  // Burnline has a limit until the current user date
  let burnLineLimit = dates.findIndex((d) => d === currentDate);
  burnLineLimit = burnLineLimit >= 0 ? burnLineLimit : 9999;

  const xAxis = ['x'];
  const values = Object.values(data);
  const indexesWithDeleted = [];
  const differenceInHoursBetweenAddedRemoved = {};
  let graphIndexDifference = 0;

  // target line
  const targetLine = ['ideal target line'];
  const burnLine = ['burn line'];
  const trendLine = ['actual trend line'];
  const completedBar = ['completed'];
  const acceptedBar = ['accepted'];
  const deletedLine = ['scope change'];
  const totalPoints = values.length > 0 ? values[0].total_points : 0;
  for (let i = 0; i < values.length; i += 1) {
    const value = values[i];
    xAxis.push(dates[i]);
    targetLine.push(Math.round(value.target_burn_down * 100) / 100);
    if (value.trend_burn_down != null)
      trendLine.push(Math.round(value.trend_burn_down * 100) / 100);
    completedBar.push(value.points_completed);
    acceptedBar.push(value.points_accepted);
    if (value.hours_deleted < 0 || value.hours_added > 0) {
      indexesWithDeleted.push(i + graphIndexDifference);
      indexesWithDeleted.push(i + graphIndexDifference + 1);
      const difference = value.hours_deleted + value.hours_added;
      differenceInHoursBetweenAddedRemoved[
        i + graphIndexDifference + 1
      ] = difference;
      graphIndexDifference += 2;
      xAxis.push(dates[i]);
      xAxis.push(dates[i]);

      // Hack in order to create the dash line for added/deleted work packages
      burnLine.push(
        Math.round((value.remaining_estimate - difference) * 100) / 100
      );
      burnLine.push(null);
      burnLine.push(Math.round(value.remaining_estimate * 100) / 100);
      deletedLine.push(
        Math.round((value.remaining_estimate - difference) * 100) / 100
      );
      deletedLine.push(Math.round(value.remaining_estimate * 100) / 100);
      deletedLine.push(null);
      // end

      targetLine.push(null);
      targetLine.push(Math.round(value.target_burn_down * 100) / 100);

      if (value.trend_burn_down != null) {
        trendLine.push(null);
        trendLine.push(Math.round(value.trend_burn_down * 100) / 100);
      }

      acceptedBar.push(null);
      acceptedBar.push(value.points_accepted);

      completedBar.push(null);
      completedBar.push(value.points_completed);
    } else {
      // If does not reach current date
      if (i <= burnLineLimit) {
        burnLine.push(Math.round(value.remaining_estimate * 100) / 100);
      }
      deletedLine.push(null);
    }
  }

  const colors =
    trendLine.length > 1
      ? {
          completed: '#477CA0',
          accepted: '#009346',
          'actual trend line': '#DE70BC',
          'ideal target line': '#6F6E6F',
          'burn line': '#703F64'
        }
      : {
          completed: '#477CA0',
          accepted: '#009346',
          'ideal target line': '#6F6E6F',
          'burn line': '#703F64'
        };

  const columns =
    trendLine.length > 1
      ? [xAxis, targetLine, trendLine, burnLine, completedBar, acceptedBar]
      : [xAxis, targetLine, burnLine, completedBar, acceptedBar];

  const hasScopeChange = deletedLine.some((e, i) => i > 0 && e); // not just label and a bunch of nulls
  if (hasScopeChange) {
    colors['scope change'] = '#703F64';
    columns.push(deletedLine);
  }

  const y1Scale = burnLine.filter((e) => e && e !== 'burn line');
  y1Scale.push(targetLine[1]);
  const y1Max = Math.max(...y1Scale) * 1.02;

  // eslint-disable-next-line
  const chart = c3.generate({
    bindto: '#chart',
    size: {
      height
    },
    data: {
      x: 'x',
      columns,
      axes: {
        completed: 'y2',
        accepted: 'y2'
      },
      types: {
        completed: 'bar',
        accepted: 'bar'
      },
      colors
    },
    bar: {
      width: {
        ratio: 0.25
      }
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          count: dates.length > 7 ? 7 : dates.length,
          format: '%b %d'
        },
        min: dates[0]
      },
      y: {
        label: {
          text: '# of hours',
          position: 'outer-middle'
        },
        min: 0,
        padding: { top: 0, bottom: 0 },
        width: 2,
        max: y1Max
      },
      y2: {
        show: true,
        label: {
          text: '# of points',
          position: 'outer-middle'
        },
        max: totalPoints * 1.05,
        min: 0,
        padding: { top: 0, bottom: 0 }
      }
    },
    legend: {
      show: false
    }
  });

  return chart;
};

export default generateGraph;
