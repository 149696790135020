import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { SketchPicker } from 'react-color';
import FileDropBox from '../Common/fileDropBox';
import ItemTypes from '../../constants/itemTypes';
import DefaultTeamMemberIcon from '../../../assets/images/team_member.png';
import Default from '../../constants/defaults';
import { allows } from '../../utils/authorization';
import SelectField from '../Common/CustomSelect/SelectField';

const Profile = ({
  user,
  products,
  teams,
  isNew,
  editExistingUser,
  isUploading,
  colorPickerIsOpen,
  handleFileDrop,
  handleFileUpload,
  onInputChange,
  onSubmit,
  toggleColorPicker,
  changeUserColor,
  errors,
  selectTeam,
  teamSelected,
  productSelected,
  onCapacityChange,
  selectProduct,
  changeUserName,
  addTeamToNewUser,
  resetPassword,
  confirmEmail,
  currentUser,
  onNameBlur
}) => {
  const org = currentUser.organizations[0]; // TODO - handle multiple organizations per user
  const orgAdmin = org && allows('administer', org, 'organization');

  const teamOptions = (orgAdmin ? teams : user.teams).map((team) => ({
    label: team.short_name,
    value: team.id
  }));

  isNew && teamOptions.unshift({ label: '', value: 'unassigned' });

  const productOptions = products.map((product) => ({
    label: product.short_name,
    value: product.id
  }));

  productOptions.unshift({ label: '', value: 'unassigned' });

  const selectedProductOption = () => ({
    label: productSelected.short_name,
    value: productSelected.id
  });

  const selectedTeamOption = () => {
    const team = isEmpty(user.default_team) ? teamSelected : user.default_team;

    if (!team) {
      return { label: '', value: null };
    }
    return { label: team.short_name, value: team.id };
  };

  const isUserViewingOwnProfile = currentUser.id === user.id;

  let imageSrc = '';

  if (user.id > 0) {
    imageSrc = user?.picture?.url || DefaultTeamMemberIcon;
  } else {
    imageSrc = user?.image?.droppedFileUrl || DefaultTeamMemberIcon;
  }
  const clearDropbox = !isEmpty(imageSrc) && !imageSrc.match(/team_member/);

  return (
    <form className="profile-form" onSubmit={onSubmit}>
      <div className="col-sm-3 profile-tab">
        <div
          className="thumbnail-container"
          style={{ height: `${clearDropbox ? '190' : '202'}px` }}
        >
          <a href="#" className="thumbnail">
            {isUploading ? (
              <div id="mini-loader" />
            ) : (
              <img src={imageSrc} alt="" />
            )}
          </a>
          {!isUploading && (
            <FileDropBox
              accepts={ItemTypes.FILE}
              onDrop={handleFileDrop}
              onUpload={handleFileUpload}
              allowCreateAttachment
              clear={clearDropbox}
            />
          )}
        </div>
        <div className={`form-group ${errors.name ? 'has-error' : ''}`}>
          <label>Name</label>
          <input
            className="form-control"
            type="text"
            name="name"
            autoComplete="name"
            value={user.name || ''}
            onChange={onInputChange}
            onBlur={onNameBlur}
          />
          {errors.name &&
            errors.name.map((error) => (
              <span key={error} className="help-block">
                {error}
              </span>
            ))}
        </div>
        <div className={`form-group ${errors.email ? 'has-error' : ''}`}>
          <label>Email</label>
          <input
            className="form-control"
            type="email"
            name="email"
            value={user.email}
            onChange={changeUserName}
          />
          {errors.email &&
            errors.email.map((error) => (
              <span key={error} className="help-block">
                {error}
              </span>
            ))}
        </div>        
        <div className={`form-group ${errors.nickname ? 'has-error' : ''}`}>
          <label>Display Name</label>
          <input
            className="form-control"
            type="text"
            name="nickname"
            autoComplete="name"
            value={user.nickname || ''}
            onChange={onInputChange}
          />
          {errors.nickname &&
            errors.nickname.map((error) => (
              <span key={error} className="help-block">
                {error}
              </span>
            ))}
        </div>
        <div className={`form-group ${errors.initials ? 'has-error' : ''}`}>
          <label>Initials</label>
          <input
            className="form-control"
            type="text"
            autoComplete="off"
            name="initials"
            value={user.initials || ''}
            maxLength={Default.USER_INITIALS_LEN}
            onChange={onInputChange}
          />
          {errors.initials &&
            errors.initials.map((error) => (
              <span key={error} className="help-block">
                {error}
              </span>
            ))}
        </div>
      </div>
      <div className="col-sm-3 col-sm-offset-1 profile-tab">
        <div className={`form-group ${errors.color ? 'has-error' : ''}`}>
          <label>Favorite Color</label>
          <div className="favorite-color">
            <div
              onClick={toggleColorPicker}
              className="color-picked"
              style={{
                backgroundColor:
                  user.color[0] === '#' ? user.color : `#${user.color}`
              }}
            />
            <span onClick={toggleColorPicker}>Click to Pick</span>
            {colorPickerIsOpen && (
              <div className="sketch-picker">
                <div className="cover" onClick={toggleColorPicker} />
                <SketchPicker
                  color={user.color || ''}
                  onChangeComplete={changeUserColor}
                />
              </div>
            )}
          </div>
          {errors.color &&
            errors.color.map((error) => (
              <span key={error} className="help-block">
                {error}
              </span>
            ))}
        </div>
        <div className={`form-group ${errors.location ? 'has-error' : ''}`}>
          <label>Location</label>
          <input
            className="form-control"
            type="text"
            name="location"
            autoComplete="address-level1"
            value={user.location || ''}
            onChange={onInputChange}
          />
          {errors.location &&
            errors.location.map((error) => (
              <span key={error} className="help-block">
                {error}
              </span>
            ))}
        </div>
        <div className={`form-group ${errors.phone ? 'has-error' : ''}`}>
          <label>Phone Number</label>
          <input
            className="form-control"
            type="text"
            autoComplete="tel"
            name="phone"
            value={user.phone || ''}
            onChange={onInputChange}
          />
          {errors.phone &&
            errors.phone.map((error) => (
              <span key={error} className="help-block">
                {error}
              </span>
            ))}
        </div>
        {teamOptions.length > (isNew ? 1 : 0) &&
          (orgAdmin || currentUser.id === user.id || isNew) && (
            <>
              <div className="form-group">
                <label>Default Team</label>
                <SelectField
                  options={teamOptions}
                  selectedOption={selectedTeamOption()}
                  handleOptionChange={isNew ? addTeamToNewUser : selectTeam}
                  autoComplete="off"
                />
              </div>
              {user.capacitites &&
                user.capacities[user.default_team.id] !== undefined && (
                  <div className="form-group">
                    <label>Capacity for {user.default_team.short_name}</label>
                    <input
                      className="form-control"
                      autoComplete="off"
                      type="number"
                      name={user.default_team.id}
                      onChange={onCapacityChange}
                      value={
                        user.capacities[user.default_team.id] === null
                          ? ''
                          : user.capacities[user.default_team.id]
                      }
                    />
                  </div>
                )}
            </>
          )}
        {productOptions.length > 1 && // first entry is unassigned
          (orgAdmin || currentUser.id === user.id || isNew) && (
            <div className="form-group">
              <label>Default Product</label>
              <SelectField
                options={productOptions}
                selectedOption={selectedProductOption()}
                handleOptionChange={selectProduct}
                autoComplete="off"
              />
            </div>
          )}
        {isNew && (
          <div className="profile-form-controls">
            <p>
              The user will be sent a confirmation email and prompted to create
              a password.
            </p>
          </div>
        )}
        {!isNew && isUserViewingOwnProfile && (
          <>
            <div className={`form-group ${errors.password ? 'has-error' : ''}`}>
              <label>New Password</label>
              <input
                className="form-control"
                type="password"
                name="password"
                autoComplete="new-password"
                onChange={onInputChange}
              />
              {errors.password &&
                errors.password.map((error) => (
                  <span key={error} className="help-block">
                    {error}
                  </span>
                ))}
            </div>

            <div
              className={`form-group ${
                errors.password_confirmation ? 'has-error' : ''
              }`}
            >
              <label>Password Confirmation</label>
              <input
                className="form-control"
                type="password"
                name="password_confirmation"
                autoComplete="new-password"
                onChange={onInputChange}
              />
              {errors.password_confirmation &&
                errors.password_confirmation.map((error) => (
                  <span key={error} className="help-block">
                    {error}
                  </span>
                ))}
            </div>
          </>
        )}
        {editExistingUser && !isUserViewingOwnProfile && (
          <div className="form-group profile-form-controls">
            <a onClick={user.confirmed ? resetPassword : confirmEmail}>
              <button
                type="button"
                className="btn btn-large btn-default reset-password"
              >
                {user.confirmed
                  ? 'Send password reset email'
                  : 'Re-send confirmation email'}
              </button>
            </a>
          </div>
        )}
        {!isNew && isUserViewingOwnProfile && (
          <div
            className={`form-group ${
              errors.current_password ? 'has-error' : ''
            }`}
          >
            <label>Password</label>
            <input
              className="form-control"
              type="password"
              autoComplete="current-password"
              name="current_password"
              onChange={onInputChange}
              placeholder="Enter your current password"
            />
            {errors.current_password &&
              errors.current_password.map((error) => (
                <span key={error} className="help-block">
                  {error}
                </span>
              ))}
          </div>
        )}
        <div className="form-group">
          <input
            className="form-control btn btn-primary"
            type="submit"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
};

Profile.propTypes = {
  user: PropTypes.object,
  isUploading: PropTypes.bool,
  errors: PropTypes.object,
  colorPickerIsOpen: PropTypes.bool,
  teamSelected: PropTypes.object,
  productSelected: PropTypes.object,
  products: PropTypes.array,
  teams: PropTypes.array,
  isNew: PropTypes.bool,
  handleFileDrop: PropTypes.func,
  handleFileUpload: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  toggleColorPicker: PropTypes.func,
  changeUserColor: PropTypes.func,
  selectTeam: PropTypes.func,
  selectProduct: PropTypes.func,
  onCapacityChange: PropTypes.func,
  onDefaultTeamChange: PropTypes.func,
  onDefaultProductChange: PropTypes.func,
  changeUserName: PropTypes.func
};

Profile.defaultProps = {
  user: {
    username: '',
    teams: [],
    roles: [],
    picture: {
      url: '',
      thumbnail: ''
    }
  },
  isUploading: false,
  errors: {},
  colorPickerIsOpen: false,
  teamSelected: {},
  productSelected: {},
  products: [],
  teams: []
};

export default Profile;
