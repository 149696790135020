import React from 'react';

const BurndownLegend = (props) => {
  const { burndown } = props;
  const showTrendLine =
    burndown &&
    Object.keys(burndown).length > 0 &&
    burndown[Object.keys(burndown)[0]].trend_burn_down;

  return (
    <div className="burndown-legend-container">
      <div className="burndown-legend">
        <div className="target legend-item">
          <div className="bl-icon" />
          <span>ideal target line</span>
        </div>
        {showTrendLine && (
          <div className="trend legend-item">
            <div className="bl-icon" />
            <span>actual trend line</span>
          </div>
        )}
        <div className="burn legend-item">
          <div className="bl-icon" />
          <span>burn line</span>
        </div>
        <div className="stories-removed legend-item">
          <div className="bl-icon" />
          <span>scope change</span>
        </div>
        <div className="completed legend-item">
          <div className="bl-icon" />
          <span>completed points</span>
        </div>
        <div className="accepted legend-item">
          <div className="bl-icon" />
          <span>accepted points</span>
        </div>
      </div>
    </div>
  );
};

export default BurndownLegend;
