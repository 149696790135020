import Types from '../constants/actionTypes';
import { getCadenceId } from '../utils/reduxHelpers';
import { updateTourState } from './tourActions';

export const updateSingleResult = (dispatch, workPackage) => {
  dispatch({
    type: Types.UPDATE_WORK_PACKAGE,
    workPackage,
    cadenceId: getCadenceId()
  });
  updateTourState(dispatch);
};

export const updateMultipleResults = (dispatch, results) => {
  dispatch({
    type: Types.UPDATE_MULTIPLE_WORK_PACKAGES,
    results,
    cadenceId: getCadenceId()
  });
};

export const addSingleResult = (
  dispatch,
  isSending,
  workPackage,
  ranksAndIds
) => {
  dispatch({
    type: Types.ADD_WORK_PACKAGE,
    workPackage,
    isSending,
    cadenceId: getCadenceId(),
    ranksAndIds
  });
  updateTourState(dispatch);
};

export const deleteSingleResult = (dispatch, workPackage) => {
  dispatch({
    type: Types.DELETE_WORK_PACKAGE,
    workPackage,
    cadenceId: getCadenceId()
  });
  updateTourState(dispatch);
};
