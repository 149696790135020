import React from 'react';
import { Link } from 'react-router-dom';
import { CadenceStatuses } from '../../constants/storyStatusTypes';

const Cardwall = ({ sprints, isKanban, multipleTeams }) => {
  const currentSprint =
    sprints.find((item) => item.status === CadenceStatuses.IN_PROGRESS) ||
    sprints.find((item) => item.status === CadenceStatuses.GETTING_READY);

  const baseCardwallPath =
    currentSprint && !isKanban && !multipleTeams
      ? `/cardwall/cadences/${currentSprint.id}`
      : '/cardwall';

  return (
    <div className="dropdown btn-component-div">
      <button
        type="button"
        id="detail-dropdown"
        className="btn btn-default menu-header"
        aria-haspopup="true"
      >
        Card Wall
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdown">
        <li>
          <Link
            to={`${baseCardwallPath}/gettingreadywall`}
            className="btn btn-link"
          >
            Get Ready
          </Link>
        </li>
        <li>
          <Link to={`${baseCardwallPath}/readywall`} className="btn btn-link">
            Plan Sprint
          </Link>
        </li>
        <li>
          <Link to={`${baseCardwallPath}/workwall`} className="btn btn-link">
            Sprint Wall
          </Link>
        </li>
        <li>
          <Link
            to={`${baseCardwallPath}/gettingdonewall`}
            className="btn btn-link"
          >
            Accept
          </Link>
        </li>
        <li>
          <Link
            to={`${baseCardwallPath}/deploymentwall`}
            className="btn btn-link"
          >
            Make Shippable
          </Link>
        </li>
        <li>
          <Link to="/cardwall/kanban/r/1" className="btn btn-link">
            Kanban View
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Cardwall;
