import { createSelector } from 'reselect';
import { checkIfKanban, checkIfScrum } from '../utils/scrumHelper';
import { getSelectedSpace } from '../utils/reduxHelpers';

const getTeams = (state) => state.teamReducer.teams;
const getTeam = (state, props) =>
  state.teamReducer.teams.find((team) => team.id === props.id);

const getUsers = (state) => state.userReducer.users;

const getOrgParentId = (_, props) => props.parentId;

const getOrgFromProps = (_, props) => props.organization;
const getShowTrash = (state) => state.trashReducer.showTrash;

const getAllSprints = (state) => state.cadenceReducer.cadences;
const getTeamIdFromProps = (_, props) => props.id;

export const isKanbanMode = createSelector([getSelectedSpace], checkIfKanban);
export const hasScrumTeam = createSelector([getSelectedSpace], checkIfScrum);

// this is used for the new user modal
export const filterTeamsByOrg = createSelector(
  [getTeams, getOrgParentId],
  (teams, orgId) =>
    teams
      .filter((team) => team.organization_id === orgId)
      .filter((t) => !t.deleted_at)
);

export const filterTeamsByOrgForOrgWall = createSelector(
  [getTeams, getOrgFromProps, getShowTrash],
  (teams, organization, showTrash) =>
    teams
      .filter((t) => t.organization_id === organization.id)
      .filter((t) => {
        if (showTrash) return true;
        return !t.deleted_at;
      })
);

export const getSelectedTeamAndMembers = createSelector(
  [getTeam, getUsers],
  (team, users) => {
    const newTeam = { ...team };
    newTeam.members = team.members.filter((member) =>
      users.some((u) => u.id === member.id)
    );
    return newTeam;
  }
);

export const getTeamSprints = createSelector(
  [getAllSprints, getTeamIdFromProps],
  (sprints, teamId) =>
    sprints.filter((sprint) => sprint.team_id === teamId && !sprint.deleted_at)
);
