import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import dropDownTypes from '../../constants/dropDownTypes';

const PlanwallMenuContainer = ({ dropDown, fieldName, type, source }) => {
  const dropDownRef = useRef();
  const [overflowing, setOverflowing] = useState(false);
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    const { elementClicked, closeDropdown } = dropDown;

    setDidMount(true);

    const windowClickClose = (evt) => {
      const closest = evt.target.closest('.planwall-menu-container');
      if (evt.target !== elementClicked && !closest) {
        closeDropdown();
      }
    };

    window.addEventListener('click', windowClickClose, {
      once: true,
      capture: true
    });

    if (source !== 'ProductRow') {
      const listComponent = document.querySelector(
        '.virtualized-container-list'
      );

      listComponent.addEventListener('scroll', closeDropdown);

      window.addEventListener('scroll', closeDropdown);
    }

    return () => {
      window.removeEventListener('click', windowClickClose);
      window.removeEventListener('scroll', closeDropdown);

      if (source !== 'ProductRow') {
        document
          .querySelector('.virtualized-container-list')
          .removeEventListener('scroll', closeDropdown);
      }
    };
  }, []);

  useEffect(() => {
    // check to see if dropdown is flowing past page bottom
    const { y, height } = dropDownRef.current.getBoundingClientRect();
    const bottomPosition = y + height + 20;
    if (bottomPosition > window.innerHeight) {
      setOverflowing(true);
    } else {
      setOverflowing(false);
    }
  }, []);

  const generateBackgroundColor = () => {
    switch (fieldName) {
      case 'value':
        return '#96cca1';
      case 'risk':
      case 'rroe':
        return '#ce9191';
      case 'size':
        return '#8cb8ba';
      case 'tc':
        return '#c09fc1';
      default:
        return '#DDDBDC';
    }
  };

  const handleMouseEnter = () => {
    document.querySelector('body').style.overflow = 'hidden';
  };

  const handleMouseLeave = () => {
    document.querySelector('body').style.overflow = 'scroll';
  };

  const DropDownComponent =
    dropDownTypes[type] && dropDownTypes[type].component;

  const generateOptions = () => {
    if (
      dropDown.result.result_type.kind === 'Defect' &&
      fieldName === 'value'
    ) {
      return { 1: 'Sev 4', 3: 'Sev 3', 5: 'Sev 2', 8: 'Sev 1' };
    }
    return dropDown.options;
  };

  const generateChildrenStyles = () => {
    const { top, x } = dropDown.elementClicked.getBoundingClientRect();

    let heightDifference = 0;

    const dropDownNode = document.querySelector('.planwall-menu-container');

    const dropDownDimensions = didMount && dropDownNode.getBoundingClientRect();
    if (dropDownDimensions) {
      heightDifference = -dropDownDimensions.height + 4;
    }

    let offsetX = 30;
    let offsetY = overflowing ? heightDifference : 20;

    if (dropDown.level === 1 && fieldName === 'risk') offsetX = 62;
    if (dropDown.level === 1 && fieldName === 'value') offsetX = 63;
    if (dropDown.result.result_type.kind === 'Defect' && fieldName === 'value')
      offsetX = overflowing ? 43 : 42;
    if (dropDown.level !== 1 && fieldName === 'size') offsetX = 33;
    if (dropDown.level !== 1 && fieldName === 'rroe') offsetX = 29;
    if (type === 'cogMenu') offsetX = 4;
    if (fieldName === 'state') {
      offsetX = 139;
      offsetY = overflowing ? heightDifference : 27;
    }

    return {
      position: 'fixed',
      top: `${top + offsetY}px`,
      left: `${x - offsetX}px`,
      right: 'initial',
      padding: fieldName === 'state' || type === 'cogMenu' ? '' : '0 0 0 5px'
    };
  };

  const clearableFields = ['value', 'risk', 'rroe', 'size', 'tc'];

  return ReactDom.createPortal(
    <div
      className={`planwall-menu-container ${fieldName} child ${
        overflowing ? 'overflowing' : ''
      }`}
      style={{
        backgroundColor: generateBackgroundColor(),
        ...generateChildrenStyles()
      }}
      onClick={dropDown.closeDropdown}
      ref={dropDownRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropDownComponent {...dropDown} options={generateOptions()} />
    </div>,
    document.querySelector('.portal-element')
  );
};

PlanwallMenuContainer.propTypes = {
  type: PropTypes.string.isRequired,
  dropDown: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  isChildren: PropTypes.bool
};

export default withRouter(PlanwallMenuContainer);
