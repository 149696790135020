import Store from '../store/store';
import { openModal } from '../actions/modalActions';
import modalTypes from '../constants/modalTypes';
import { WorkPackageStatuses } from '../constants/storyStatusTypes';
import { updateTargetStatus } from '../actions/targetActions';

const endTarget = (
  selectedUnfinishedWorkPackages = [],
  targetId,
  currentTarget,
  targetStatus
) => {
  Store.dispatch(
    updateTargetStatus(currentTarget.id, targetStatus, {
      unfinishedWorkPackageIds: selectedUnfinishedWorkPackages,
      targetToCarryOverId: targetId
    })
  );
};

export const checkResultsToBeTransfered = (
  currentTarget,
  targetWorkPackages,
  targetStatus = WorkPackageStatuses.ACCEPTED
) => {
  const state = Store.getState();

  const { targets } = state.targetReducer;

  const notCompletedTargets = targets.filter(
    t =>
      !t.deleted_at && 
      t.status ===
        (WorkPackageStatuses.GETTING_READY ||
          t.status === WorkPackageStatuses.IN_PROGRESS) &&
      t.id !== currentTarget.id
  );
  const unfinishedResults = targetWorkPackages.filter(
    w =>
      w.type === 'Result' &&
      w.state !== WorkPackageStatuses.ACCEPTED &&
      w.state !== WorkPackageStatuses.SHIPPABLE &&
      w.state !== WorkPackageStatuses.LIVE
  );
  if (unfinishedResults.length > 0) {
    Store.dispatch(
      openModal(modalTypes.unfinishedResults, {
        unfinishedResults,
        currentTarget,
        targetStatus,
        targets: notCompletedTargets,
        endGroup: endTarget,
        type: 'target',
        title: notCompletedTargets.length > 0 ? 'The following stories will be carried over to the selected target.' : 'The following stories will be carried over to a new target.'
      })
    );
  } else {
    endTarget([], null, currentTarget, targetStatus);
  }
};

export const startTarget = target => {
  Store.dispatch(
    updateTargetStatus(target.id, WorkPackageStatuses.IN_PROGRESS)
  );
};
