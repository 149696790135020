import React from 'react';

const DetailsTab = ({
  organization,
  handleFieldChange,
  allowEdit
}) => (
  <div
    role="tabpanel"
    className="tab-pane active team-details-tab"
    id="details"
  >
    <div className="organization-description">
      <label>Description</label>
      <textarea
        id="organization-description-text"
        value={organization.description || ''}
        onChange={e =>
          handleFieldChange({
            description: e.target.value
          })
        }
        placeholder="Enter organization communication tools and important links here"
        disabled={!allowEdit}
      >
        {organization.description}
      </textarea>
    </div>
  </div>
);

export default DetailsTab;
