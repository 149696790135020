import { orderBy } from 'lodash';
import Store from '../store/store';
import { getSelectedSpace, getTeams } from './reduxHelpers';
import {
  isAuthorizedWorkerForProduct,
  isAuthorizedWorkerForTeam
} from './authorization';
// eslint-disable-next-line import/prefer-default-export
export const sortWorkPackages = (wps, sortBy, order) => {
  let sortedWorkPackages = wps;
  if (['rank', 'state', 'wsjf'].includes(sortBy)) {
    sortedWorkPackages = orderBy(wps, [sortBy], [order]);
  } else if (['size', 'value', 'tc', 'rroe', 'risk'].includes(sortBy))
    sortedWorkPackages = orderBy(
      wps,
      (item) =>
        item.result_type && (item.result_type.type_attributes[sortBy] || 0) * 1,
      [order]
    );
  return sortedWorkPackages;
};

export const doWeShowChildren = (hasChildren, arrayOfChildren = []) => {
  const { showTrash } = Store.getState().trashReducer;

  if (arrayOfChildren.length < 1) return false;

  const allChildrenDeleted = arrayOfChildren.every(
    (child) => !!child.deleted_at
  );

  if (hasChildren) {
    if (allChildrenDeleted) {
      if (showTrash) return true;
      return false;
    }
    return true;
  }
  return false;
};

export const isWorkPackageInScope = (workPackage) => {
  const { id, type, teams } = getSelectedSpace();
  const teamIds = (teams && teams.length > 0 && teams.map((t) => t.id)) || [];

  if (type === 'product') {
    if (workPackage.product_id !== id) {
      return false;
    }
    if (workPackage.product_id === id) {
      return true;
    }

    if (!teamIds.includes(workPackage.team_id)) return false;
    return true;
  }
  if (type === 'team') {
    if (workPackage.team_id !== id) {
      return false;
    }
    if (workPackage.team_id === id) {
      return true;
    }

    return true;
  }
  return true;
};

export const isWorkPackageInStore = (workPackage) => {
  const allResults = Store.getState().workPackageReducer.all;
  return allResults.some((result) => result.id === workPackage.id);
};

export const isOwnerAuthorizedForWorkPackage = (
  ownerId,
  workPackageAuthority
) => {
  switch (workPackageAuthority.type) {
    case 'product':
      return isAuthorizedWorkerForProduct(ownerId, workPackageAuthority.id);
    case 'team':
      return isAuthorizedWorkerForTeam(ownerId, workPackageAuthority.id);
    default:
      return false;
  }
};

export const resultInTeamScope = (result) => {
  const teams = getTeams();
  return teams.some((team) => team.id === result.team_id);
};
