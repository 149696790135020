import React from 'react';

const CadenceMetricsTab = ({}) => {
  return (
    <div role="tabpanel" id="metrics">
      YET TO BE IMPLEMENTED
    </div>
  );
};

export default CadenceMetricsTab;
