import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DragSource, DropTarget } from 'react-dnd';
import { flow, isEqual } from 'lodash';
import moment from 'moment';
import Tooltip from 'react-tooltip-lite';
import { resultCardId } from '../../utils/string';
import Store from '../../store/store';
import DropDownMenu from '../Common/dropDownMenu';
import Holon from '../Buttons/holon';
import modalTypes from '../../constants/modalTypes';
import ItemTypes from '../../constants/itemTypes';
import {
  addWrapParent,
  updateWorkPackage
} from '../../actions/workPackageActions';
import { openModal } from '../../actions/modalActions';
import { startDrag, endDrag } from '../../actions/notificationActions';
import ChildrenBar from './childrenBar';
import { allows } from '../../utils/authorization';
import UserInitialsButton from '../Common/userInitialsButton';
import { filterWorkersByAuthority } from '../../selectors/userSelector';
import { determineModalType } from '../../utils/resultModalHelpers';

const resultSource = {
  beginDrag(props) {
    Store.dispatch(startDrag());
    return {
      result: props.result,
      index: props.index,
      removeCard: props.removeCard
    };
  },
  canDrag(props) {
    return allows('update', props.result);
  },
  endDrag() {
    Store.dispatch(endDrag());
  }
};

const resultTarget = {
  hover(props, monitor) {
    const hoverIndex = props.index;
    if (props.result.state === monitor.getItem().result.state) {
      if (props.result.id === monitor.getItem().result.id) {
        return;
      }
      props.moveCardHover(monitor.getItem().result, props.result);
      monitor.getItem().index = hoverIndex; // eslint-disable-line no-param-reassign
    } else {
      props.moveCardToAnotherColumn(hoverIndex);
      monitor.getItem().index = hoverIndex; // eslint-disable-line no-param-reassign
    }
  },
  drop(props, monitor) {
    const draggedItem = monitor.getItem();
    // const multiProducts = Store.getState().defaultOptionsReducer
    //   .multipleProductsSelected;
    if (props.result.state === draggedItem.result.state) {
      props.moveCardDrop(draggedItem);
    }
  }
};

const collectSource = (connectTo, monitor) => ({
  connectDragSource: connectTo.dragSource(),
  isDragging: monitor.isDragging(),
  canDrag: monitor.canDrag()
});

const collectTarget = (connectDropTarget) => ({
  connectDropTarget: connectDropTarget.dropTarget()
});

class KanbanCard extends Component {
  // If more than one product is selected, we can disable DnD/ranking
  multipleProductsSelected = Store.getState().defaultOptionsReducer
    .multipleProductsSelected;

  constructor(props) {
    super(props);
    this.state = {
      dropDown: {},
      isTitleEditable: false,
      result: props.result,
      placeHolderName: props.result.short_name
    };
  }

  componentDidUpdate(prevProps) {
    const { result } = prevProps;
    if (result && !isEqual(result, this.props.result)) {
      this.setState({ result: this.props.result });

      const { short_name: shortName } = this.props.result;

      if (this.state.placeHolderName !== shortName) {
        this.setState({ placeHolderName: shortName });
      }
    }
  }

  openDetailCard = () => {
    const { result } = this.state;
    const modalType = determineModalType(result);
    this.props.openModal(modalType, {
      id: result.id
    });
  };

  openParent = (e) => {
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    const { result } = this.state;

    if (result.parent_id) {
      this.props.addWrapParent(result.parent_id);
      Store.dispatch(
        openModal(
          modalTypes.wrapCard,
          { item: result, type: 'story', posx, posy },
          'wrapCard'
        )
      );
    }
  };

  toggleDropDownOwner = (e) => {
    e.stopPropagation();
    const { users, teams } = this.props;
    const { result } = this.state;
    let { dropDown } = this.state;
    if (dropDown.id) {
      dropDown = {};
    } else {
      const { target: element } = e;
      const classes = 'planwall-drop-menu';

      dropDown = {
        result,
        classes,
        users,
        elementClicked: element,
        elementAttached: element,
        type: 'changeOwner',
        parent:
          element.closest('#scrollable-wall-container') ||
          element.closest('.kanban-wall'),
        id: result.id,
        closeDropdown: () => this.setState({ dropDown: {} })
      };
    }
    this.setState({ dropDown });
  };

  headerValues = () => {
    const { defaultOptions } = this.props;
    const { result } = this.state;
    const valueDefaults =
      defaultOptions.value_defaults && defaultOptions.value_defaults.values;
    const severityDefaults = {
      1: 'S4',
      3: 'S3',
      5: 'S2',
      8: 'S1'
    };
    const riskDefaults =
      defaultOptions.risk_defaults && defaultOptions.risk_defaults.values;
    const sizeDefaults =
      defaultOptions.t_shirt_size_defaults &&
      defaultOptions.t_shirt_size_defaults.values;
    const vrs = result.result_type ? result.result_type.type_attributes : {};

    const borderStyle = { borderLeft: '1px solid transparent' };

    if (result.level === 1)
      return (
        <div className="flexContainer col-sm-5 ">
          <div
            className="flexSize_1 kanbanCardIcons text-center value"
            style={borderStyle}
          >
            <span className="icon_text">
              {vrs.value
                ? result.result_type.kind == 'Defect'
                  ? severityDefaults[vrs.value]
                  : valueDefaults[vrs.value][0]
                : '-'}
            </span>
          </div>
          <div
            className="flexSize_1 kanbanCardIcons  text-center risk"
            style={borderStyle}
          >
            <span className="icon_text">
              {riskDefaults && vrs.risk ? riskDefaults[vrs.risk][0] : '-'}
            </span>
          </div>
          <div
            className="flexSize_1 kanbanCardIcons  text-center points"
            style={borderStyle}
          >
            <span className="icon_text">
              {sizeDefaults && vrs.size ? vrs.size : '-'}
            </span>
          </div>
        </div>
      );

    return (
      <div className="flexContainer col-sm-5 ">
        <div
          className="kanbanCardIcons flexSize_1 text-center value"
          style={borderStyle}
        >
          <span className="icon_text">
            {valueDefaults && vrs.value ? valueDefaults[vrs.value][0] : '-'}
          </span>
        </div>
        <div
          className="kanbanCardIcons flexSize_1 text-center tc"
          style={borderStyle}
        >
          <span className="icon_text">
            {riskDefaults && vrs.tc ? riskDefaults[vrs.tc][0] : '-'}
          </span>
        </div>
        <div
          className="kanbanCardIcons flexSize_1 text-center risk"
          style={borderStyle}
        >
          <span className="icon_text">
            {riskDefaults && vrs.rroe ? riskDefaults[vrs.rroe][0] : '-'}
          </span>
        </div>
        <div
          className="kanbanCardIcons flexSize_1 text-center points"
          style={borderStyle}
        >
          <span className="icon_text">
            {sizeDefaults && vrs.size ? sizeDefaults[vrs.size] : '-'}
          </span>
        </div>
      </div>
      //
    );
  };

  makeTitleEditable = () => this.setState({ isTitleEditable: true });

  handleOnChange = ({ target }) => {
    this.setState({ placeHolderName: target.value });
  };

  handleOnBlur = ({ target }) => {
    const { result } = this.state;
    const oldName = this.state.result.short_name;
    const newName = target.value;

    if (newName == '') {
      // if user attempts to rename to empty string, reset to old name
      target.value = oldName;
      this.setState({ isTitleEditable: false });
      this.setState({ placeHolderName: oldName });
    } else {
      this.props.updateWorkPackage(result.id, {
        work_package: { short_name: target.value }
      });
    }
    this.setState({ isTitleEditable: false });
  };

  fullCard = () => {
    const { isDragging, canDrag, noHolon, users } = this.props;
    const { result } = this.state;
    const { dropDown, isTitleEditable } = this.state;
    const allowEdit = allows('update', result) && !result.deleted_at;

    const cursorClass = canDrag ? 'move-cursor' : 'no-drop-cursor';

    const deletedClassName = result.deleted_at ? 'deleted' : '';
    const blockedClassName = result.blocked ? 'blocked-card' : '';
    const higlightClassName = result.dropPreview ? 'highlight-transparent' : '';
    const draggingClassName = isDragging ? 'dragging-opacity' : '';

    return (
      <div
        className={`panel panel-default wall-row kanban-card ${deletedClassName} ${blockedClassName} ${higlightClassName} ${draggingClassName}`}
      >
        <div className={`panel-heading ${cursorClass}`}>
          <div className="panel-title">
            <div className="col-sm-5 flexContainer flexCardTitle ">
              <a onClick={this.openDetailCard}>{resultCardId(result)}</a>
            </div>
            <div className="col-sm-2 text-center">
              {result.parent_id && !noHolon && (
                <Holon action={this.openParent} />
              )}
            </div>
            {this.headerValues()}
          </div>
        </div>
        <div
          className={`kanban-main ${
            result.level > 1 ? 'r1plusCard' : 'r1Card'
          }`}
        >
          <div
            onClick={allowEdit ? this.makeTitleEditable : null}
            className="panel-body"
          >
            {isTitleEditable ? (
              <textarea
                className={`textarea${result.level > 1 ? '-min' : ''}`}
                rows="3"
                autoFocus={isTitleEditable}
                value={this.state.placeHolderName}
                onBlur={this.handleOnBlur}
                onChange={this.handleOnChange}
              />
            ) : (
              <p className={result.level === 1 ? 'text' : ''}>
                {this.state.placeHolderName || result.name}
              </p>
            )}
          </div>
          <div
            className={`panel-footer ${
              result.level === 1 ? 'result1' : 'greater-result'
            }`}
          >
            {result.level > 1 && (
              <ChildrenBar resultId={result.id} level={result.level} />
            )}
            <div className={`card-details ${cursorClass}`}>
              <div className="details-text">
                {moment(result.status_updated_at).fromNow(true)} in this column
              </div>
              <UserInitialsButton
                className="owner-initials"
                onClick={this.toggleDropDownOwner}
                isDisabled={!allowEdit || !users || users.length === 0}
                userId={result.owner_id}
                users={users}
              />
            </div>
          </div>
        </div>
        {dropDown && dropDown.id && (
          <DropDownMenu
            type={dropDown.type}
            dropDown={dropDown}
            key={dropDown.id}
          />
        )}
      </div>
    );
  };

  miniCard = () => {
    const { isDragging } = this.props;
    const { result } = this.state;
    const blockedClassName = result.blocked ? 'blocked-card' : '';
    const miniCardBorderClassName = `mini-card-${result.state
      .toLowerCase()
      .replace(' ', '-')}`;
    const dropPreviewClassName = result.dropPreview ? 'highlight' : '';
    const deletedClassName = result.deleted_at ? 'deleted' : '';
    const draggingClassName = isDragging ? 'dragging-opacity' : '';

    // If there's more than one product selected, then we can't drag/rank, so don't show a special cursor at all
    const cursorClass = this.multipleProductsSelected ? '' : 'move-cursor';

    return (
      <div
        className={`mini-result-card /* ${cursorClass}*/ ${blockedClassName} ${miniCardBorderClassName} ${dropPreviewClassName} ${deletedClassName} ${draggingClassName}`}
      >
        <Tooltip
          content={result.name}
          useDefaultStyles
          className="mini-result-card-content"
        >
          <span className="link" onClick={this.openDetailCard}>
            {result.id ? resultCardId(result) : 'New'}
          </span>
          <div className="glyphicon glyphicon-move move-glyph" />
        </Tooltip>
      </div>
    );
  };

  render() {
    const { mini, connectDragSource, connectDropTarget } = this.props;
    const { result } = this.state;
    if (!result || !result.id) return null;

    const card = mini ? this.miniCard() : this.fullCard();

    return connectDropTarget(connectDragSource(card));
  }
}

const mapStateToProps = (state, ownProps) => {
  const { result } = ownProps;
  const users = filterWorkersByAuthority(state, result);
  return {
    defaultOptions: state.defaultOptionsReducer.defaultOptions,
    teams: state.teamReducer.teams,
    users
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal,
      addWrapParent,
      updateWorkPackage
    },
    dispatch
  );

export default flow([
  DragSource(ItemTypes.KANBAN_CARD, resultSource, collectSource),
  DropTarget(ItemTypes.KANBAN_CARD, resultTarget, collectTarget)
])(connect(mapStateToProps, mapDispatchToProps)(KanbanCard));
