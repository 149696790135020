import React from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '../Common/CustomSelect/SelectField';
const LevelDropDown = ({ handleSelectChange, selectedValue }) => {
  const handleChange = (option) => {
    handleSelectChange(option.value);
  };

  const levelOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 }
  ];

  const selectedOption = levelOptions.find(option => option.value === selectedValue)

  return (
    <div className="org-level-level-select">
      <CustomSelect
        placeholder="Level"
        handleOptionChange={handleChange}
        selectedOption={selectedOption}
        options={levelOptions}
        containerClass="org-card-level-select"
      />
    </div>
  );
};

LevelDropDown.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.number.isRequired
};

export default LevelDropDown;
