import { offset } from '@floating-ui/dom';
import { userWelcomed } from '../../actions/tourActions';
import { allows } from '../../utils/authorization';
import { userTourState } from '../../utils/userHelpers';

export default [
  {
    id: 'organization-setup',
    text: '<p>Welcome to Agile Dezign! You may want to begin by setting up your Organization and inviting users.</p> <p>Open the organization card by clicking on the Organization ID link.</p>',
    selector: 'a#org-id',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    dispatch_on: {
      done: userWelcomed
    },
    autoShow: (state) => !userTourState(state).welcomed,
    predicate: (state) =>
      allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  },
  {
    id: 'show-guide',
    // eslint-disable-next-line no-multi-str
    text: "<p>Guidance and coaching is available throughout Agile Dezign for setting up your Agile project, including tips based on observation of your backlog and other data.</p>\
    <p>This icon will appear if guidance is available on the current page.  It will turn into a lightbulb if there are specific tips for you based on your data.</p>\
    <p>You can show or hide the guide by clicking on this icon, and also hide the active guide by hitting the &ltesc&gt key.</p>",
    selector: '#guide-me',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  },
  { id: 'space-selector',
    text: '<p>An important menu is the Spaces menu, which determines which backlog items are visible to you in Agile Dezign. </p>\
    <p>As an administrator, you can view the backlog of any Team or Product you have defined. \
    Products enable different teams to draw work from a shared backlog.</p>',
    selector: '.menu-toggle',
    on: 'right',
    predicate: (state) =>
      allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )    
  },
  {
    id: 'show-guide',
    // eslint-disable-next-line no-multi-str
    text: '<p>Guidance and coaching is available throughout Agile Dezign for setting up your Agile project, including tips based on observation of your backlog and other data.</p>\
    <p>This icon will appear if guidance is available on the current page.  It will turn into a lightbulb if there are specific tips for you based on your data.</p>',
    selector: '#guide-me',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      !allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  },  
  {
    id: 'configure-teams',
    text: '<p>After inviting users, you may want to configure your team(s). Edit the default team by clicking its name in the list.</p>',
    selector: 'div.team-name-col:first-of-type > a',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  },
  {
    id: 'add-members',
    text: '<p>You can add members either on the team card, or with this button.  Note that you may need to invite them as users first!</p>',
    selector: 'div.team.org-row button',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  },
  {
    id: 'add-teams',
    text: '<p>Add new teams with this button.</p>',
    selector: 'div.org-add-team button',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  },
  {
    id: 'flip-products',
    text: '<p>To configure products for teams to work on, flip to the product view.</p></p>This is not required; if you are just setting up Agile teams you can come back to this later on.</p>',
    selector: 'div.flip-products-teams button',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  },
  {
    id: 'plan-features',
    text: '<p>Now that you have your team(s) and products configured, head to the planning wall to begin scope discovery.',
    selector: '#menu-list-wall',
    on: 'left-end',
    navigate: { route: '/planwall/r/2', text: 'Go There' },
    predicate: (state) =>
      allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  }
];
