import Store from '../store/store';
import { WorkPackageStatuses } from '../constants/storyStatusTypes';
import { getShowTrash } from './reduxHelpers';

// return immediate descendants of a workPackage within the provided scope
export const extractImmediateDescendants = (topIDs, scope, level = 0) => {
  if (level === 0) return [];

  const children = scope.filter((workPackage) => {
    const inScope =
      topIDs.includes(workPackage.parent_id) &&
      !topIDs.includes(workPackage.id); // prevent duplicates when parent is same level as child; undiagnosed backend data error when converting levels
    return inScope;
  });

  const newTopIDs = children.map((workPackage) => workPackage.id);

  return [
    ...children,
    ...extractImmediateDescendants(newTopIDs, scope, level - 1)
  ];
};

export const extractAllAncestors = (workPackage, allWorkPackages) => {
  const ancestors = [];
  // look for the parent of workPackage, then each of its parents from allWorkPackages
  let parent = allWorkPackages.find((wp) => wp.id === workPackage.parent_id);
  while (parent) {
    ancestors.push(parent);
    parent = allWorkPackages.find((wp) => wp.id === parent.parent_id);
  }
  return ancestors;
};

// return all descendants of a workPackage within the provided scope, even if their intermediate ancestors are not in the scope
export const extractAllDescendants = (
  topIDs,
  scope,
  allWorkPackages = scope
) => {
  // find all the members of scope that inherit somehow from the topIDs, and include intermediate work packages regardless of scope;
  const descendants = [];
  scope.forEach((workPackage) => {
    // traverse the parent hierarchy looking for a workPackage that is in the provided scope
    let inScope = false;
    let item = workPackage;
    const intermediateParents = [];
    while (!inScope && item) {
      const parentId = item.parent_id;
      inScope = topIDs.includes(item.parent_id); // item's parent is in scope
      if (!inScope) {
        intermediateParents.push(item);
        item = allWorkPackages.find((wp) => wp.id === parentId); // try it again with the parent's parent if any
      }
    }
    if (inScope) {
      if (!descendants.find((d) => d.id === item.id)) descendants.push(item);
      intermediateParents.forEach((p) => {
        if (!descendants.find((d) => d.id === p.id)) descendants.push(p);
      });
    }
  });

  return descendants;
};

export const extractDeletedDescendants = (topIDs, scope, level = 0) => {
  if (level === 0) return [];

  const childrenLevel = level - 1;

  const children = scope.filter(
    (workPackage) =>
      topIDs.includes(workPackage.removed_parent_id) && !!workPackage.deleted_at
  );

  const newTopIDs = children.map((workPackage) => workPackage.id);

  return [
    ...children,
    ...extractDeletedDescendants(newTopIDs, scope, childrenLevel)
  ];
};

export const extractChildren = (parentId, scope) => {
  const children = scope.filter(
    (workPackage) => parentId === workPackage.parent_id
  );

  return children;
};

export const extractNonDeletedChildren = (parentId, scope) => {
  const children = scope.filter(
    (workPackage) =>
      parentId === workPackage.parent_id && !workPackage.deleted_at
  );

  return children;
};

export const extractUnfinishedDescendants = (currentWorkPackage, scope) => {
  const unfinishedResults = scope.filter(
    (workPackage) =>
      workPackage.type === 'Result' &&
      workPackage.state !== WorkPackageStatuses.ACCEPTED &&
      workPackage.state !== WorkPackageStatuses.SHIPPABLE &&
      workPackage.state !== WorkPackageStatuses.LIVE &&
      workPackage.id !== currentWorkPackage.id &&
      !workPackage.deleted_at
  );

  return extractAllDescendants(
    [currentWorkPackage.id],
    unfinishedResults,
    scope
  );
};

const findDescendants = (result, scope, isOwnerless, ownerId, showDeleted) => {
  const showTrash = getShowTrash();

  if (isOwnerless) {
    return scope.filter((wp) => {
      if (showTrash || showDeleted) {
        return wp.parent_id === result.id && !wp.owner_id;
      }
      return wp.parent_id === result.id && !wp.owner_id && !wp.deleted_at;
    });
  }

  if (ownerId) {
    return scope.filter((wp) => {
      if (showTrash || showDeleted) {
        return wp.parent_id === result.id && wp.owner_id === ownerId;
      }
      return (
        wp.parent_id === result.id && !wp.deleted_at && wp.owner_id === ownerId
      );
    });
  }
  return scope.filter((wp) => {
    if (showTrash || showDeleted) {
      return wp.parent_id === result.id;
    }
    return wp.parent_id === result.id && !wp.deleted_at;
  });
};

const extractResultChildren = (
  result,
  scope,
  getAll,
  isOwnerless,
  ownerId,
  showDeleted
) => {
  if (getAll) {
    return findDescendants(result, scope, isOwnerless, ownerId, showDeleted);
  }
  return extractUnfinishedDescendants(result, scope);
};

export const extractChildrenOneLevelBelow = (
  result,
  getAll,
  isOwnerless,
  ownerId,
  showDeleted
) => {
  let scope;
  switch (result.level) {
    case 4:
      scope = Store.getState().workPackageReducer.capabilities;
      break;
    case 3:
      scope = Store.getState().workPackageReducer.features;
      break;
    case 2:
      scope = Store.getState().workPackageReducer.stories;
      break;
    default:
      break;
  }
  if (result.level > 1) {
    return extractResultChildren(
      result,
      scope,
      getAll,
      isOwnerless,
      ownerId,
      showDeleted
    );
  }
  return [];
};

export const descendantsInTarget = (currentResult, targetResults) =>
  extractImmediateDescendants(
    [currentResult.id],
    targetResults,
    currentResult.level
  );

export const descendantsInTargetExist = (currentResult, targetResults) =>
  descendantsInTarget(currentResult, targetResults).length > 0;

export const unfinishedDescendantsAndAncestorsNotInTarget = (
  currentResult,
  scope,
  target
) => {
  const descendants = extractUnfinishedDescendants(currentResult, scope);

  const workPackageIdsInTarget = target.workPackageIds
    ? target.workPackageIds
    : target.work_packages.map((wp) => wp.id);

  const ancestorsNotInTarget = extractAllAncestors(currentResult, scope).filter(
    // exclude ancestors in another target; need to be moved explicitly
    (result) => !workPackageIdsInTarget.includes(result.id) && !result.target_id
  );

  const descendantsNotInTarget = descendants.filter(
    (result) => !workPackageIdsInTarget.includes(result.id)
  );

  // return an array of ancestorsNotInTarget and descendantsNotInTarget
  return [...ancestorsNotInTarget, ...descendantsNotInTarget];
};
