import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Option from './Option';

const OptionsContainer = ({
  options,
  openDropDown,
  activeIndex,
  setActiveIndex,
  handleOptionClick
}) => {
  const dropDownRef = useRef();
  const [overflowing, setOverflowing] = useState(false);

  useLayoutEffect(() => {
    // check to see if dropdown is flowing past page bottom
    const { y, height } = dropDownRef.current.getBoundingClientRect();
    const bottomPosition = y + height + 20;
    if (bottomPosition > window.innerHeight) {
      setOverflowing(true);
    } else {
      setOverflowing(false);
    }
  }, [openDropDown]);

  return (
    <div
      className={`custom-select-dropdown ${overflowing ? 'overflowing' : ''}`}
      ref={dropDownRef}
    >
      {options.length ? (
        options.map((option, index) => (
          <Option
            key={`${option.label}-${index}`}
            option={option}
            index={index}
            active={index === activeIndex}
            setActiveIndex={setActiveIndex}
            handleOptionClick={handleOptionClick}
          />
        ))
      ) : (
        <div className="custom-select-option default">No options match</div>
      )}
    </div>
  );
};

OptionsContainer.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  openDropDown: PropTypes.bool,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  handleOptionClick: PropTypes.func
};

export default OptionsContainer;
