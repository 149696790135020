import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cloneDeep } from 'lodash';
import {
  allows,
  handleProductAuthorityChange
} from '../../utils/authorization';
import modalTypes from '../../constants/modalTypes';
import {
  createProduct,
  updateProduct,
  deleteProduct
} from '../../actions/productActions';
import { openModal, updateModal } from '../../actions/modalActions';
import { applicationError } from '../../actions/notificationActions';
import { getChildrenProgress } from '../../actions/workPackageActions';
import { filterEpicsByPropsProduct } from '../../selectors/workPackageSelector';

import ShareButton from '../Common/shareButton';
import ShortNameField from '../Common/shortNameField';
import ModalNavigation from '../Common/modalNavigation';
import DetailStatus from '../Common/detailStatus';
import DetailsTab from './detailsTab';
import TeamsTab from './teamsTab';
import ScopeTab from './scopeTab';
import DetailHeader from './detailHeader';

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShortNameValid: true,
      hasChanged: false,
      activeTab: 'details',
      ...this.defaultProductAttributes()
    };
  }

  componentDidMount() {
    this.props.getChildrenProgress(this.props.children);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.product !== this.props.product &&
      !!(this.props.product && this.props.product.id)
    ) {
      this.setState({ product: this.props.product, hasChanged: false });
    }
  }

  defaultProductAttributes = () => {
    let { product } = this.props;
    if (product && product.id) return { product };

    const organizationId = this.props.parentId;
    let attachmentList = [];
    product = {
      ...this.props.product,
      short_name: '',
      description: '',
      organization_id: organizationId,
      success_criteria_attributes: [],
      owner_id: null,
      teams: []
    };

    if (sessionStorage.attachmentList) {
      attachmentList = JSON.parse(sessionStorage.getItem('attachmentList'));
      sessionStorage.removeItem('attachmentList');
    }

    return { product, attachmentList };
  };

  closeForm = () => {
    const { hasChanged } = this.state;

    if (hasChanged) {
      const message = 'Do you want to save the changes you made in this form?';
      const actions = {
        save: {
          name: 'Save',
          function: this.createNewProduct
        },
        dontSave: {
          name: "Don't save",
          function: this.props.onClose
        }
      };
      this.props.openModal(
        modalTypes.message,
        {
          message,
          actions,
          closeButtonTitle: 'Cancel'
        },
        'confirm'
      );
    } else {
      this.props.onClose();
    }
  };

  changeTab = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  createNewProduct = () => {
    sessionStorage.removeItem('product');
    sessionStorage.removeItem('attachmentList');
    const { product, attachmentList } = this.state;
    const { teams = [] } = product;
    if (!product.short_name) {
      this.setState({ isShortNameValid: false });
      return;
    }
    const productCopy = { ...product };
    productCopy.team_ids = teams.map((team) => team.id);
    this.props.createProduct({ product: { ...productCopy } }, attachmentList);
  };

  handleNewFieldChange = (e) => {
    const { product } = this.state;
    let hasChanged = true;
    const productCopy = { ...product };
    if (e.short_name) {
      productCopy.short_name = e.short_name;
      this.setState({ isShortNameValid: true });
    } else if (e.description) {
      productCopy.description = e.description;
    } else if (e.owner_id || e.owner_id === null) {
      productCopy.owner_id = e.owner_id;
    } else if (e.chief_product_owner_id || e.chief_product_owner_id === null) {
      productCopy.chief_product_owner_id = e.chief_product_owner_id;
    } else if (e.state) {
      productCopy.state = e.state;
    } else if (e.target && e.target.name) {
      productCopy[e.target.name] = e.target.value;
    } else {
      hasChanged = false;
    }
    this.setState({ product: productCopy, hasChanged });
  };

  handleKeyPress = ({ key, target }) => {
    const { product } = this.state;
    const productCopy = { ...product };
    if (key === 'Enter' && !product.id) {
      productCopy[target.name] = target.value;
      this.setState({ product: productCopy }, () => this.createNewProduct());
    }
  };

  handleFieldChange = (e) => {
    const { product } = this.props;
    const field = e;
    const productCopy = { ...product };
    const fieldName = Object.keys(field)[0];
    if (fieldName === 'short_name') {
      if (!field.short_name) {
        productCopy.short_name = field.short_name;
        return this.setState({ isShortNameValid: false, product: productCopy });
      }
      if (field.short_name === product.short_name) return null;
    }
    this.props.updateProduct(product.id, field);
  };

  handleSelectChange = (currValue, name) => {
    const { product } = this.state;
    if (!product.id) {
      const productClone = { ...product, [name]: currValue };
      this.setState({ product: productClone, hasChanged: true });
      return;
    }

    this.props.updateProduct(product.id, { [name]: currValue });
  };

  addCriteria = (criterion) => {
    const { product } = this.state;
    const productCopy = { ...product };
    const criteriaClone = cloneDeep(productCopy.success_criteria_attributes);

    if (criterion.tempId) {
      const index = product.success_criteria_attributes.findIndex(
        (item) => item.tempId === criterion.tempId
      );
      criteriaClone[index].short_name = criterion.short_name;
      productCopy.success_criteria_attributes = criteriaClone;
    } else {
      criteriaClone.push({
        ...criterion,
        tempId: product.success_criteria_attributes.length + 1
      });
      productCopy.success_criteria_attributes = criteriaClone;
    }
    this.setState({ product: productCopy });
  };

  removeCriteria = (criterionIndex) => {
    const { product } = this.state;
    const productCopy = { ...product };

    productCopy.success_criteria_attributes = product.success_criteria_attributes.filter(
      (_, index) => index !== criterionIndex
    );

    this.setState({ product: productCopy });
  };

  addAttachment = (attachment) => {
    const { attachmentList } = this.state;
    const attachmentListCopy = [...attachmentList];
    this.setState({ attachmentList: attachmentListCopy.concat(attachment) });
  };

  removeAttachment = (attachmentIndex) => {
    const { attachmentList } = this.state;
    const newAttachmentList = attachmentList.filter(
      (att, index) => index !== attachmentIndex
    );
    this.setState({ attachmentList: newAttachmentList });
  };

  addTemporaryTeam = (team) => {
    const { product } = this.state;
    let updatedProduct = { ...product };
    const updatedProductTeams = [...updatedProduct.teams];

    if (updatedProduct.teams && updatedProduct.teams.length > 0) {
      updatedProductTeams.push(team);
      updatedProduct.teams = updatedProductTeams;
    } else {
      updatedProduct.teams = [team];
    }
    updatedProduct = handleProductAuthorityChange(updatedProduct);
    this.setState({ product: updatedProduct });
  };

  removeTemporaryTeam = (teamId) => {
    const { product } = this.state;
    let updatedProduct = { ...product };
    const { teams = [] } = updatedProduct;
    updatedProduct.teams = teams.filter((team) => team.id !== teamId);
    updatedProduct = handleProductAuthorityChange(updatedProduct);
    this.setState({ product: updatedProduct });
  };

  render() {
    if (!this.props.isOpen) return null;

    const { isLoading } = this.props;
    const { product, isShortNameValid } = this.state;
    const allowEdit = product.id ? allows('update', product, 'product') : true;
    return (
      <div
        className="detail-modal workpackage-detail product-detail"
        tabIndex="-1"
        role="dialog"
      >
        <div className="detail-dialog" role="document">
          {isLoading ? <div id="loader" /> : null}
          <div className="detail-content">
            <div className="modal-header detail-header">
              <DetailHeader
                product={product}
                options={this.props.options}
                onClose={this.closeForm}
                handleUpdate={this.handleFieldChange}
                handleDelete={this.props.deleteProduct}
              />
            </div>
            <div className="modal-body">
              <div className="short-name-box">
                <ShortNameField
                  autoFocus
                  shortName={product.short_name}
                  onNameChange={
                    product.id
                      ? this.handleFieldChange
                      : this.handleNewFieldChange
                  }
                  allowEdit={allowEdit}
                  onKeyUp={this.handleKeyPress}
                  isValid={isShortNameValid}
                />
                {product.id && <ShareButton />}
              </div>
              <ModalNavigation
                tabs={['details', 'scope', 'teams']}
                activeTab={this.state.activeTab || 'details'}
                changeTab={this.changeTab}
              >
                <DetailsTab
                  product={product}
                  users={this.props.userList.users}
                  handleUpdate={
                    product.id
                      ? this.handleFieldChange
                      : this.handleNewFieldChange
                  }
                  selectUpdate={this.handleSelectChange}
                  addCriteria={this.addCriteria}
                  removeCriteria={this.removeCriteria}
                  attachmentList={this.state.attachmentList}
                  addAttachment={this.addAttachment}
                  removeAttachment={this.removeAttachment}
                />
                <ScopeTab product={product} />
                <TeamsTab
                  product={product}
                  addTemporaryTeam={this.addTemporaryTeam}
                  removeTemporaryTeam={this.removeTemporaryTeam}
                  allowEdit={allowEdit}
                />
              </ModalNavigation>
            </div>
            <div className="modal-footer">
              <div className="row footer-selects">
                {!product.id && (
                  <div className="col-md-3">
                    <div className="save-cancel-buttons">
                      <label>&nbsp;</label>
                      <button
                        type="button"
                        className="btn btn-md button-lavender"
                        onClick={this.createNewProduct}
                        disabled={isLoading}
                      >
                        <strong>save</strong>
                      </button>
                      <button
                        type="button"
                        className="btn btn-md btn-default button-normal"
                        onClick={this.closeForm}
                        disabled={isLoading}
                      >
                        <strong>Cancel</strong>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <DetailStatus
                isNew={!product.id}
                resultId={product.id}
                status={product.state}
                handleNewFieldChange={this.handleNewFieldChange}
                allowEdit={allowEdit}
                type="product"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProductDetail.propTypes = {
  id: PropTypes.number
};

const mapStateToProps = (state, ownProps) => {
  const product = state.productReducer.products.find(
    (p) => p.id === ownProps.id
  );

  const epics = product ? filterEpicsByPropsProduct(state, { product }) : [];
  const children = product ? state.workPackageReducer.all.filter((child) =>
    epics.find((e) => e.id === child.parent_id)
  ) : [];
  return {
    userList: state.userReducer,
    options: state.defaultOptionsReducer.defaultOptions,
    product,
    epics,
    children,
    isLoading: state.productReducer.isSending
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal,
      updateModal,
      createProduct,
      updateProduct,
      deleteProduct,
      applicationError,
      getChildrenProgress
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
