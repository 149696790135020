import React, { useState, useEffect } from 'react';
import CustomSelect from './CustomSelect/SelectField';

const OwnerDropdown = ({
  ownerOptions,
  ownerId,
  handleOwnerAddition,
  handleOwnerRemoval,
  handleOwnerEdit,
  taskId,
  disabled
}) => {
  const [selectedOwner, setSelectedOwner] = useState({
    label: '',
    value: null
  });

  useEffect(() => {
    const selected = ownerOptions.find(owner => owner.value === ownerId) || {
      label: '',
      value: null
    };
    setSelectedOwner(selected);
  }, [ownerId]);

  const handleChange = option => {
    const oldOwner =
      ownerOptions.find(owner => owner.value === selectedOwner.value) || null;

    const newOwnerId = option.value || null;

    if (oldOwner === null || oldOwner.value === null) {
      handleOwnerAddition(taskId, newOwnerId);
    } else if (newOwnerId === null) {
      handleOwnerRemoval(taskId, oldOwner.value);
    } else {
      handleOwnerEdit(taskId, oldOwner.value, newOwnerId);
    }
  };

  return (
    <CustomSelect
      options={ownerOptions}
      selectedOption={selectedOwner}
      handleOptionChange={handleChange}
      disabled={disabled}
      sortItems={true}
    />
  );
};

export default OwnerDropdown;
