import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '../Common/CustomSelect/SelectField';

const TeamDropDown = ({ selectedTeamId, handleTeamChange, teams }) => {
  const options = teams.map((team) => ({
    label: team.short_name,
    value: team.id
  }));
  const selectedOption = options.find(
    (option) => option.value === selectedTeamId
  );

  const handleChange = (option) => {
    handleTeamChange(option.value);
  };
  const defaultOption = { label: '', value: null };
  return (
    <div className="ac-team-dropdown-container">
      <SelectField
        selectedOption={selectedOption || defaultOption}
        handleOptionChange={handleChange}
        options={options}
        placeholder="Select a Team"
      />
    </div>
  );
};

TeamDropDown.propTypes = {
  selectedTeamId: PropTypes.number.isRequired,
  handleTeamChange: PropTypes.func.isRequired,
  teams: PropTypes.array
};

export default TeamDropDown;
