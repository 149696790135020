import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '../Common/CustomSelect/SelectField';

const TypeDropDown = ({
  handleInputTypeChange,
  acInputType,
  organizationLevels,
  generateOptionValue
}) => {
  const options =
    organizationLevels.map(orgLevel => ({
      label: orgLevel.name,
      value: generateOptionValue(orgLevel.name)
    }));

const defaultOption = { label: "", value: null }
  const selectedOption = options.find(option => option.value === acInputType)

  const handleChange = option => {
    handleInputTypeChange(option.value);
  };
  
  return (
    <div className="ac-type-dropdown-container">
      <SelectField
        selectedOption={selectedOption || defaultOption }
        handleOptionChange={handleChange}
        options={options}
        placeholder="Select a Type"
      />
    </div>
  );
};

TypeDropDown.propTypes = {
  handleInputTypeChange: PropTypes.func.isRequired,
  acInputType: PropTypes.string.isRequired,
  organizationLevels: PropTypes.array.isRequired,
  generateOptionValue: PropTypes.func.isRequired
}

export default TypeDropDown;
