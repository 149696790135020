import React, { Fragment } from 'react';
import divider from '../../../assets/images/large-card-nav-divider.png';

const ModalNavigation = ({
  changeTab,
  activeTab,
  tabs,
  children,
  metricsDisabled
}) => (
  <>
    <ul className="nav nav-tabs" role="tablist">
      {tabs.map((tab, index) => {
        const disabled = tabs[index] === 'metrics' && metricsDisabled;
        const tabKey = `${tabs[index]}_${index}`;
        return (
          <li
            key={tabKey}
            role="presentation"
            className={`${activeTab === tab ? 'active' : ''}`}
          >
            <a
              id={tabs[index]}
              href={`#${tab}`}
              aria-controls="details"
              role="tab"
              data-toggle="tab"
              onClick={disabled ? undefined : () => changeTab(tab)}
              disabled={disabled}
              className={disabled ? 'tab-disabled' : ''}
            >
              {tab.toUpperCase()}
            </a>
            {index !== tabs.length - 1 ? <img src={divider} alt="" /> : null}
          </li>
        );
      })}
    </ul>
    {children && (
      <div className="tab-content">
        {children.map((child, index) => (
          <div
            role="tabpanel"
            className={`tab-pane ${activeTab === tabs[index] ? 'active' : ''}`}
            id={tabs[index]}
            key={tabs[index]}
          >
            {child}
          </div>
        ))}
      </div>
    )}
  </>
);

export default ModalNavigation;
