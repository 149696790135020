import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import CollabEditor from './collabEditor';
import { fetchUserInfo } from '../../actions/userActions';
import expandIcon from '../../../assets/images/expandArrows.png';
import collapseIcon from '../../../assets/images/collapseArrows.png';

function DescriptionField({
  user,
  id,
  type,
  onFieldChange,
  isFull,
  toggleIsFull,
  allowEdit,
  description
}) {
  const [userStates, setUserStates] = useState([]);
  const [mounted, setMounted] = useState(true);
  useEffect(() => {
    const tabChangeHandler = () => {
      if (!id) return;
      if (document.visibilityState === 'hidden') {
        setMounted(false);
        setUserStates([]);
      } else if (document.visibilityState === 'visible') {
        setMounted(true);
      }
    };

    document.addEventListener('visibilitychange', tabChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', tabChangeHandler);
    };
  }, []);

  if (mounted)
    return (
      <div id={`description${isFull ? '-full' : ''}`}>
        <CollabEditor
          entityType={type}
          entityId={id}
          username={user.name}
          onUserStatesChange={setUserStates}
          onFieldChange={onFieldChange}
          isFull={isFull}
          allowEdit={allowEdit}
          description={description}
          userStates={userStates}
        />
        <img
          src={isFull ? collapseIcon : expandIcon}
          className={`resize-btn ${isFull ? 'full' : ''}`}
          onClick={toggleIsFull}
        />
        <ul className="quill-user-list">
          {userStates.map((userState) => (
            <li key={userState.user.id}>
              <UserColor color={userState.user.color} />
              {userState.user.name}
            </li>
          ))}
        </ul>
      </div>
    );
  return null;
}

DescriptionField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  onFieldChange: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool
};

const mapStateToProps = (state) => ({ user: state.userReducer.user });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchUserInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionField);

const UserColor = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  display: inline-block;
  margin-right: 0.5rem;
`;
