import {
  getSelectedSpace,
  getOrganization,
  getFirstOrganization
} from './reduxHelpers';

export const currentOrganization = () => {
  const selectedSpace = getSelectedSpace();
  let selectedOrganizationID;
  if (selectedSpace.type === 'team') {
    selectedOrganizationID = selectedSpace.organization_id;
  } else if (selectedSpace.type === 'product') {
    selectedOrganizationID = selectedSpace.organization_id;
  }
  return selectedOrganizationID
    ? getOrganization(selectedOrganizationID)
    : getFirstOrganization() || [];
};

export const generateOrgLevelDefaults = (organizationLevels) => {
  const filteredDefaults = organizationLevels.filter(
    (orgLevel) => orgLevel.default_level === true
  );
  const sortedDefaults = filteredDefaults.sort((a, b) => a.level - b.level);
  return sortedDefaults;
};
