import { createSelector } from 'reselect';

const getTypedTeamCriteria = (state, props) =>
  state.criteriaReducer.criteria[props.type]?.team;

const getTeamId = (_, props) => props.teamId;

const getOrganization = (state) => state.organizationReducer.organizations[0];

export const currentTeamCriteria = createSelector(
  [getTypedTeamCriteria, getTeamId],
  (criteria, teamId) => {
    if (!criteria) return [];
    return criteria.filter((c) => c.team_id === teamId);
  }
);

export const orgLevelsForCriteria = createSelector(
  [getOrganization],
  (organization) => {
    if (!organization) return [];
    const organizationLevels = organization.organization_levels || [];
    return organizationLevels.filter(
      (orgLevel) => orgLevel.level === 1 && !orgLevel.deleted_at
    );
  }
);
