import { allows } from './authorization';
import Store from '../store/store';
import { createTeam } from '../actions/teamActions';

// eslint-disable-next-line import/prefer-default-export
export const getTeamOptions = (teams) =>
  teams.map((option) => {
    const disabled = !allows('update', { team_id: option.id });
    return {
      label: option.short_name || option.name,
      value: option.id,
      disabled
    };
  });

export const createNewTeamHelper = (teamName, orgId) => {
  const newTeam = {
    short_name: teamName,
    kanban: false
  };

  Store.dispatch(createTeam(newTeam, orgId));
};
