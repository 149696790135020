import { cloneDeep } from 'lodash';
import Store from '../store/store';
import {
  getTeam,
  getProduct,
  getUser,
  getOrganization,
  getUserOrganization
} from './reduxHelpers';

const getTeamId = (object, type) => {
  switch (type) {
    case 'workPackage':
      return (object.team && object.team.id) || object.team_id;
    case 'task': {
      const parent = Store.getState().workPackageReducer.all.find(
        (w) => w.id === object.work_package_id
      );
      return parent && getTeamId(parent, 'workPackage');
    }
    case 'team':
      return object.id;
    default:
      return object.team_id;
  }
};

const getProductId = (object, type) =>
  type === 'product' ? object.id : object.product_id;

const getOrganizationId = (object, type) =>
  type === 'organization' ? object.id : object.organization_id;

export const allowsUser = (actionName, state = Store.getState()) => {
  const { user } = state.userReducer;

  // TODO: find a better way to do this -- perhaps hydrate user.organizations in fetchUser (or fetchUserInfo? one of those)
  // TODO: We really need to be consistent about where to fetch Team / Org permissions from: user object, or Teams/Orgs objects
  const { organizationReducer } = state;

  const allTeamsPermissions = user?.teams
    ? user.teams.flatMap((t) => t.permissions)
    : [];

  const allOrganizationPermissions = Array.isArray(
    organizationReducer.organizations
  )
    ? organizationReducer.organizations.flatMap((o) => o.permissions)
    : [];

  return (
    allTeamsPermissions
      .concat(allOrganizationPermissions)
      .includes(actionName) || allOrganizationPermissions.includes('administer')
  );
};

export const allows = (actionName, object, type = 'workPackage') => {
  const teamId = getTeamId(object, type);
  const productId = getProductId(object, type);
  const organizationId = getOrganizationId(object, type);

  if (teamId) {
    const team = getTeam(teamId);
    if (team && team.permissions.includes(actionName)) return true;
    return false;
  }
  if (productId) {
    const product = getProduct(productId);

    const isTeamAuthorized = product?.teams?.some(
      (team) =>
        // only team admins can get their permissions through the product
        allows('administer', team, 'team') && allows(actionName, team, 'team')
    );
    if (isTeamAuthorized) return true;

    const organization =
      Store.getState().organizationReducer.organizations.find(
        (o) => product && o.id.toString() === product.organization_id.toString()
      );

    if (organization?.permissions?.includes(actionName)) return true;
    return false;
  }

  let organization;
  if (type === 'organization' && object.id !== -1) organization = object;
  else
    organization = organizationId
      ? getOrganization(organizationId)
      : getUserOrganization();
  if (
    organization &&
    (organization.permissions.includes(actionName) ||
      // ensure org admins can do everything; this permission is added to organizations in organization.rb model
      organization.permissions.includes('administer'))
  )
    return true;

  return false;
};

export const allowsThroughOrganizationOrTeam = (
  actionName,
  organizationId,
  teamId
) => {
  const organization = getOrganization(organizationId);
  const team = getTeam(teamId);

  const allowsWithinOrganization =
    organization && organization.permissions.includes(actionName);
  const allowsWithinTeam =
    team?.permissions && team.permissions.includes(actionName);

  return allowsWithinOrganization || allowsWithinTeam;
};

const authorizedWorkerIds = (teams) =>
  teams.reduce(
    (ids, team) =>
      Array.from(new Set([...ids, ...team.authorized_result_owner_ids])),
    []
  );

export const updatableTeams = (teams) =>
  teams.filter((team) => allows('update', team, 'team'));

export const isAuthorizedWorker = (user) => {
  const { selectedSpace } = Store.getState().workPackageReducer;

  if (selectedSpace.type === 'team') {
    return (
      selectedSpace.authorized_result_owner_ids.includes(user.id) &&
      !user.deleted_at
    );
  }
  if (selectedSpace.type === 'product') {
    for (let i = 0; i < selectedSpace.teams.length; i++) {
      if (
        selectedSpace.teams[i].authorized_result_owner_ids.includes(user.id) &&
        !user.deleted_at
      )
        return true;
    }
    return false;
  }
};

export const authorizeWorkerAgainstProductTeams = (user, product) => {
  for (let i = 0; i < product.teams.length; i++) {
    if (
      !user.deleted_at &&
      product.teams[i].authorized_result_owner_ids.includes(user.id)
    ) {
      return true;
    }
  }
  return false;
};

export const authorizeOwnerAgainstProductTeams = (user, product) => {
  for (let i = 0; i < product.teams.length; i++) {
    if (
      !user.deleted_at &&
      product.teams[i].authorized_product_owner_ids.includes(user.id)
    ) {
      return true;
    }
  }
  return false;
};

const userReference = (user) => {
  if (typeof user === 'number') {
    return getUser(user);
  }
  return user;
};

// in the next two functions, the argument `user` can either be an id or a whole object
export const isAuthorizedWorkerForProduct = (user, productId) => {
  const product = getProduct(productId);
  return authorizeWorkerAgainstProductTeams(userReference(user), product);
};

export const isAuthorizedWorkerForTeam = (user, teamId) => {
  const team = getTeam(teamId);
  const userRef = userReference(user);
  return (
    team.authorized_result_owner_ids.includes(userRef.id) && !userRef.deleted_at
  );
};

export const isAuthorizedOwnerForProduct = (user, productId) => {
  const product = getProduct(productId);
  return authorizeOwnerAgainstProductTeams(userReference(user), product);
};

export const isAuthorizedOwnerForTeam = (user, teamId) => {
  const team = getTeam(teamId);
  const userRef = userReference(user);
  return (
    team.authorized_product_owner_ids.includes(userRef.id) &&
    !userRef.deleted_at
  );
};

export const isProductAssignedToTeam = (teamId, productId) => {
  const product = getProduct(productId);
  const team = getTeam(teamId);
  return !!product.teams.find((t) => t.id === team.id);
};

export const handleResultAuthorityChange = (result, name, currValue) => {
  const resultClone = cloneDeep(result);

  const { team_id, owner_id, product_owner_id } = result;

  if (currValue) {
    if (name === 'product_id') {
      if (team_id && !isProductAssignedToTeam(team_id, currValue)) {
        resultClone.team_id = null;
      }

      if (owner_id) {
        if (!isAuthorizedWorkerForProduct(owner_id, currValue)) {
          resultClone.owner_id = null;
        }
      }

      if (product_owner_id) {
        if (!isAuthorizedWorkerForProduct(product_owner_id, currValue)) {
          resultClone.product_owner_id = null;
        }
      }
    }

    if (name === 'team_id') {
      if (owner_id) {
        if (!isAuthorizedWorkerForTeam(owner_id, currValue)) {
          resultClone.owner_id = null;
        }
      }

      if (product_owner_id) {
        if (!isAuthorizedWorkerForTeam(product_owner_id, currValue)) {
          resultClone.product_owner_id = null;
        }
      }
    }
  }
  resultClone[name] = currValue;

  if (!resultClone.product_id && !resultClone.team_id) {
    resultClone.owner_id = null;
    resultClone.product_owner_id = null;
  }

  return resultClone;
};

export const handleProductAuthorityChange = (product) => {
  const productCopy = { ...product };

  const { teams = [], owner_id, chief_product_owner_id } = productCopy;

  if (teams.length > 0) {
    if (owner_id) {
      const authorizedOwner = teams.some((t) =>
        isAuthorizedWorkerForTeam(owner_id, t.id)
      );
      if (!authorizedOwner) {
        productCopy.owner_id = null;
      }
    }

    if (chief_product_owner_id) {
      const authorizedOwner = teams.some((t) =>
        isAuthorizedWorkerForTeam(chief_product_owner_id, t.id)
      );
      if (!authorizedOwner) {
        productCopy.chief_product_owner_id = null;
      }
    }
  } else {
    productCopy.owner_id = null;
    productCopy.chief_product_owner_id = null;
  }

  return productCopy;
};
