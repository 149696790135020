import React from 'react';
import ResultsTable from '../WorkPackage/resultsTable';
import StoryList from '../PlanWall/storyList';
import AddStory from '../Common/addStory';

const CadencePlanTab = ({
  dropDown,
  resultIds,
  results,
  cadence,
  currentUser,
  allowEdit,
  organizationLevel,
  setDropdown,
  handleAddWorkPackageToCadence,
  handleRemoveWorkPacakgeFromCadence
}) => (
  <div role="tabpanel" id="plan">
    <div className="flexbox">
      <div className="cadence-plan-list">
        <ResultsTable
          noPreserve
          dropDown={dropDown}
          setDropdown={setDropdown}
          resultIds={resultIds}
          placement="CadencePlanTab"
        />
      </div>
      <div className="cadence-plan-side">
        <div className="direct">Drag items here to add them to the sprint</div>
        <div className={`summary ${cadence.id === 0 ? 'is-new' : ''}`}>
          <span>Items: {results.filter((r) => !r.deleted_at).length}</span>
          {cadence.id !== 0 && (
            <>
              <span>
                Plan:{' '}
                {cadence.plan_estimate
                  ? Math.round(cadence.plan_estimate * 10) / 10
                  : 0}
              </span>
              <span>
                ToDo: {cadence.todo ? Math.round(cadence.todo * 10) / 10 : 0}
              </span>
            </>
          )}
        </div>
        <StoryList
          results={results}
          source="side"
          parentId={cadence.id}
          add={handleAddWorkPackageToCadence}
          delete={handleRemoveWorkPacakgeFromCadence}
          dropDown={dropDown}
          setDropdown={setDropdown}
          mini
          cadence
        />
      </div>
    </div>
    <AddStory
      ownerId={currentUser.id}
      disabled={!allowEdit}
      cadenceId={cadence.id}
      label="STORY"
      organizationLevel={organizationLevel}
      level={1}
      placement="cadence"
    />
  </div>
);

export default CadencePlanTab;
