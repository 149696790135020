import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const EditInput = ({
  handleChange,
  editInput,
  handleSubmitEdit,
  criterium,
  clearEditFields
}) => {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleOnKeyDown = e => {
    if (e.key === 'Enter') {
      if (criterium.short_name === editInput) {
        clearEditFields();
        return;
      }
      handleSubmitEdit();
    }
  };

  const handleOnBlur = () => {
    if (criterium.short_name === editInput) {
      clearEditFields();
      return;
    }
    handleSubmitEdit();
  };

  return (
    <input
      type="text"
      name="editInput"
      className="ac-edit-input"
      onChange={handleChange}
      value={editInput}
      ref={inputRef}
      onBlur={handleOnBlur}
      onKeyDown={handleOnKeyDown}
    />
  );
};

EditInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  editInput: PropTypes.string.isRequired,
  handleSubmitEdit: PropTypes.func.isRequired,
  clearEditFields: PropTypes.func.isRequired,
  criterium: PropTypes.object.isRequired
};

export default EditInput;
