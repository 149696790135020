import React from 'react';
import { Group } from '@vx/group';
import { BarGroup, Line, Circle } from '@vx/shape';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { Text } from '@vx/text';
import { LegendOrdinal, LegendItem, LegendLabel } from '@vx/legend';
import localPoint from '@vx/event/build/localPoint';
import { withTooltip, TooltipWithBounds } from '@vx/tooltip';
import { capitalize } from 'lodash';
import CustomSelect from '../Common/CustomSelect/SelectField';
import { CadenceStatuses } from '../../constants/storyStatusTypes';

const committedColor = '#858e91';
const actualColor = '#00924c';
const velocityColor = '#000000';
const averageColor = '#005C8E';
const bg = '#DBDCDD';

class Velocity extends React.Component {
  constructor(props) {
    super(props);
    const { sprints } = props;
    const acceptedCount = sprints.filter(
      (s) => s.status === CadenceStatuses.ACCEPTED
    ).length;
    this.state = {
      sprintsSelected: acceptedCount > 3 ? 3 : acceptedCount
    };
  }

  selectAmountSprints = (option) => {
    this.setState({ sprintsSelected: option.value });
  };

  // accessors
  x0 = (d) => d.name;

  formatDate = (name) => name;

  generateOptions = () => {
    const { sprints } = this.props;

    return sprints
      .filter((s) => s.status === CadenceStatuses.ACCEPTED)
      .map((sprint, index) => ({
        label: `${index + 1} sprint${index > 0 ? 's' : ''}`,
        value: index + 1
      }));
  };

  handleMouseOverBar = (event, datum) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    this.props.showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum
    });
  };

  render() {
    const {
      width = 400,
      height = 300,
      margin = {
        top: 10
      },
      graphData = [],
    } = this.props;
    const { sprintsSelected } = this.state;

    if (graphData.length === 0) return null;

    const min = graphData.length - 7 >= 0 ? graphData.length - 7 : 0;
    const data = graphData.slice(min, graphData.length);
    const keys = Object.keys(data[0]).filter((d) => d !== 'name');

    // scales
    const x0Scale = scaleBand({
      domain: data.map(this.x0),
      padding: 0.2
    });
    const x1Scale = scaleBand({
      domain: keys,
      padding: 0.1
    });
    const yScale = scaleLinear({
      domain: [
        0,
        Math.max(...data.map((d) => Math.max(...keys.map((key) => d[key])))) + 5
      ]
    });
    // bars color
    const color = scaleOrdinal({
      domain: keys,
      range: [committedColor, actualColor]
    });
    // legend colors
    const ordinalColorScale = scaleOrdinal({
      domain: ['committed', 'actual', 'velocity', 'average'],
      range: [committedColor, actualColor, velocityColor, averageColor]
    });

    // bounds
    const xMax = width - 45;
    const yMax = height - margin.top - 24;

    x0Scale.rangeRound([0, xMax]);
    x1Scale.rangeRound([0, x0Scale.bandwidth()]);
    yScale.range([yMax, 0]);

    const sprintOptions = this.generateOptions();
    const selectedOption = sprintOptions.find(
      (option) => option.value === sprintsSelected
    );
    const lines = [];

    return (
      <>
        {/* Select amount of sprints to show the average */}
        <div
          className="velocity-select-container"
          onClick={(e) => e.stopPropagation()}
        >
          <label>Select Velocity Average</label>
          <CustomSelect
            options={sprintOptions}
            selectedOption={selectedOption}
            handleOptionChange={this.selectAmountSprints}
            containerClass="team-metrics-tab"
          />
        </div>
        <svg width={width} height={height} style={{ marginTop: '2px' }}>
          <rect
            x={0}
            y={0}
            key="velocity-rect"
            width={width}
            height={height}
            fill={bg}
          />
          <Group top={margin.top} left={25}>
            <BarGroup
              data={data}
              keys={keys}
              key="velocity-group"
              height={yMax}
              x0={this.x0}
              x0Scale={x0Scale}
              x1Scale={x1Scale}
              yScale={yScale}
              color={color}
            >
              {(barGroups) => {
                let lastX = 0;
                let lastY = 0;
                let firstY = 0;
                let avgY = 0;
                let avgX = 0;
                let pointsSum = 0;
                let lastBarWidth = 0;

                const velocityChart = barGroups.map((barGroup, groupIndex) => {
                  let currentY = 0;
                  let currentX = 0;
                  const sprintGroup = (
                    <Group
                      key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                      left={barGroup.x0}
                    >
                      {barGroup.bars.map((bar, index) => {
                        const barDraw = (
                          <g
                            key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          >
                            <rect
                              x={bar.x}
                              y={bar.y}
                              key={`bar-group-bar-rect${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                              width={bar.width}
                              height={bar.height}
                              fill={bar.color}
                              onMouseMove={(e) =>
                                this.handleMouseOverBar(e, {
                                  key: bar.key,
                                  value: bar.value
                                })}
                              onMouseOut={this.props.hideTooltip}
                            />
                          </g>
                        );
                        // Gets data only from the second bar (index === 1)
                        // which is the bar of stories accepted
                        if (index === 1) {
                          currentY = bar.y;
                          currentX = bar.x + bar.width / 2;
                          lastBarWidth = bar.width; // for extending the line to the right, below
                          // Get the first y for the average line
                          if (
                            groupIndex ===
                            barGroups.length - sprintsSelected
                          ) {
                            firstY = bar.y;
                          }
                          // Get the sum to calculate the average
                          if (
                            groupIndex >=
                            barGroups.length - sprintsSelected
                          ) {
                            pointsSum += bar.value;
                          }
                        }

                        return barDraw;
                      })}
                    </Group>
                  );
                  // Draw the average lines based on the amount of sprints
                  // selected
                  if (groupIndex === barGroups.length - sprintsSelected) {
                    lines.push([
                      <Circle
                        key={`velocitymarker-${groupIndex}-${lastY}`}
                        cx={barGroup.x0 + currentX}
                        cy={currentY}
                        r="5"
                        fill={velocityColor}
                      />
                    ]);
                    avgY = firstY;
                    avgX = barGroup.x0 + currentX;
                  } else if (
                    groupIndex > 0 &&
                    groupIndex > barGroups.length - sprintsSelected
                  ) {
                    lines.push([
                      <Line
                        key={`full-${groupIndex}-${lastY}`}
                        stroke={velocityColor}
                        from={{ x: lastX, y: lastY }}
                        to={{ x: barGroup.x0 + currentX, y: currentY }}
                        strokeWidth={2}
                      />,
                      <Circle
                        key={`velocitymarker-${groupIndex}-${lastY}`}
                        cx={barGroup.x0 + currentX}
                        cy={currentY}
                        r="5"
                        fill={velocityColor}
                      />
                    ]);
                    avgY += currentY;
                  }
                  lastX = barGroup.x0 + currentX;
                  lastY = currentY;

                  return sprintGroup;
                });

                avgY /= sprintsSelected;

                lines.push([
                  <Line
                    key="avg-velocity"
                    stroke={averageColor}
                    from={{ x: avgX, y: avgY }}
                    to={{ x: lastX + lastBarWidth, y: avgY }}
                    strokeWidth={2.5}
                    strokeDasharray="9,10"
                  />,
                  <Text
                    x={lastX + lastBarWidth}
                    y={avgY - 15}
                    key="avg-velocity-label"
                    fill={averageColor}
                    textAnchor="middle"
                    verticalAnchor="start"
                    style={{ fontWeight: 'bold', color: averageColor }}
                  >
                    {(pointsSum / sprintsSelected).toFixed(1)}
                  </Text>
                ]);
                return velocityChart;
              }}
            </BarGroup>

            <g>{lines}</g>
          </Group>
          <AxisLeft
            scale={yScale}
            top={margin.top}
            left={25}
            stroke="#1b1a1e"
            strokeWidth={2}
            numTicks={6}
            tickTextFill="#1b1a1e"
          />
          <AxisBottom
            left={25}
            top={yMax + margin.top}
            tickFormat={this.formatDate}
            scale={x0Scale}
            stroke="#1b1a1e"
            strokeWidth={2}
            tickStroke="1b1a1e"
            tickLabelProps={() => ({
              fill: '1b1a1e',
              fontSize: 11,
              textAnchor: 'middle'
            })}
          />
        </svg>
        <div className="velocity-legend">
          <LegendOrdinal
            scale={ordinalColorScale}
            labelFormat={(label) => `${label.toLowerCase()}`}
          >
            {(labels) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                {labels.map((label, i) => {
                  const size = 15;
                  return (
                    <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                      {(label.text === 'average' ||
                        label.text === 'velocity') && (
                        <svg width={30} height={size}>
                          <g fill="none" stroke={label.value} strokeWidth={3}>
                            <line
                              strokeDasharray={
                                label.text === 'average' ? '3, 3' : '0, 0'
                              }
                              x1={0}
                              y1={7}
                              x2={30}
                              y2={7}
                            />
                          </g>
                        </svg>
                      )}
                      {(label.text === 'committed' ||
                        label.text === 'actual') && (
                        <svg width={size} height={size}>
                          <rect fill={label.value} width={size} height={size} />
                        </svg>
                      )}
                      <LegendLabel align="left" margin="0 0 0 4px">
                        {label.text}
                      </LegendLabel>
                    </LegendItem>
                  );
                })}
              </div>
            )}
          </LegendOrdinal>
          {this.props.tooltipOpen && (
            <TooltipWithBounds
              key={Math.random()}
              top={this.props.tooltipTop}
              left={this.props.tooltipLeft}
            >
              {capitalize(this.props.tooltipData.key)}:{' '}
              {this.props.tooltipData.value}
            </TooltipWithBounds>
          )}
        </div>
      </>
    );
  }
}

export default withTooltip(Velocity);
