import Store from '../store/store';

export const getTeam = (teamId) =>
  Store.getState().teamReducer.teams.find((t) => t.id === teamId);

export const getTeams = () => Store.getState().teamReducer.teams;

export const getCurrentTeams = () =>
  Store.getState().defaultOptionsReducer.currentTeams;

export const getProduct = (productId) =>
  Store.getState().productReducer.products.find((p) => p.id === productId);

export const getUser = (userId) =>
  Store.getState().userReducer.users.find((user) => user.id === userId);

export const getCurrentUser = () => Store.getState().userReducer.user;

export const getOrganization = (orgId) =>
  Store.getState().organizationReducer.organizations.find(
    (o) => o.id === orgId
  );

export const getOrganizations = () =>
  Store.getState().organizationReducer.organizations;

export const getFirstOrganization = () =>
  Store.getState().organizationReducer.organizations[0];

export const getUserOrganization = () =>
  Store.getState().userReducer.user.organizations[0];

export const getSelectedSpace = () =>
  Store.getState().workPackageReducer.selectedSpace || {
    id: 'unassigned',
    initialSpace: false
  };

export const getWorkPackage = (id) =>
  Store.getState().workPackageReducer.all.find((wp) => wp.id === id);

export const getNonDeletedResults = () =>
  Store.getState().workPackageReducer.all.filter((wp) => !wp.deleted_at);

export const getCadenceId = () => Store.getState().cadenceReducer.cadence?.id;

export const getShowTrash = () => Store.getState().trashReducer.showTrash;

export const resolveUser = (user) => {

  const { teams } = Store.getState().teamReducer;
  const { products } = Store.getState().productReducer;
  const { organizations } = Store.getState().organizationReducer;

  user.teams = teams.filter(
    (t) =>
      user.team_ids
        ? user.team_ids.some((tid) => tid === t.id) // structure of other serialized users
        : // eslint-disable-next-line eqeqeq
          user.teams.some((ut) => ut.id == t.id) // structure of current serialized user
  );
  user.default_team = teams.find((t) => t.id === user.default_team?.id);
  user.default_product = products.find(
    (p) => p.id === user.default_product?.id
  );
  user.organizations = organizations.filter(
    (o) =>
      user.organization_ids
        ? user.organization_ids.some((oid) => oid === o.id) // structure of other serialized users
        : // eslint-disable-next-line eqeqeq
          user.organizations.some((uo) => uo.id == o.id) // structure of current serialized user
  );
};
