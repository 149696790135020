import React, { useState, useEffect } from 'react';
import linkIcon from '../../../assets/images/link_icon.png';

const ShareButton = () => {
  const [copySuccess, setCopySuccess] = useState('');

  let timeout;

  useEffect(() => () => () => clearTimeout(timeout));

  const copyToClipboard = () => {
    const type = 'text/plain';
    const blob = new Blob([window.location.href], { type });
    // eslint-disable-next-line no-undef
    const data = [new ClipboardItem({ [type]: blob })];
    navigator.clipboard.write(data);

    setCopySuccess('Copied!');

    timeout = setTimeout(() => setCopySuccess(null), 1000);
  };

  return (
    <>
      <div className="share-button" onClick={copyToClipboard}>
        {copySuccess || <img src={linkIcon} alt="" className="link-icon" />}
      </div>
    </>
  );
};

export default ShareButton;
