import QuillCursors from 'quill-cursors';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { QuillBinding } from 'y-quill';
// import { WebrtcProvider } from 'y-webrtc'
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import MagicUrl from 'quill-magic-url'

Quill.register('modules/magicUrl', MagicUrl)
Quill.register('modules/cursors', QuillCursors);

const WEBSOCKET_SERVER = process.env.COLLAB_SERVER_URL;

const DESCRIPTION_MAX_LENGTH = 1048576; // Characters; maximum length of description field

// Stop people from typing or pasting descriptions longer than a (sizable) maximum length
// Adapted from https://github.com/quilljs/quill/issues/688#issuecomment-625877142
Quill.register('modules/maxlength', function (quill, options) {
  quill.on('text-change', function (new_deltas, old_deltas, source) {
    // If this isn't a user-triggered event, we can stop here
    if (source !== 'user') return true;

    const current_length = quill.getLength();

    // If the text is over the limit, undo the last action
    if (current_length > options.value) {
      quill.history.undo();
    }
  });
});

function CollabEditor({
  entityType,
  entityId,
  username,
  onUserStatesChange,
  onFieldChange,
  isFull,
  allowEdit,
  description
}) {
  const [spinner, setSpinner] = useState(true);

  const reactQuillRef = useRef(null);
  const awarenessRef = useRef(null);
  const providerRef = useRef(null);

  const docName = `${entityType}_${entityId}`; // Example: WorkPackage_12345

  const timerRef = useRef(null);

  const disconnectQuill = () => {
    if (entityId) {
      // if there is no entityId, quill never connects to the server
      providerRef.current.shouldConnect = false;
      providerRef.current.disconnect();
      reactQuillRef.current?.editor.disable();
      timerRef.current = null;
      console.log('quill disconnected');
    }
  };

  const reconnectQuill = () => {
    if (entityId) {
      // if there is no entityId, quill never connects to the server
      const quill = reactQuillRef.current.editor;
      quill.setText('');
      quill.enable();
      providerRef.current.connect();
      runTimer();
      console.log('quill reconnected');
    }
  };

  const setUsername = (awareness, username) => {
    const id = Math.random() * 100_000; // TODO: Get this from API instead so it's unique.
    const myColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    awareness.setLocalStateField('user', {
      id,
      name: username,
      color: myColor
    });
  };

  const runTimer = () => {
    timerRef.current = window.setTimeout(disconnectQuill, 1800000);
  };

  const timerClickHandler = () => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
      timerRef.current = null;
      runTimer();
      console.log('timer reset');
      return;
    }

    if (!timerRef.current) {
      return reconnectQuill();
    }
  };

  useEffect(() => {
    if (!reactQuillRef.current) {
      console.log('useEffect: No reference to current Quill editor on page');
      return;
    }

    const quill = reactQuillRef.current.editor;

    // If there's no entityId, its means it's a new WorkPackage/Task/etc; disable networking/multiplayer for Quill
    if (!entityId) {
      quill.on('editor-change', function () {
        // OBS: description will be in Quill's Delta format
        const description = JSON.stringify(quill.getContents().ops);

        onFieldChange({ description });
      });

      return;
    }

    // If the user only has read-only access, then make Quill read-only and don't try to have it connect
    if (!allowEdit && entityId) {
      quill.disable();

      try {
        // Quill-formatted text
        const deltas = JSON.parse(description);

        quill.updateContents(deltas);
      } catch (e) {
        // Plain-text or otherwise
        quill.setText(description);
      }

      // Remove the spinner "slab" to reveal the Quill editor, because we're loading an existing entity and the spinner
      // appears by default. New entities don't bring up the spinner in the first place.
      setSpinner(false);

      // updateCharCounter( quill );
    } else {
      const ydoc = new Y.Doc();
      const ytext = ydoc.getText('quill');

      let adzUser = null;

      try {
        adzUser = JSON.parse(window.localStorage.adzUser);
      } catch {
        console.log(
          "collabEditor: authentication error - window.localStorage.adzUser doesn't exist or isn't valid JSON"
        );
      }

      // Add user ID parameters for socket authentication to the query string
      const socketOptions = {
        params: {
          email: adzUser.uid,
          client: adzUser.client,
          token: adzUser.token,
          expiry: adzUser.expiry
        }
      };

      const provider = new WebsocketProvider(
        WEBSOCKET_SERVER,
        docName,
        ydoc,
        socketOptions
      );

      providerRef.current = provider;
      const { awareness } = provider;

      // Set up awareness ref so we can access it other places.
      awarenessRef.current = awareness;
      new QuillBinding(ytext, quill, awareness);

      // Render a list of usernames next to the editor whenever new information is available from the awareness instance
      awareness.on('update', () => {
        // Map each awareness state to a dom-string
        const users = [];
        awareness.getStates().forEach((state) => {
          // State is { user: { name }, cursor: x }
          users.push(state);
        });
        onUserStatesChange(users);
      });

      // When changing window we blur. This also means your cursor will be deselected.
      window.addEventListener('blur', () => {
        quill.blur();
      });

      // Initialize our user
      setUsername(awareness, username);

      provider.on('sync', () => {
        setSpinner(false);

        // updateCharCounter( quill );
      });
      runTimer();

      return () => {
        awareness.destroy();
        provider.destroy();
        window.clearTimeout(timerRef.current);
      };
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    // If user changes name, update awareness with new info.
    const awareness = awarenessRef.current;
    if (!awareness) {
      return;
    }
    setUsername(awareness, username);
  }, [username]);

  return (
    <div
      className="editor-container"
      onClick={allowEdit ? timerClickHandler : undefined}
    >
      {!!entityId && spinner && <div className="editor-spinner" />}
      <ReactQuill
        ref={reactQuillRef}
        id={`react-quill-yay${isFull ? '-full' : ''}`}
        theme={allowEdit ? 'snow' : 'bubble'}
        style={{ display: spinner && !!entityId ? 'none' : 'flex' }}
        readOnly={!allowEdit}
        modules={{
          cursors: true,
          toolbar: [
            // adding some basic Quill content features
            [{ font: [] }],
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            ['link', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }]
          ],
          history: {
            userOnly: true, // Local undo shouldn't undo changes from remote users
            delay: 100 // Short delay so that if the character limit is hit, there's a minimal impact of undo
          },
          maxlength: {
            value: DESCRIPTION_MAX_LENGTH
          },
          magicUrl: true
        }}
      />
    </div>
  );
}

export default CollabEditor;
