import React from 'react';
import { connect } from 'react-redux';

const ChildrenBar = ({ childrenProgress }) => {
  const {
    children_count,
    accepted_children_count,
    total_children_points,
    total_accepted_points
  } = childrenProgress || {
    children_count: 0,
    total_children_points: 0,
    accepted_children_count: 0,
    total_accepted_points: 0
  };

  const countPercentage =
    (accepted_children_count / (children_count > 0 ? children_count : 1.0)) *
    100;

  const pointsPercentage =
    (total_accepted_points /
      (total_children_points > 0 ? total_children_points : 1.0)) *
    100;

  return (
    <div className="card-details">
      <div className="children-bar-container">
        Done # {childrenProgress?.accepted_children_count || 0} /{' '}
        {childrenProgress?.children_count || 0}
        <div className="bar-line-container">
          <div className="bar-line" style={{ width: `${countPercentage}%` }} />
          <span className="bar-line-percentage">
            {`${Math.round(countPercentage)}%`}
          </span>
        </div>
      </div>

      <div className="children-bar-container">
        Points {childrenProgress?.total_accepted_points || 0} /{' '}
        {childrenProgress?.total_children_points || 0}
        <div className="bar-line-container">
          <div className="bar-line" style={{ width: `${pointsPercentage}%` }} />
          <span className="bar-line-percentage">
            {`${Math.round(pointsPercentage)}%`}
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { metrics } = state.workPackageReducer;
  const childrenProgress = metrics.childrenProgress[ownProps.resultId];

  return {
    childrenProgress
  };
};

export default connect(mapStateToProps)(ChildrenBar);
