import React from 'react';
import { Link } from 'react-router-dom';
import defaults from '../../constants/defaults';

const Listwall = ({ orgLevels }) => (
  <div id="menu-list-wall" className="dropdown btn-component-div">
    <button
      type="button"
      id="detail-dropdown"
      className="btn btn-default menu-header"
      aria-haspopup="true"
    >
      List Wall
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdown">
      {orgLevels.map(orgLevel => (
        <li key={`${orgLevel.level}-${orgLevel.name}`}>
          <Link to={`/planwall/r/${orgLevel.level}`} className="btn btn-link">
            {orgLevel.name}
          </Link>
        </li>
      ))}
      <li>
        <Link to="/planwall/r/5" className="btn btn-link">
          Organization
        </Link>
      </li>
    </ul>
  </div>
);

export default Listwall;
