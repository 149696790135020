import React, { useRef, useState, useEffect } from 'react';
import { AreaStack } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { scaleTime, scaleLinear, scaleOrdinal } from '@vx/scale';
import { timeParse, timeFormat } from 'd3-time-format';
import { curveMonotoneX } from '@vx/curve';
import { LegendOrdinal, LegendItem, LegendLabel } from '@vx/legend';
import { GridRows, GridColumns } from '@vx/grid';

const parseDate = timeParse('%Y %m %d');

const x = (d) => parseDate(d.date);
const y0 = (d) => d[0];
const y1 = (d) => d[1];

const margin = {
  top: 5,
  bottom: 50,
  left: 25,
  right: 20
};

const ordinalColorScale = (level) => {
  if (level === 1) {
    return scaleOrdinal({
      domain: ['ready', 'in progress', 'completed'],
      range: ['#f2cc27', '#6f3f64', '#366994']
    });
  }
  return scaleOrdinal({
    domain: [
      'not ready',
      'getting ready',
      'ready',
      'in progress',
      'completed',
      'accepted',
      'shippable',
      'live'
    ],
    range: [
      '#be1e2d',
      '#df7f26',
      '#f2cc27',
      '#6f3f64',
      '#366994',
      '#009444',
      '#106e38',
      '#939393'
    ]
  });
};

const colors = {
  'not ready': '#be1e2d',
  'getting ready': '#df7f26',
  ready: '#f2cc27',
  'in progress': '#6f3f64',
  completed: '#366994',
  accepted: '#009444',
  shippable: '#106e38',
  live: '#939393'
};

const getSumOfPoints = (data) =>
  data.reduce((total, d) => {
    const sum = Object.values(d)
      .filter((v) => !!Number(v))
      .reduce((all, v) => all + v, 0);
    if (sum > total) {
      return sum;
    }
    return total;
  }, 0);

const numTicksForWidth = (width) => Math.round(width / 40);

export default ({
  width,
  height,
  workPackage = {},
  graphData = [],
  teamTab
}) => {
  if (graphData.length === 0) return null;

  const keys = Object.keys(graphData[0]).filter((k) => k !== 'date');
  // bounds
  const yMax = height - margin.top - margin.bottom;
  const xMax = width - margin.left - margin.right;

  const numTicks = numTicksForWidth(xMax);
  const interval =
    graphData.length > numTicks ? Math.round(graphData.length / numTicks) : 1;
  const ticks = graphData.reduce((all, data, index) => {
    if (index % interval === 0) {
      all.push(new Date(data.date));
    }
    return all;
  }, []);

  const legendRef = useRef();
  const [legendWidth, setLegendWidth] = useState();

  const getLegendWidth = () => {
    const newWidth = legendRef.current.clientWidth;
    setLegendWidth(newWidth);
  };

  useEffect(() => {
    getLegendWidth();
  }, []);

  // scales
  const xScale = scaleTime({
    range: [0, xMax],
    domain: [
      new Date(graphData[0].date),
      new Date(graphData[graphData.length - 1].date)
    ]
  });

  const sumOfPoints = getSumOfPoints(graphData);

  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [0, sumOfPoints * 1.2]
  });

  let legendBottom;
  if (teamTab && window.innerWidth >= 1730) legendBottom = '40px';
  else if (teamTab) legendBottom = '50px';
  else legendBottom = '25px';

  return (
    <>
      <div className="cf-header">
        <h5>
          <strong>
            Cumulative Flow{' '}
            {workPackage.level > 1 ? '(story points)' : '(task hours)'}
          </strong>
        </h5>
        <LegendOrdinal
          scale={ordinalColorScale(workPackage.level)}
          labelFormat={(label) => `${label}`}
        >
          {(labels) => (
            <div className="cf-labels-container" ref={legendRef}>
              {labels.map((label, i) => {
                const size = 10;
                return (
                  <LegendItem key={`legend-quantile-${i}`} margin="0 2px">
                    <div
                      className="cf-legend-item"
                      style={{
                        backgroundColor: colors[label.text],
                        minWidth: 22
                      }}
                    >
                      {workPackage.level > 1 && legendWidth && legendWidth < 453
                        ? label.text
                            .match(/(\b\S)?/g)
                            .join('')
                            .match(/(^\S|\S$)?/g)
                            .join('')
                            .toUpperCase() // use initials for label to avoid wrapping
                        : label.text}
                    </div>
                  </LegendItem>
                );
              })}
            </div>
          )}
        </LegendOrdinal>
      </div>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill="#FFFFFF" rx={0} />
        <Group
          transform={
            workPackage.level > 1 ? 'translate(16,25)' : 'translate(25,5)'
          }
        >
          <AxisBottom
            scale={xScale}
            top={yMax}
            stroke="#1b1a1e"
            tickTextFill="#1b1a1e"
            tickFormat={timeFormat('%b %d')}
            strokeWidth={2}
            tickValues={ticks}
          />
          <AxisLeft
            scale={yScale}
            top={0}
            left={0}
            stroke="#1b1a1e"
            strokeWidth={2}
            tickTextFill="#1b1a1e"
          />
          <GridRows
            scale={yScale}
            width={xMax}
            numTicks={(sumOfPoints * 1.2) / 5}
          />
          <GridColumns scale={xScale} height={yMax} tickValues={ticks} />
          <AreaStack
            top={margin.top}
            left={margin.left}
            keys={keys.reverse()}
            data={graphData}
            x={(d) => xScale(x(d.data))}
            y0={(d) => yScale(y0(d))}
            y1={(d) => yScale(y1(d))}
            curve={curveMonotoneX}
          >
            {(area) => {
              const { stacks, path } = area;
              return stacks.map((stack) => (
                <path
                  key={`stack-${stack.key}`}
                  d={path(stack)}
                  stroke="transparent"
                  fill={colors[stack.key]}
                  fillOpacity={0.75}
                />
              ));
            }}
          </AreaStack>
        </Group>
      </svg>
    </>
  );
};
