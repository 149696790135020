import { createSelector } from 'reselect';

const getOrganizationId = (state) =>
  state.workPackageReducer.selectedSpace.organization_id;

const getOrganizations = (state) => state.organizationReducer.organizations;

export const getDefaultOrgLevels = createSelector(
  [getOrganizationId, getOrganizations],
  (orgId, organizations) => {
    const currentOrganization = organizations.find((org) => org.id === orgId);
    return currentOrganization.organization_levels.filter(
      (level) => level.default_level
    );
  }
);
