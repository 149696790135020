import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Default from '../../constants/defaults';
import StoryList from '../PlanWall/storyList';
import StoryListHeader from './storyListHeader';
import TableList from '../PlanWall/tableList';

const DetailPlanTab = ({
  allWorkPackages,
  targetWorkPackages,
  target,
  add,
  remove,
  dropDown,
  setDropdown,
  preservedState,
  preserveComponentState
}) => {
  const visibleFields = Default.tableFields.target.fields;

  let workPackagesInTargetList;
  if (!target.id) {
    workPackagesInTargetList = allWorkPackages.filter((option) =>
      target.workPackageIds.includes(option.id)
    );
  } else workPackagesInTargetList = targetWorkPackages;

  // if the target is persisted, targetWorkPackages is the current array of work package IDs in the target
  // if not, target.workPackageIds is the array of work package IDs in the target
  // REFACTOR: make these consistent
  const workPackageIdsInTarget = target.id
    ? targetWorkPackages.map((wp) => wp.id)
    : [...target.workPackageIds];

  return (
    <div id="target-available-results" className="middle">
      <div className="col-sm-8 all-work-packages-table">
        <TableList
          level={undefined}
          preservedState={preservedState}
          preserveComponentState={preserveComponentState}
          noPreserve
          source="TargetDetail"
          tableFields={visibleFields}
          highlightedIds={workPackageIdsInTarget}
        />
      </div>
      <div className="col-sm-4">
        <div className="panel">
          <div className="panel-heading">
            <div className="panel-title">
              Drag items on the left here to add to this Target
            </div>
          </div>
          <div className="panel-body empty-space target-story-list">
            <StoryList
              results={workPackagesInTargetList}
              source="SideTargetDetail"
              fields={visibleFields}
              parentId={target.id}
              level={4}
              target={target}
              add={add}
              delete={remove}
              dropDown={dropDown}
              setDropdown={setDropdown}
              alwaysExpanded
              mini
            />
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="table">
  //     <div className="col-sm-8 all-work-packages-table">
  //       <StoryListHeader
  //         results={all}
  //         targets={targets}
  //         visibleFields={visibleFields}
  //         defaultOptions={defaultOptions}
  //         source="TargetDetail"
  //         level={4}
  //         parentId={target.id}
  //         add={remove}
  //         dropDown={dropDown}
  //         setDropdown={setDropdown}
  //         expand
  //         noPreserve
  //       />
  //     </div>

  // <div className="col-sm-4">
  //   <div className="panel">
  //     <div className="panel-heading">
  //       <div className="panel-title">
  //         Drag items on the left here to add to this Target
  //       </div>
  //     </div>
  //     <div className="panel-body empty-space target-story-list">
  //       <StoryList
  //         results={storylistOptions}
  //         source="SideTargetDetail"
  //         fields={visibleFields}
  //         parentId={target.id}
  //         level={4}
  //         target={target}
  //         add={add}
  //         delete={remove}
  //         dropDown={dropDown}
  //         setDropdown={setDropdown}
  //         alwaysExpanded
  //         mini
  //       />
  //     </div>
  //   </div>
  // </div>
  //   </div>
  // );
};

DetailPlanTab.propTypes = {
  allWorkPackages: PropTypes.array,
  targetWorkPackages: PropTypes.array,
  target: PropTypes.object,
  dropDown: PropTypes.object,
  addWorkPackageToTarget: PropTypes.func,
  removeWorkPackageFromTarget: PropTypes.func,
  setDropdown: PropTypes.func
};

const mapStateToProps = (state) => {
  const { all: allWorkPackages } = state.workPackageReducer;

  return {
    defaultOptions: state.defaultOptionsReducer.defaultOptions,
    targets: state.targetReducer.targets,
    allWorkPackages
  };
};

export default connect(mapStateToProps, null)(DetailPlanTab);
