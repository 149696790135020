export default (context, text, maxWidth) => {
  const words = text.split(' ');
  let line = '';
  let lineCount = 0;
  let i;
  let test;
  let metrics;

  for (i = 0; i < words.length; i++) {
    test = words[i];
    metrics = context.measureText(test);
    while (metrics.width > maxWidth) {
      // Determine how much of the word will fit
      test = test.substring(0, test.length - 1);
      metrics = context.measureText(test);
    }
    if (words[i] !== test) {
      words.splice(i + 1, 0, words[i].substr(test.length));
      words[i] = test;
    }

    test = `${line + words[i]} `;
    metrics = context.measureText(test);

    if (metrics.width > maxWidth && i > 0) {
      // context.fillText(line, x, y);
      line = `${words[i]} `;
      lineCount++;
    } else {
      line = test;
    }
  }

  return lineCount;
};
