import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { camelCase, isEmpty } from 'lodash';
import {
  filterOwnersByAuthority,
  filterOwnersByProductTeams
} from '../../selectors/userSelector';
import SelectField from './CustomSelect/SelectField';

const OwnerSelect = ({ label, users, allowEdit, selectedOwner, onUpdate }) => {
  const defaultOption = { label: '', value: null };

  const handleChange = option => {
    const name = camelCase(label);

    if (name === 'owner') {
      onUpdate(option.value, 'owner_id');
    } else if (name === 'productOwner' || name === 'productManager') {
      onUpdate(option.value, 'product_owner_id');
    } else if (name === 'chiefPo') {
      onUpdate(option.value, 'chief_product_owner_id');
    }
  };

  const options = !isEmpty(users)
    ? users.map(user => ({
        label: user.nickname || user.name,
        value: user.id
      }))
    : [];

  options.unshift(defaultOption);

  const selectedValue =
    (selectedOwner &&
      options.find(option => option.value === selectedOwner.id)) ||
    defaultOption;

  return (
    <div className="row detail-select">
      <div className="col-sm-3">
        <label htmlFor="owner">{label}</label>
      </div>
      <div className="col-sm-9">
        <SelectField
          selectedOption={selectedValue || defaultOption}
          handleOptionChange={handleChange}
          disabled={!allowEdit || users.length === 0}
          options={options}
          placeholder=""
          sortItems={true}
        />
      </div>
    </div>
  );
};

OwnerSelect.propTypes = {
  result: PropTypes.object,
  product: PropTypes.object,
  label: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  selectedOwner: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  let users = [];
  if (ownProps.result) {
    users = filterOwnersByAuthority(state, ownProps.result);
  } else if (ownProps.product) {
    users = filterOwnersByProductTeams(state, ownProps.product);
  }
  return { users };
};

export default connect(mapStateToProps)(OwnerSelect);
