import React from 'react';
import PropTypes from 'prop-types';
import { workPackageStateCharacter } from '../../utils/string';
import { WorkPackageStatuses } from '../../constants/storyStatusTypes';

const StateMenu = (props) => {
  const { classes, result, options, stateToOptionKey, changeStatus } = props;

  return (
    <ul className={`dropdown dropdown-menu result-dropdown-menu ${classes}`}>
      {Object.keys(options)
        .filter((option) => option !== stateToOptionKey)
        .map((option) => {
          const optionName = options[option];
          const optionClass = option.replace(/_/g, '-').toLowerCase();

          return (
            <li key={optionName} className="options">
              <div
                onClick={
                  () =>
                    changeStatus(
                      result,
                      WorkPackageStatuses[option]
                    ) /* use the standard WorkPackageStatuses for sake of API */
                }
              >
                <span className={`icon ${optionClass}`}>
                  {workPackageStateCharacter(result, optionName)}
                </span>
                <span className="btn btn-link menu-text">{optionName}</span>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

StateMenu.propTypes = {
  result: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  classes: PropTypes.string
};

export default StateMenu;
