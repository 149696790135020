import React from 'react';
import PropTypes from 'prop-types';
import { fromCamelCaseToNormal } from '../../utils/string';

const payload = organizationLevelId => ({ organizationLevelId });

const TypeMenu = props => {
  const { classes, handleSelect, options } = props;

  return (
    <ul className={`dropdown dropdown-menu result-dropdown-menu ${classes}`}>
      {Object.keys(options).map(key => (
        <li key={key}>
          <a onClick={() => handleSelect(payload(key))}>
            {fromCamelCaseToNormal(options[key])}
          </a>
        </li>
      ))}
    </ul>
  );
};

TypeMenu.propTypes = {
  field: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  classes: PropTypes.string
};

TypeMenu.defaultProps = {
  classes: ''
};

export default TypeMenu;
