import { isEmpty } from 'lodash';
import Store from '../store/store';
import { getCurrentTeams, getCurrentUser } from './reduxHelpers';
import { resultCardId } from './string';
import { updatableTeams } from './authorization';
import { getTeamOptions } from './teamHelpers';
import { updateWorkPackageLevel } from '../actions/workPackageActions';
import { openModal } from '../actions/modalActions';
import modalTypes from '../constants/modalTypes';
import { isOwnerAuthorizedForWorkPackage } from './workPackageHelpers';
import { filterOwnersByTeam } from '../selectors/userSelector';

export const defaultTeamId = (result) => {
  const currentTeams = getCurrentTeams();
  if (
    !result?.id &&
    currentTeams.length > 0 &&
    currentTeams[0].id !== 'unassigned'
  )
    return currentTeams[0].id;
  return result?.team_id;
};

export const defaultParentId = (props) => {
  const { location, planwallState, result } = props;

  if (result.parent_id) return result.parent_id;
  if (result.id) return null;

  if (location.pathname.includes('planwall')) {
    const {
      state: { fil: planwallFilters }
    } = planwallState;
    const { parentFilter } = JSON.parse(window.atob(planwallFilters));

    if (!parentFilter || parentFilter.length < 1 || parentFilter.length > 1)
      return null;

    if (parentFilter.length === 1) return parentFilter[0];
  }

  return null;
};

export const getModalProductTeam = (result, selectedSpace, products, teams) => {
  const user = getCurrentUser();
  let product;
  let team;

  if (selectedSpace.type === 'product') {
    product = products.find((p) => p.id === Number(result.product_id)) || {
      id: null
    };
    team =
      teams.find((t) => t.id === result.team_id) ||
      (result.team_id &&
      user.default_team &&
      selectedSpace.teams.find((t) => t.id === user.default_team.id)
        ? user.default_team
        : { id: null });
  }

  if (selectedSpace.type === 'team') {
    team = teams.find((t) => t.id === result.team_id) || {
      id: null
    };
    product = products.find((p) => p.id === Number(result.product_id)) || {
      id: null
    };
  }

  return { product, team };
};

export const childOrganizationLevel = (result, organizationLevels) => {
  // for new cards, this will always be -1
  const organizationLevelId =
    result.result_type && result.result_type.organization_level_id
      ? result.result_type.organization_level_id
      : -1;

  // for new cards, this will always return an empty object
  // because the organizationLevelId will be -1 for new cards
  const organizationLevel =
    organizationLevels.find(
      (orgLevel) => orgLevel.id === organizationLevelId
    ) || {};

  let storyOrganizationLevel;

  if (organizationLevel.level && organizationLevel.level > 1) {
    // this runs for already created cards
    storyOrganizationLevel = organizationLevels.find(
      (orgLevel) =>
        orgLevel.level === organizationLevel.level - 1 &&
        orgLevel.default_level === true
    );
  } else if (organizationLevelId === -1) {
    // this runs for new cards
    const childLevel = result.level - 1;
    storyOrganizationLevel = organizationLevels.find(
      (orgLevel) =>
        orgLevel.level === childLevel && orgLevel.default_level === true
    );
  }

  return storyOrganizationLevel;
};

export const generateParentOptions = (parents, parent) => {
  const sortedParents = parents.sort((a, b) => {
    if (a.level === b.level) {
      return a.type_id < b.type_id ? -1 : 1;
    }
    return a.level < b.level ? -1 : 1;
  });
  const parentOptions = sortedParents.map((option) => ({
    label: `${resultCardId(option)} -${option.short_name || option.name}`,
    value: option.id
  }));

  const selectedParent = parentOptions.find(
    (option) => option.value === parent.id
  );

  return { selectedParent, parentOptions };
};

export const generateTeamOptions = (teams, team) => {
  const updateable = updatableTeams(teams) || [];
  const teamOptions =
    // ensure team is included in the teamOptions if there are no updateable teams; this happens for observers
    updateable.length === 0 && team && team.id
      ? getTeamOptions([team])
      : getTeamOptions(updateable);

  const selectedTeam = teamOptions.find((option) => option.value === team.id);
  return { teamOptions, selectedTeam };
};

export const determineModalType = (item) => {
  switch (item.level) {
    case 1:
      return modalTypes.resultDetail;
    case 2:
      return modalTypes.featureDetail;
    case 3:
      return modalTypes.capabilityDetail;
    case 4:
      return modalTypes.epicDetail;
    default:
      return undefined;
  }
};

const defaultOwnerId = (props, workPackageAuthority) => {
  const {
    location: { pathname },
    planwallState,
    kanbanWallState: { selectedUsers },
    user
  } = props;
  let ownerId;
  if (pathname.includes('planwall')) {
    const { ownerFilter } = JSON.parse(window.atob(planwallState.state.fil));
    if (!ownerFilter || isEmpty(ownerFilter) || ownerFilter.length > 1)
      ownerId = user.id;
    if (ownerFilter?.length === 1) [ownerId] = ownerFilter;
  }

  if (pathname.includes('cardwall')) {
    if (isEmpty(selectedUsers) || selectedUsers.length > 1) ownerId = user.id;
    if (selectedUsers?.length === 1) [ownerId] = selectedUsers;
  }

  if (
    (!ownerId || ownerId === user.id) &&
    isOwnerAuthorizedForWorkPackage(user.id, workPackageAuthority)
  )
    return user.id;
  if (
    !ownerId ||
    ownerId === 'unassigned' ||
    !isOwnerAuthorizedForWorkPackage(ownerId, workPackageAuthority)
  )
    return null;
  return ownerId;
};

const generateDefaultTarget = (props) => {
  const {
    location,
    planwallState,
    kanbanWallState: { selectedTargets },
    targets
  } = props;
  let targetId;
  if (!planwallState.state.fil) return null;
  if (location.pathname.includes('planwall')) {
    const { targetFilter } = JSON.parse(window.atob(planwallState.state.fil));
    if (!targetFilter || isEmpty(targetFilter) || targetFilter.length > 1)
      targetId = null;
    if (targetFilter?.length === 1) [targetId] = targetFilter;
  }

  if (location.pathname.includes('cardwall')) {
    if (isEmpty(selectedTargets) || selectedTargets.length > 1) targetId = null;
    if (selectedTargets?.length === 1) [targetId] = selectedTargets;
  }

  if (targetId === 'unassigned') return null;
  if (!targetId) return null;

  const targetSelected = targets.find((target) => target.id === targetId);
  return targetSelected.status === 'Accepted' ? null : targetId;
};

export const defaultNewResultAttributes = (props, childAttr) => {
  const {
    cadenceSelected,
    location,
    organizationLevelId,
    organizationLevels,
    selectedSpace,
    planwallState,
    user
  } = props;
  const organizationLevel = organizationLevels.find(
    (orgLevel) => orgLevel.id === organizationLevelId
  );
  const { pathname } = location;
  const addToCurrentSprint =
    pathname.indexOf('cardwall') !== -1 && pathname.indexOf('kanban') === -1;

  const pws = planwallState.state;

  const visibleFieldNames = pws.col ? JSON.parse(window.atob(pws.col)) : [];
  const filters = pws.fil ? window.atob(pws.fil) : '{}';

  const sprintFilter =
    (visibleFieldNames.sprint && JSON.parse(filters).sprintFilter) || [];

  const parentId = defaultParentId(props);

  let cadenceId = '';
  if (addToCurrentSprint && cadenceSelected) {
    cadenceId = cadenceSelected.id;
  } else {
    const sprintFilterWithoutUnassigned = sprintFilter.filter(
      (s) => s !== 'unassigned'
    );
    cadenceId =
      sprintFilterWithoutUnassigned.length === 1
        ? sprintFilterWithoutUnassigned[0]
        : cadenceId;
  }
  let children = [];
  let criteria = [];
  let attachmentList = [];
  let hasChanged = false;

  let teamId;
  let ownerId;
  let defaultProduct;

  if (selectedSpace.type === 'product') {
    teamId = defaultTeamId(props.result);
    defaultProduct = selectedSpace;
    // only set default owner if the user is a worker on the default team;
    // if no default team, set default owner if the user is authorized to work on the default product
    if (teamId) ownerId = defaultOwnerId(props, { type: 'team', id: teamId });
    else
      ownerId = defaultOwnerId(props, {
        type: 'product',
        id: defaultProduct.id
      });
  } else if (selectedSpace.type === 'team') {
    teamId = selectedSpace.id;
    ownerId = defaultOwnerId(props, { type: 'team', id: teamId });
    // find the owner & default product
    if (user.id === ownerId && user.default_product?.id)
      defaultProduct = user.default_product;
    else {
      const teamOwners = filterOwnersByTeam(Store.getState(), selectedSpace);
      const owner = teamOwners.find((o) => o.id === ownerId);
      if (owner && owner.default_product?.id)
        defaultProduct = owner.default_product;
      else [defaultProduct] = selectedSpace.products;
    }
    // if the default product is not one of the team's products, choose the team's first product
    if (!selectedSpace.products.find((product) => product === defaultProduct))
      [defaultProduct] = selectedSpace.products;
  }
  const productId = defaultProduct?.id;

  const resultType =
    props.result && props.result.result_type ? props.result.result_type : {};
  const typeAttributes = resultType.type_attributes || {};
  const val = typeAttributes.value || null;
  const risk = typeAttributes.risk || null;
  const size = typeAttributes.size || null;

  let result;

  if (props.result.id) {
    result = {
      ...props.result
    };
  } else {
    result = {
      short_name: '',
      description: '',
      type: 'Result',
      level: organizationLevel ? organizationLevel.level : 1,
      cadence_id: cadenceId,
      product_id: productId,
      team_id: teamId,
      result_type: {
        type_attributes: {
          risk,
          size,
          value: val
        }
      },
      target_id: generateDefaultTarget(props),
      parent_id: parentId,
      owner_id: ownerId
    };
  }

  if (sessionStorage.result) {
    result = JSON.parse(sessionStorage.getItem('result'));
    sessionStorage.removeItem('result');
    hasChanged = true;
  }
  if (sessionStorage[childAttr]) {
    children = JSON.parse(sessionStorage.getItem(childAttr));
    sessionStorage.removeItem(childAttr);
  }
  if (sessionStorage.criteria) {
    criteria = JSON.parse(sessionStorage.getItem('criteria'));
    sessionStorage.removeItem('criteria');
  }
  if (sessionStorage.attachmentList) {
    attachmentList = JSON.parse(sessionStorage.getItem('attachmentList'));
    sessionStorage.removeItem('attachmentList');
  }

  const defaults = {
    result,
    criteria,
    attachmentList,
    hasChanged
  };

  defaults[childAttr] = children;

  return defaults;
};

export const handleOrgLevelChange = async (orgLevel, result, closeForm) => {
  // make sure store is updated before anything else
  await Store.dispatch(
    updateWorkPackageLevel(result, orgLevel.level, orgLevel.id)
  );
  if (result.level !== orgLevel.level) {
    closeForm();
    const newModalType = determineModalType(orgLevel);
    Store.dispatch(
      openModal(newModalType, {
        id: result.id
      })
    );
  }
};
