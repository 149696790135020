import React from 'react';

const Restore = () => (
  <div className="btn-component-div">
    <button type="button" className="btn btn-link">
      <i className="glyphicon glyphicon-refresh" alt="restore" />
    </button>
  </div>
);

export default Restore;
