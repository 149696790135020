import { offset } from '@floating-ui/dom';

export default [
  {
    id: 'configure-products',
    text: '<p>To add Product(s) your teams will work on, use this button.</p>',
    selector: 'div.org-add-product button',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    }
  },
  {
    id: 'flip-teams',
    text: '<p>To flip back to the Teams view, click this button again. </p>',
    selector: 'div.flip-products-teams button',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    }
  }
];
