import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { resultCardId } from '../../utils/string';
import CustomSelect from './CustomSelect/SelectField';

/* eslint-disable jsx-a11y/label-has-associated-control */

class ResultsDropdown extends Component {
  handleChange = (option) => {
    const field = { work_package_id: option.value };
    this.props.onFieldChange(field);
  };

  resultName = (result) => {
    const { name } = result;
    return name.length > 30 ? `${name.substring(0, 30)}...` : name;
  };

  render() {
    const { allowEdit, workPackagesReducer, selectedState, task } = this.props;

    const disabled = !allowEdit;
    const workPackages = workPackagesReducer || {};

    const filteredPackageOptions = workPackages.stories.reduce((acc, wp) => {
      if (wp.state === selectedState) {
        acc.push({
          label: `${resultCardId(wp)} ${this.resultName(wp)}`,
          value: wp.id
        });
      }
      return acc;
    }, []);

    filteredPackageOptions.unshift(
      { label: '', value: null },
      { label: selectedState, value: null, disabled: true }
    );

    const selectedOption = filteredPackageOptions.find(
      (option) => option.value === task.work_package_id
    );
    // no clear purpose - commented out for now
    // const adHoc =
    //   workPackages.activities && workPackages.activities.length > 0
    //     ? workPackages.activities[0]
    //     : {};

    return (
      <div>
        <label>Result</label>
        <CustomSelect
          handleOptionChange={this.handleChange}
          options={filteredPackageOptions}
          selectedOption={selectedOption}
          disabled={disabled}
        />
      </div>
    );
  }
}

ResultsDropdown.propTypes = {
  onFieldChange: PropTypes.func,
  allowEdit: PropTypes.bool,
  workPackageError: PropTypes.bool
};

export default ResultsDropdown;
