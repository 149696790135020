import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ImPlus } from 'react-icons/im';
import { openModal } from '../../actions/modalActions';
import {
  deleteUser,
  deleteUserPermanently,
  restoreUser,
  userLimitModal
} from '../../actions/organizationActions';
import modalTypes from '../../constants/modalTypes';
import RoleToggler from '../Common/roleToggler';
import MinusDelete from '../Buttons/minusDelete';
import Restore from '../Buttons/restore';
import Delete from '../Buttons/delete';
import { allows } from '../../utils/authorization';
import AddUser from '../Common/addUser';

const OrganizationMembersTab = ({
  organization,
  users,
  openModal,
  changeRole,
  isSending,
  deleteUser,
  deleteUserPermanently,
  restoreUser,
  showTrash,
  userLimitModal
}) => {
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowDeleteOrRestore, setAllowDeleteOrRestore] = useState(false);
  const [allowAddMembers, setAllowAddMembers] = useState(false);
  const [allowUpdateRoles, setAllowUpdateRoles] = useState(false);

  useEffect(() => {
    setAllowEdit(allows('edit', organization, 'organization'));
    setAllowDeleteOrRestore(allows('destroy', organization, 'organization'));
    setAllowAddMembers(allows('create', organization, 'organization'));
    setAllowUpdateRoles(allows('update', organization, 'organization'));
  }, [organization]);

  const orgMembers =
    organization.id !== -1 &&
    users.filter((usr) => organization.member_ids.includes(usr.id));

  // if (showTrash && orgMembers) {
  //   orgMembers = orgMembers.concat(organization.deleted_members);
  // }

  const handleDelete = (user) => {
    const message = `Are you sure you want to delete ${user.name}? This user will be permanently
       unassigned from owned tasks and work items, as well as products and teams.`;

    const actions = {
      Confirm: {
        name: 'Confirm',
        function: deleteUser.bind(null, organization.id, user.id)
      }
    };
    openModal(modalTypes.message, {
      message,
      actions,
      closeButtonTitle: 'CANCEL',
      id: 'message'
    });
  };

  const handleDeletePermanently = (user) => {
    const message = `Are you sure you want to permanently delete ${user.name}?`;
    const actions = {
      Confirm: {
        name: 'Confirm',
        function: deleteUserPermanently.bind(null, organization.id, user.id)
      }
    };
    openModal(modalTypes.message, {
      message,
      actions,
      closeButtonTitle: 'CANCEL',
      id: 'message'
    });
  };

  const handleRestore = (user) => {
    const { member_limit, active_member_count } = organization;
    if (member_limit && active_member_count >= member_limit) {
      userLimitModal(member_limit);
      return;
    }
    restoreUser(organization.id, user.id);
  };

  const renderDeleteOrRestoreButtons = (
    authorization,
    memberDeleted,
    user,
    isUserAnAdmin
  ) => {
    if (!authorization) return <div className="delete-container" />;
    return memberDeleted ? (
      <div className="delete-container">
        <div onClick={() => handleRestore(user)}>
          <Restore />
        </div>
        <Delete handleClick={() => handleDeletePermanently(user)} />
      </div>
    ) : (
      <div className="delete-container">
        {!isUserAnAdmin && (
          <MinusDelete handleClick={() => handleDelete(user)} />
        )}
      </div>
    );
  };

  const handleAdminAssignment = (user, userIsAnAdmin) => {
    const actions = {
      Confirm: {
        name: 'Confirm',
        function: changeRole.bind(null, user.id, 'Administrator')
      }
    };
    let message;

    if (userIsAnAdmin) {
      message = `Are you sure you want to remove the Administrator role from ${user.name}?`;
    } else {
      message = `Are you sure you want to make ${user.name} an Administrator?`;
    }

    openModal(modalTypes.message, {
      message,
      actions,
      closeButtonTitle: 'CANCEL',
      id: 'message'
    });
  };

  const addUser = () => {
    const { member_limit, active_member_count } = organization;
    if (member_limit && active_member_count >= member_limit) {
      userLimitModal(member_limit);
      return;
    }

    openModal(
      modalTypes.newUser,
      {
        parentId: organization.id,
        isNew: true
      },
      'user'
    );
  };

  return (
    <div
      role="tabpanel"
      className="tab-pane active organization-members-tab"
      id="details"
    >
      <div className="user-table-wrapper">
        <div className="flexbox">
          <div className="users-table">
            <table cellSpacing="0">
              <thead>
                <tr>
                  <th>
                    <span className="table-header-text">Display Name</span>
                  </th>
                  <th>
                    <span className="table-header-text">Full Name</span>
                  </th>
                  <th>
                    <span className="table-header-text">Email</span>
                  </th>
                  <th>
                    <span className="table-header-text">Permissions</span>
                  </th>
                  {allowAddMembers && (
                    <th className="header-plus">
                      <button
                        id="add-member-details"
                        className="btn btn-link white-plus table-header-text "
                        onClick={addUser}
                      >
                        <ImPlus size="2em" color="#898BC4" />
                      </button>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {orgMembers &&
                  orgMembers
                    .sort((a, b) => {
                      // Sort by deleted first, then by name. Deleted records go to the bottom
                      // JavaScript could have a logical XOR to make our life easier, but nooooo, that would make too much sense.
                      if (a.deleted_at && !b.deleted_at) return 1;
                      if (!a.deleted_at && b.deleted_at) return -1;
                      if (
                        (!a.deleted_at && !b.deleted_at) ||
                        (a.deleted_at && b.deleted_at)
                      )
                        return a.name.localeCompare(b.name);
                      a.name.localeCompare(b.name);
                    })
                    .filter((user) => {
                      if (showTrash && user.deleted_at) return true;
                      if (user.deleted_at) return false;
                      return true;
                    })
                    .map((user) => {
                      const memberDeleted = !!user.deleted_at;
                      const deletedClassName = memberDeleted ? 'deleted' : '';
                      const { organization_roles = [] } = user;
                      const isUserAnAdmin = organization_roles.find(
                        (role) =>
                          role.organization_id === organization.id &&
                          role.type === 'Administrator'
                      );
                      return (
                        <tr
                          key={user.email}
                          className={`org-member-row ${deletedClassName}`}
                        >
                          <td className="display-name-td">
                            <div className="dropdown btn-component-div">
                              <button
                                id="detail-dropdown"
                                type="button"
                                className="btn btn-link"
                                onClick={() =>
                                  openModal(
                                    modalTypes.editUser,
                                    { editExistingUser: true, user },
                                    'user'
                                  )
                                }
                                disabled={!allowEdit || memberDeleted}
                              >
                                <span
                                  className="glyphicon glyphicon-cog"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                            <div className="org-display-name">
                              {user.nickname}
                            </div>
                          </td>
                          <td>
                            <div>{user.name}</div>
                          </td>
                          <td>
                            <div className="user-name">
                              <span>
                                <a href={`mailto:${user.email}`}>
                                  {user.email}
                                </a>
                              </span>
                            </div>
                          </td>
                          <td className="role-column">
                            {!memberDeleted && (
                              <RoleToggler
                                user={user}
                                roleLevel="organization"
                                roleLevelId={organization.id}
                                disabled={!allowUpdateRoles}
                                handleRoleChange={changeRole}
                                handleAdminAssignment={handleAdminAssignment}
                                isSending={isSending}
                              />
                            )}
                          </td>
                          <td>
                            {renderDeleteOrRestoreButtons(
                              allowDeleteOrRestore,
                              memberDeleted,
                              user,
                              isUserAnAdmin
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          {allowAddMembers && <AddUser organization={organization} />}
        </div>
      </div>
    </div>
  );
};

OrganizationMembersTab.propTypes = {};

const mapStateToProps = (state) => ({
  showTrash: state.trashReducer.showTrash,
  isSending: state.teamReducer.isSending
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal,
      deleteUser,
      deleteUserPermanently,
      restoreUser,
      userLimitModal
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationMembersTab);
