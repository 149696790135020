import React, { useState, useRef, useEffect } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import newProduct from '../../../assets/images/new-product-icon.png';
import newTeam from '../../../assets/images/new-team-icon.png';
import searchIcon from '../../../assets/images/search-icon.png';

// Data imports
import {
  setCurrentProducts,
  setCurrentTeams
} from '../../actions/defaultOptionsActions';
import { setSelectedSpace } from '../../actions/workPackageActions';
import { truncate } from '../../utils/string';

function SpacesMenu({
  singleSelect = true,
  products,
  currentProducts,
  setCurrentProducts,
  teams,
  currentTeams,
  setCurrentTeams,
  setSelectedSpace,
  selectedSpace
}) {
  const [active, setActive] = useState(false);
  const [query, setQuery] = useState('');
  const [productsSelected, setProductsSelected] = useState([]);
  const [teamsSelected, setTeamsSelected] = useState([]);

  const menuRef = useRef();
  const { pathname } = window.location;

  const updateSearchFilter = (q) => {
    setQuery(q);
  };

  const filteredProducts = products.filter(
    (v) =>
      v.short_name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) &&
      !v.deleted_at
  );
  const filteredTeams = teams.filter(
    (v) =>
      v.short_name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) &&
      !v.deleted_at
  );

  const handleClick = (e) => {
    if (menuRef && !menuRef.current.contains(e.target)) {
      setActive(false);
    }
  };

  useEffect(() => {
    setTeamsSelected(currentTeams);
    setProductsSelected(currentProducts);
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  // Product functions
  function selectProduct(product) {
    if (singleSelect) {
      setSelectedSpace(product, 'product');
      setProductsSelected([product.id]);
      setCurrentProducts([product.id]);

      const teamIds = product.teams.map((t) => t.id);

      setTeamsSelected(teamIds);
      setCurrentTeams(teamIds);

      setActive(false);
      return;
    }

    if (productsSelected.find((t) => t.id === product.id)) {
      setProductsSelected(productsSelected.filter((t) => t.id !== product.id));
    } else {
      const newProducts = productsSelected.slice(0);
      newProducts.push(product);
      setProductsSelected(newProducts);
    }
  }

  function changeCurrentProduct(evt) {
    evt.preventDefault();
    if (productsSelected.length > 0 && productsSelected !== currentProducts) {
      setCurrentProducts(productsSelected);
    }
  }
  // Team functions

  function selectTeam(team) {
    if (singleSelect) {
      setSelectedSpace(team, 'team');
      setTeamsSelected([team.id]);
      setCurrentTeams([team.id]);

      const productIds = team.products.map((prod) => prod.id);

      setProductsSelected(productIds);
      setCurrentProducts(productIds);

      setActive(false);
      return;
    }

    if (teamsSelected.find((t) => t.id === team.id)) {
      setTeamsSelected(teamsSelected.filter((t) => t.id !== team.id));
    } else {
      const newTeams = teamsSelected.slice(0);
      newTeams.push(team);
      setTeamsSelected(newTeams);
    }
  }

  return (
    <div className="menu-container" ref={menuRef}>
      <div
        className={`menu-toggle ${active ? 'active' : ''}`}
        onClick={() => setActive(!active)}
      >
        <span>Spaces</span>
        <div className={`--arr ${active && 'active'}`} />
      </div>
      {active && (
        <div className="menu-content">
          <div className="space-search-container">
            <input
              id="space-search"
              type="text"
              value={query}
              onChange={(e) => updateSearchFilter(e.target.value)}
            />
            {query.length > 0 ? (
              <button type="button" onClick={() => setQuery('')}>
                <IoMdCloseCircle color="white" size="1.2em" />
              </button>
            ) : (
              <img src={searchIcon} alt="search-icon" />
            )}
          </div>
          {!pathname.match(/^\/cadences/) ? (
            <DataBlock title="Products" expanded={false} icon={newProduct}>
              {filteredProducts.map((product) => (
                <div
                  key={product.id}
                  onClick={() => selectProduct(product)}
                  className={`selector ${
                    selectedSpace.id === product.id &&
                    selectedSpace.short_name === product.short_name &&
                    'active'
                  }`}
                >
                  <span>{truncate(product.short_name, 25)}</span>
                  <div className="state-block" />
                </div>
              ))}
            </DataBlock>
          ) : null}
          <DataBlock title="Teams" expanded icon={newTeam}>
            {/*            {pathname !== '/cadences' &&
              query.length <= 0 && (
                <div
                  onClick={() =>
                    selectTeam({
                      id: 'unassigned',
                      products: [{ id: 'unassigned' }]
                    })
                  }
                  className={selectedSpace.id === 'unassigned' && 'active'}
                >
                  Unassigned
                  <div className="state-block" />
                </div>
              )} */}
            {filteredTeams.map(
              (team) =>
                (pathname === '/cadences' ? team.kanban !== true : true) && (
                  <div
                    key={team.id}
                    onClick={() => selectTeam(team)}
                    className={`selector ${
                      selectedSpace.id === team.id &&
                      selectedSpace.type === team.type &&
                      'active'
                    }`}
                  >
                    <span>{truncate(team.short_name, 25)}</span>
                    <div className="state-block" />
                  </div>
                )
            )}
          </DataBlock>
        </div>
      )}
      <div className="space-name">
        {selectedSpace.short_name ? truncate(selectedSpace.short_name, 50) : ''}
      </div>
    </div>
  );
}

function DataBlock({ children, expanded, title, icon }) {
  const [expandedBlock, setExpandedBlock] = useState(false);

  useEffect(() => {
    setExpandedBlock(expanded);
  }, [expanded]);

  return (
    <div className="data-container">
      <div
        className="--header"
        onClick={() => setExpandedBlock(!expandedBlock)}
      >
        {icon && <img src={icon} alt={`${title}-icon`} height="16px" />}
        {title}
        <div className={`--arr ${expandedBlock && 'active'}`} />
      </div>
      {expandedBlock && <div className="--content">{children}</div>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  products: state.productReducer.products,
  currentProducts: state.defaultOptionsReducer.currentProducts,
  teams: state.teamReducer.teams,
  currentTeams: state.defaultOptionsReducer.currentTeams,
  selectedSpace: state.workPackageReducer.selectedSpace || { id: 'unassigned' }
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentProducts,
      setCurrentTeams,
      setSelectedSpace
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SpacesMenu);
