import { createSelector } from 'reselect';

const getCadences = (state) => state.cadenceReducer.cadences;
const selectedTeamId = (state) =>
  state.workPackageReducer.selectedSpace.type === 'team'
    ? state.workPackageReducer.selectedSpace.id
    : null;

export const filterActiveCadences = createSelector(
  [getCadences, selectedTeamId],
  (cadences, teamId) =>
    cadences.filter(
      (cadence) =>
        !cadence.deleted_at && (!teamId || cadence.team_id === teamId)
    )
);

export const anyActiveCadence = createSelector([getCadences], (cadences) => {
  const activeCadences = cadences.filter(
    (cad) => cad.status !== 'Accepted' && !cad.deleted_at
  );
  return !!activeCadences.length;
});
