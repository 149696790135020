import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import FileDropBox from '../Common/fileDropBox';
import ItemTypes from '../../constants/itemTypes';
import { openModal } from '../../actions/modalActions';
import modalTypes from '../../constants/modalTypes';
import RoleToggler from '../Common/roleToggler';

const TeamDetails = (props) => {
  const {
    team,
    isUploading,
    onChange,
    onUpdate,
    handleFileDrop,
    handleFileUpload,
    addMember,
    changeRole,
    allowCreate,
    allowEdit,
    allowAddMembers,
    showTrash,
    roleChanging,
    allowUpdateUser
  } = props;

  const imageSrc = team?.picture || '';
  const clearDropbox = !(isEmpty(imageSrc) || imageSrc.match(/missing.png/));
  return (
    <div
      role="tabpanel"
      className="tab-pane active team-details-tab"
      id="details"
    >
      <div className="thumbnail-description-container">
        <div className="thumbnail-container">
          <a className="thumbnail">
            {isUploading ? (
              <div id="mini-loader" />
            ) : (
              <img src={imageSrc} alt="" />
            )}
          </a>
          {!isUploading && (
            <FileDropBox
              accepts={ItemTypes.FILE}
              onDrop={handleFileDrop}
              onUpload={handleFileUpload}
              allowCreateAttachment={allowCreate}
              clear={clearDropbox}
            />
          )}
        </div>
        <div className="team-description">
          <textarea
            onChange={({ target }) =>
              !team.id ? onChange({ description: target.value }) : undefined
            }
            defaultValue={team.description}
            placeholder="Enter team communication tools and important links here"
            onBlur={({ target }) =>
              team.id > 0 ? onUpdate({ description: target.value }) : undefined
            }
            disabled={!allowEdit}
          />
        </div>
      </div>

      <div className="add-users-operations-container">
        {allowAddMembers && (
          <div className="add-user">
            <button
              type="submit"
              className="btn btn-xs button-lavender"
              onClick={addMember}
            >
              <strong>Add member</strong>
            </button>
          </div>
        )}
        {allowEdit && (
          <div className="operations-radio-container">
            <span>Operate in:</span>
            <label className="radio">
              <input
                type="radio"
                checked={!team.kanban}
                onChange={() =>
                  team.id > 0
                    ? onUpdate({ kanban: false })
                    : onChange({ kanban: false })
                }
              />
              Scrum
            </label>
            <label className="radio">
              <input
                type="radio"
                checked={team.kanban}
                onChange={() =>
                  team.id > 0
                    ? onUpdate({ kanban: true })
                    : onChange({ kanban: true })
                }
              />
              Kanban
            </label>
          </div>
        )}
      </div>
      {team.id && (
        <div className="user-table-wrapper">
          <div className="users-table">
            <table cellSpacing="0">
              <thead>
                <tr>
                  <th>
                    <span className="table-header-text">User Name</span>
                  </th>
                  <th>
                    <span className="table-header-text">Name</span>
                  </th>
                  <th>
                    <span className="table-header-text">Display Name</span>
                  </th>
                  <th>
                    <span className="table-header-text">Role</span>
                  </th>
                  <th>
                    <span className="table-header-text last-header">
                      Capacity
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {team.members &&
                  team.members
                    .sort((a, b) => a.name.localeCompare(b.name)) // alphabetize
                    .map((user) => {
                      const memberDeleted = team?.deleted_members?.find(
                        (t) => t.id === user.id
                      );

                      if (!showTrash && memberDeleted) return null;

                      const userRoles = user.team_roles
                        ? user.team_roles.filter(
                            (r) => r.team_id === team.id
                          ) || []
                        : [];
                      const deletedClassName = memberDeleted ? 'deleted' : '';
                      return (
                        <tr key={user.email} className={`${deletedClassName}`}>
                          <td className="user-name-column">
                            <div className="user-name">
                              <div className="dropdown btn-component-div">
                                <button
                                  id="detail-dropdown"
                                  type="button"
                                  className="btn btn-link"
                                  onClick={() =>
                                    props.openModal(
                                      modalTypes.editUser,
                                      { editExistingUser: true, user },
                                      'user'
                                    )
                                  }
                                  disabled={!allowUpdateUser}
                                >
                                  <span
                                    className="glyphicon glyphicon-cog"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                              <span>
                                <span className="team-modal-user-email">
                                  <a href={`mailto:${user.email}`}>
                                    {user.email}
                                  </a>
                                </span>
                              </span>
                            </div>
                          </td>
                          <td className="name-column">
                            <div>{user.name}</div>
                          </td>
                          <td className="nickname-column">
                            <div>{user.nickname}</div>
                          </td>
                          <td className="role-column">
                            <RoleToggler
                              user={user}
                              roleLevel="team"
                              roleLevelId={team.id}
                              disabled={!allowEdit}
                              handleRoleChange={changeRole}
                              isSending={roleChanging}
                              team={team}
                            />
                          </td>
                          <td className="capacity-column">
                            <div>{user.capacity}</div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

TeamDetails.propTypes = {
  team: PropTypes.object,
  users: PropTypes.array,
  isUploading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  showTrash: state.trashReducer.showTrash,
  roleChanging: state.teamReducer.roleChanging
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);
