import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { toggleTourActive } from '../../actions/tourActions';
import help from '../../../assets/images/help.png';
import helpInactive from '../../../assets/images/help-inactive.png';
import idea from '../../../assets/images/idea.png';

const GuideMe = (props) => {
  const { tour, available, showing, currentStep, attention } = props;
  const history = useHistory();

  useEffect(() => {
    // console.log('GuideMe effect', showing, currentStep, tour.id);
    if (showing && showing === tour.id) {
      if (currentStep) {
        tour.show(currentStep);
        const tourStep = tour.getCurrentStep();
        if (tourStep) {
          const buttonWithRouteIndex = tourStep.options.buttons.findIndex(
            (button) => button.route !== undefined
          );

          if (buttonWithRouteIndex > 0) {
            // add a new button to navigate to the route
            const tourStepOptions = cloneDeep(tourStep.options);
            const { route } = tourStepOptions.buttons[buttonWithRouteIndex];
            tourStepOptions.buttons[buttonWithRouteIndex].action = () => {
              history.push(route);
            };
            tourStep.updateStepOptions(tourStepOptions);
          }
        } else {
          console.warn('Current step not found in tour', currentStep);
          tour.show();
        }
        // console.log('showing', currentStep, tour.id);
      } else {
        // console.log('showing', tour.id);
        tour.show();
      }
    } else {
      // console.log('hiding', tour.id);
      // tour.hide();
    }
  }, [showing, currentStep, tour]);

  return (
    available && (
      <button
        type="button"
        id="guide-me"
        className="guide"
        onClick={() => {
          props.toggleTourActive(tour, currentStep);
        }}
      >
        <img
          // eslint-disable-next-line no-nested-ternary
          src={showing ? help : attention ? idea : helpInactive}
          className={`btn ${showing ? 'guide-active' : 'guide-inactive'} ${
            attention ? 'guide-attention' : ''
          }`}
          // eslint-disable-next-line no-nested-ternary
          alt={
            showing ? 'guide-active' : attention ? 'guide-attention' : 'guide'
          }
        />
      </button>
    )
  );
};

const mapStateToProps = (state) => {
  const { activeTour, available, showing, currentStep, attention } =
    state.tourReducer;
  return {
    tour: state.tourReducer[activeTour],
    available,
    showing,
    currentStep,
    attention
  };
};

export default connect(mapStateToProps, { toggleTourActive })(GuideMe);
