import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modalActions';

const SmallNewModal = ({ closeModal, type, submitFn, id, organizationId }) => {
  const [text, setText] = useState('');
  const inputRef = useRef();

  const handleEscapePress = (e) => {
    if (e.keyCode === 27) {
      closeTheModal();
    }
  };

  useEffect(() => {
    inputRef.current.focus();
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);

  const closeTheModal = () => closeModal(null, id);

  const handleButtonClick = () => {
    submitFn(text, organizationId);
    closeTheModal();
  };

  const handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      handleButtonClick();
    }
  };

  return (
    <div className="small-new-modal-backdrop" onClick={closeTheModal}>
      <div className="small-new-modal" onClick={(e) => e.stopPropagation()}>
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleEnterPress}
          ref={inputRef}
        />
        <div className="sn-text-container">
          <span>Please choose a name for your {type}</span>
          <button onClick={handleButtonClick}>Submit</button>
        </div>
      </div>
    </div>
  );
};

SmallNewModal.propTypes = {
  closeModal: PropTypes.func,
  type: PropTypes.string,
  submitFn: PropTypes.func,
  organizationId: PropTypes.number
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SmallNewModal);
