// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer, logger } from '@rails/actioncable';

const setConsumerToWindow = () => {

  // Enable ActionCable logging?
  logger.enabled = false

  window.App = createConsumer();
};

export default setConsumerToWindow();
