const eventCache = {};

// eslint-disable-next-line import/prefer-default-export
export const onResizeDone = (
  isInitializing,
  name,
  stateChangeTrue,
  stateChangeFalse,
  time = 0
) => {
  let thingToDo;

  if (!eventCache[name]) {
    const eventFn = () => {
      stateChangeTrue();
      clearTimeout(thingToDo);
      thingToDo = setTimeout(stateChangeFalse, time);
    };
    eventCache[name] = eventFn;
  }

  if (isInitializing) {
    window.addEventListener('resize', eventCache[name]);
  } else {
    window.removeEventListener('resize', eventCache[name]);
    delete eventCache[name];
  }
};
