import React, { Component } from 'react';
import ItemTypes from '../../constants/itemTypes';
import Attachments from '../Common/attachments';
import DescriptionField from '../Common/descriptionField';
import ProductSuccessCriteria from '../Criteria/productSuccessCriteria';
import OwnerSelect from '../Common/ownerSelect';
import { allows } from '../../utils/authorization';

class DetailsTab extends Component {
  state = {
    isFull: false,
    isCriteriaFull: false
  };

  toggleIsFull = () =>
    this.setState((prevState) => ({ isFull: !prevState.isFull }));

  toggleCriteriaFull = () =>
    this.setState((prevState) => ({
      isCriteriaFull: !prevState.isCriteriaFull
    }));

  render() {
    const {
      product,
      handleUpdate,
      removeCriteria,
      addCriteria,
      attachmentList,
      addAttachment,
      removeAttachment,
      selectUpdate
    } = this.props;
    const { isFull, isCriteriaFull } = this.state;
    const allowEdit = product.id ? allows('update', product, 'product') : true;
    const allowCreateAttachments = allows('create_attachments', product, 'product');
    const allowDeleteAttachments = allows('destroy_attachments', product, 'product');
    return (
      <div className="details-tab">
        {!isCriteriaFull && (
          <div className={`row top-description ${isFull ? 'full' : ''}`}>
            <DescriptionField
              id={product.id}
              type="Product"
              description={product.description}
              onFieldChange={handleUpdate}
              allowEdit={allowEdit}
              isFull={isFull}
              toggleIsFull={this.toggleIsFull}
            />
            {!isFull && (
              <div className="selects-column detail-tab-right">
                <OwnerSelect
                  label="Owner"
                  selectedOwner={{ id: product.owner_id }}
                  allowEdit={allowEdit && product.teams.length > 0}
                  onUpdate={selectUpdate}
                  product={product}
                />
                <OwnerSelect
                  label="Chief PO"
                  selectedOwner={{ id: product.chief_product_owner_id }}
                  allowEdit={allowEdit && product.teams.length > 0}
                  onUpdate={selectUpdate}
                  product={product}
                />
              </div>
            )}
          </div>
        )}
        {!isFull && (
          <div className="row bottom-ac">
            <div className="ac-container">
              <ProductSuccessCriteria
                criteria={product.success_criteria_attributes}
                product={product}
                isNew={!product.id}
                detailCard
                addCriteria={addCriteria}
                removeCriteria={removeCriteria}
                isCriteriaFull={isCriteriaFull}
                toggleCriteriaFull={this.toggleCriteriaFull}
              />
            </div>
            {!isCriteriaFull && (
              <Attachments
                itemId={product.id}
                type={ItemTypes.PRODUCT}
                allowCreate={allowCreateAttachments}
                allowDelete={allowDeleteAttachments}
                attachmentList={attachmentList}
                addAttachment={addAttachment}
                removeAttachment={removeAttachment}
                isNew={!product.id}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DetailsTab;
