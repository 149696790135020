import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import OptionsContainer from './OptionsContainer';

const SelectField = ({
  options,
  selectedOption,
  placeholder,
  handleOptionChange,
  disabled,
  allowEdit,
  sortItems,
  type_short,
  containerClass
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [inputValue, setInputValue] = useState('');

  // arrowOscillator is used in scrolling with arrowkeys functionality
  // it simply changes from true to false to trigger its useEffect continuously
  const [arrowOscillator, setArrowOscillator] = useState(false);

  const [renderedOptions, setRenderedOptions] = useState([]);

  const inputRef = useRef();
  const containerRef = useRef();

  const handleContainerClick = () => {
    if (disabled) return;
    inputRef.current.focus();
    setOpenDropDown(true);
  };

  useEffect(() => {
    const selected = renderedOptions[activeIndex];
    if (selected) selected.scrollIntoView(false);
  }, [arrowOscillator]);

  useEffect(() => {
    if (openDropDown) {
      setRenderedOptions(document.querySelectorAll('.custom-select-option'));
    } else if (!openDropDown && renderedOptions.length) {
      setRenderedOptions([]);
    }
  }, [openDropDown]);

  // alphebetizes dropdown list
  if (sortItems) {
    options.sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
    );
  }

  // determine options for dropdown list
  const dynamicallyRenderOptions = () => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (activeIndex === 0) {
        setActiveIndex(options.length - 1);
      } else {
        setActiveIndex((prevState) => prevState - 1);
      }
      setArrowOscillator((prev) => !prev);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (activeIndex === options.length - 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex((prevState) => prevState + 1);
      }
      setArrowOscillator((prev) => !prev);
    } else if (e.key === 'Enter') {
      if (dynamicallyRenderOptions().length > 0) {
        const selected = dynamicallyRenderOptions()[activeIndex];
        if (!selected.disabled) handleOptionChange(selected);
      }
      inputRef.current.blur();
      setInputValue('');
      setOpenDropDown(false);
    }
  };

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    setActiveIndex(0);
  };

  const handleOptionClick = (optionLabel) => {
    const selected = options.find((option) => option.label === optionLabel);
    if (selected.disabled) return;
    handleOptionChange(selected);
    setInputValue('');
    setOpenDropDown(false);
  };

  const handleInputBlur = (e) => {
    setInputValue('');
    setOpenDropDown(false);
  };

  return (
    <div
      className={`custom-select-container ${disabled ? 'disabled' : ''} ${
        type_short ? 'type_short' : ''
      } ${containerClass}`}
      onClick={handleContainerClick}
      ref={containerRef}
      onBlur={handleInputBlur}
    >
      <input
        type="text"
        className={`${type_short ? 'type_short' : ''}`}
        value={inputValue}
        name="inputValue"
        ref={inputRef}
        onKeyDown={handleOnKeyDown}
        onChange={handleOnChange}
        autoComplete="off"
        spellCheck={false}
        placeholder={selectedOption?.value ? '' : placeholder}
        disabled={!allowEdit}
      />
      {!inputValue.length && (
        <div className="custom-select-display">
          {selectedOption?.label !== 'Clear' ? selectedOption?.label : ''}
        </div>
      )}

      <span className={`fa_icon_container icon  ${disabled ? 'disabled' : ''}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            fill="#898BC4"
            d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"
          />
        </svg>
      </span>

      <div className={`custom-select-triangle ${disabled ? 'disabled' : ''}`} />
      {openDropDown && !disabled && (
        <OptionsContainer
          options={dynamicallyRenderOptions()}
          openDropDown={openDropDown}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          handleOptionClick={handleOptionClick}
        />
      )}
    </div>
  );
};

SelectField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOption: PropTypes.object,
  placehoder: PropTypes.string,
  handleOptionChange: PropTypes.func,
  disabled: PropTypes.bool,
  containerClass: PropTypes.string
};

SelectField.defaultProps = {
  containerClass: ''
};

export default SelectField;
