import { offset } from '@floating-ui/dom';
import { allowsUser } from '../../utils/authorization';

export default [
  {
    id: 'feature-discovery',
    text: "<h2>What are you working on?</h2><p>It can be hard to start backlog planning. Don't feel that you have to figure it all out.\
    </p><p>What are some of the most important pieces of what you are working on? For example: Product catalog, Online ordering, Search by name.</p>\
    <p>Type a short phrase to identify each thing and hit enter to build an initial list.</p>",
    selector: '#add-item-input',
    on: 'top-start',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      allowsUser('create_work_packages', state),
  },
  {
    id: 'feature-ranking',
    text: '<p>Now drag to rank the items you created with the most important ones at the top.</p>',
    selector: '.story-list',
    on: 'left',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
        .length > 2
  },
  {
    id: 'feature-value',
    text: '<p>It may help to give each one a relative value, with 1 being the lowest.</p>',
    selector: '.icon.value',
    on: 'left',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
        .length > 0
  },  
  {
    id: 'feature-sizing',
    text: '<p>Now that you have the beginning of good list, consider shirt-sizing your features to see which are the biggest.</p>\
    <p>Ask your team to pick the items that seem the smallest, and size them as XS.  Similarly, pick the largest and make it an XL.</p>\
    <p>This will help you decide which ones need to be broken down further.<p>',
    selector: '.icon.size',
    on: 'left',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
        .length > 9
  },
   {
    id: 'change-level',
    text: '<p>If some items seem much bigger, or much smaller than the others, you can convert them into higher or lower level items\
    by choosing "Change to..." options on the gear menu.<p>',
    selector: '.row-menu',
    on: 'right',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 5, crossAxis: 12 })]
    },
    predicate: (state) =>
      state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
        .length > 9
  }, 
  {
    id: 'feature-details',
    text: "<p>Don't try to list out everything; just start with the most important ones that come to mind.</p>\
    <p>When you are done, open the most important one to add details.</p>",
    selector: '.id-col:first-of-type > a',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
        .length > 0
  }
];
