import React, { Component } from 'react';
import { isEqual, intersectionBy, orderBy } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Default from '../../constants/defaults';
import StoryList from '../PlanWall/storyList';
import ListSort from '../Buttons/listSort';
import ListFilter from '../PlanWall/listFilter';
import VrsFilterDropdown from '../PlanWall/vrsFilterDropdown';
import SprintFilter from '../PlanWall/sprintFilter';
import TargetFilter from '../PlanWall/targetFilter';
import StateFilter from '../PlanWall/stateFilter';
import { extractImmediateDescendants } from '../../utils/hierarchyHelpers';
import { sortList } from '../../actions/workPackageActions';
import {
  WorkPackageStatuses,
  HigherLevelStatuses
} from '../../constants/storyStatusTypes';

class StoryListHeader extends Component {
  constructor(props) {
    super(props);

    this.listHeader = null;

    this.state = {
      sortDescending: true,
      sortField: 'rank',
      vrsFilter: {},
      sprintFilter: [],
      targetFilter: [],
      stateFilter: [],
      listTeamFilter: [],
      tableFields: Default.tableFields.target.fields,
      savedState: {},
      listWidth: 0,
      listHeight: 0,
      oscillator: false,
      results: props.results
    };

    this.levelContent = this.levelContent.bind(this);
    this.activateSort = this.activateSort.bind(this);
    this.buildVrsFilter = this.buildVrsFilter.bind(this);
    this.filterByVrs = this.filterByVrs.bind(this);
    this.buildFilterBySprint = this.buildFilterBySprint.bind(this);
    this.filterBySprint = this.filterBySprint.bind(this);
    this.buildFilterByTarget = this.buildFilterByTarget.bind(this);
    this.filterByTarget = this.filterByTarget.bind(this);
    this.buildFilterByState = this.buildFilterByState.bind(this);
    this.filterByState = this.filterByState.bind(this);
    this.filterByParent = this.filterByParent.bind(this);
    this.selectColumns = this.selectColumns.bind(this);
    this.saveComponentState = this.saveComponentState.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.calculateListHeight = this.calculateListHeight.bind(this);
    this.calculateListWidth = this.calculateListWidth.bind(this);
    this.forceRerender = this.forceRerender.bind(this);
  }

  componentWillMount() {
    if (!this.props.noPreserve) this.processComponentState();
  }

  componentDidMount() {
    this.calculateListWidth();
    this.calculateListHeight();
    window.addEventListener('resize', this.forceRerender);
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setState({
  //     tableFields: Default.tableFields.target.fields
  //   });
  // }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.preservedState, this.state.savedState) &&
      !this.props.noPreserve
    ) {
      this.processComponentState();
    }
    if (!isEqual(prevProps.results, this.props.results)) {
      this.setState({results: this.props.results})
    }

    this.calculateListWidth();
    this.calculateListHeight();
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.forceRerender);
  }

  buildFilterByState = (event, stateId) => {
    const { stateFilter } = this.state;
    const stateOptions = WorkPackageStatuses;
    let newStateFilter = JSON.parse(JSON.stringify(stateFilter));
    if (event.target && event.target.type === 'button') {
      if (stateFilter.length === Object.keys(stateOptions).length) {
        newStateFilter = [];
      } else {
        newStateFilter = Object.keys(stateOptions).map(
          option => stateOptions[option]
        );
      }
    } else if (stateFilter.indexOf(stateId) !== -1) {
      newStateFilter = stateFilter.filter(id => id !== stateId);
    } else {
      newStateFilter.push(stateId);
    }
    this.setState({ stateFilter: newStateFilter }, () => {
      if (!this.props.noPreserve) this.saveComponentState();
    });
  };

  processComponentState() {
    const { preservedState } = this.props;
    const { tableFields } = this.state;
    let {
      sortField,
      sortDescending,
      vrsFilter,
      showCompleted,
      sprintFilter,
      targetFilter,
      stateFilter,
      parentFilter
    } = this.state;
    const visibleFieldNames = preservedState.col
      ? window.atob(preservedState.col).split(',')
      : [];
    const sort = preservedState.sort
      ? window.atob(preservedState.sort).split(',')
      : [];
    const filters = preservedState.fil ? window.atob(preservedState.fil) : '{}';
    visibleFieldNames.forEach(name => {
      const index = tableFields.findIndex(
        field => field.name.toLowerCase() === name.toLowerCase()
      );
      if (index > -1 && tableFields[index]) {
        tableFields[index].visible = true;
      }
    });
    [sortField] = sort;
    sortDescending = JSON.parse(sort[1]);
    vrsFilter = JSON.parse(filters).vrsFilter || {};
    sprintFilter = JSON.parse(filters).sprintFilter || [];
    targetFilter = JSON.parse(filters).targetFilter || [];
    stateFilter = JSON.parse(filters).stateFilter || [];
    parentFilter = JSON.parse(filters).parentFilter || [];
    showCompleted = preservedState.sc;
    this.setState({
      sortField,
      sortDescending,
      vrsFilter,
      sprintFilter,
      targetFilter,
      stateFilter,
      parentFilter,
      showCompleted,
      tableFields,
      savedState: { ...preservedState }
    });
  }

  saveComponentState() {
    const {
      tableFields,
      sortField,
      sortDescending,
      vrsFilter,
      sprintFilter,
      targetFilter,
      stateFilter,
      parentFilter
    } = this.state;
    const visibleFields = tableFields.filter(field => field.visible);
    const visibleFieldNames = visibleFields.reduce(
      (acc, el) => (acc.length ? acc.concat(el.name) : [el.name]),
      []
    );
    const savedState = {
      ...this.props.preservedState,
      col: window.btoa(visibleFieldNames),
      sort: window.btoa([sortField, sortDescending]),
      fil: window.btoa(
        JSON.stringify({
          vrsFilter,
          sprintFilter,
          targetFilter,
          stateFilter,
          parentFilter
        })
      )
    };
    this.setState({ savedState });
    this.props.preserveComponentState(savedState);
  }

  activateSort(field) {
    let criteria = field;
    if (this.state.sortField === criteria) criteria = 'rank';
    const ascending =
      criteria === 'rank' || criteria === 'name' || criteria === 'description';

    this.setState(
      {
        sortField: criteria,
        sortDescending: !ascending
      },
      () => {
        const order = this.state.sortDescending ? 'desc' : 'asc';
        const sortedResults = orderBy(this.state.results, criteria, order);
        this.setState({ results: sortedResults });
        if (!this.props.noPreserve) this.saveComponentState();
      }
    );
  }

  buildVrsFilter(action, type, value) {
    const { vrsFilter } = this.state;
    const filterByVrs = vrsFilter[type] || [];
    const index = filterByVrs.findIndex(item => item === value);
    if (action === 'add' && index < 0) {
      vrsFilter[type] = vrsFilter[type]
        ? vrsFilter[type].concat(value)
        : [value];
    } else if (action === 'remove') {
      vrsFilter[type].splice(index, 1);
    } else {
      vrsFilter[type] = [];
    }
    this.setState({ vrsFilter }, () => {
      // this.filterByVrs();
      if (!this.props.noPreserve) this.saveComponentState();
    });
  }

  handleVrsFilterClick = type => {
    const { vrsFilter } = this.state;
    const { defaultOptions, level } = this.props;
    let defaultType;
    if (['rroe', 'tc'].includes(type)) defaultType = 'risk';
    else if (type === 'size' && level > 1) defaultType = 't_shirt_size';
    else defaultType = type;

    const defaults = defaultOptions[`${defaultType}_defaults`];
    const fieldDefaults = defaults ? defaults.values : {};
    const severityOptions = defaultOptions.severity_defaults
      ? defaultOptions.severity_defaults.values
      : {};
    const severityFilters = Object.keys(severityOptions).map(
      s => `severity-${s}`
    );
    const allOptions =
      type === 'value' && level === 1
        ? Object.keys(fieldDefaults).concat(severityFilters)
        : Object.keys(fieldDefaults);
    if (
      vrsFilter[type] &&
      vrsFilter[type].length === Object.keys(allOptions).length + 1
    ) {
      vrsFilter[type] = [];
      this.setState({ vrsFilter });
    }
  };

  filterByVrs = ({ result_type: resultType }) => {
    const { vrsFilter } = this.state;
    const filterKeys = Object.keys(vrsFilter);

    const matchWithResultAttribute = filterKey => {
      if (vrsFilter[filterKey].length === 0) return true;

      const resultKind = resultType.kind;
      let resultAttribute = resultType.type_attributes[filterKey];
      resultAttribute = resultAttribute && resultAttribute.toString();

      const isUnassignedSelected =
        vrsFilter[filterKey].includes('unassigned') && !resultAttribute;

      if (resultKind === 'Defect' && filterKey === 'value') {
        if (vrsFilter[filterKey].includes(`severity-${resultAttribute}`))
          return true;
        return isUnassignedSelected;
      }
      if (vrsFilter[filterKey].includes(resultAttribute)) return true;
      return isUnassignedSelected;
    };

    return filterKeys.every(matchWithResultAttribute);
  };
  // filterByVrs() {
  //     const { vrsFilter } = this.state;
  //     let results = this.props.results;
  //     const sorted = {};
  //     const filterKeys = Object.keys(vrsFilter);
  //     filterKeys.forEach((key, index) => {
  //       sorted[key] = vrsFilter[key].length
  //         ? results.filter(item => {
  //             // handle unassigned filter option
  //             if (
  //               vrsFilter[key].includes('unassigned') &&
  //               !item.result_type.type_attributes[key]
  //             ) {
  //               return true;
  //             }
  //             // handle defect value filtering
  //             if (item.result_type.kind === 'Defect' && key === 'value') {
  //               if (
  //                 vrsFilter[key].includes(
  //                   `severity-${item.result_type.type_attributes[key]}`
  //                 )
  //               )
  //                 return true;
  //               return false;
  //             }
  //             // handle general filtering
  //             if (
  //               vrsFilter[key].includes(
  //                 `${item.result_type.type_attributes[key]}`
  //               )
  //             ) {
  //               return true;
  //             }
  //           })
  //         : results;
  //       if (index > 0 && index < filterKeys.length) {
  //         results = intersectionBy(
  //           sorted[key],
  //           sorted[filterKeys[index - 1]],
  //           'id'
  //         );
  //       } else {
  //         results = sorted[key];
  //       }
  //     });
  //     this.setState({ results });
  //   }

  buildFilterByListTeam = option => {
    const { listTeamFilter } = this.state;
    const { currentTeams } = this.props;
    let newListTeamFilter = [...listTeamFilter];
    if (option && option.target && option.target.type === 'button') {
      if (newListTeamFilter.length === currentTeams.length) {
        newListTeamFilter = [];
      } else {
        newListTeamFilter = currentTeams.map(team => team.id);
      }
    } else if (listTeamFilter.includes(option)) {
      newListTeamFilter = listTeamFilter.filter(id => id !== option);
    } else {
      newListTeamFilter.push(option);
    }
    this.setState({ listTeamFilter: newListTeamFilter }, () => {
      if (!this.props.noPreserve) this.saveComponentState();
    });
  };

  filterByListTeam = result => {
    const { listTeamFilter } = this.state;
    let includes = true;
    if (listTeamFilter.length > 0) {
      includes = listTeamFilter.includes(
        result.team_id ? result.team_id : 'unassigned'
      );
    }
    return includes;
  };

  handleListTeamFilterClick = () => {
    const { listTeamFilter } = this.state;
    const { currentTeams } = this.props;
    if (listTeamFilter.length === Object.keys(currentTeams).length)
      this.setState({ listTeamFilter: [] });
  };

  buildFilterBySprint(sprint) {
    const { sprintFilter } = this.state;
    let newSprintFilter = JSON.parse(JSON.stringify(sprintFilter));

    if (sprint.target && sprint.target.type === 'button') {
      newSprintFilter = [];
    } else if (sprintFilter.includes(sprint)) {
      newSprintFilter = sprintFilter.filter(id => id !== sprint);
    } else {
      newSprintFilter.push(sprint);
    }

    this.setState({ sprintFilter: newSprintFilter }, () => {
      if (!this.props.noPreserve) this.saveComponentState();
    });
  }

  filterBySprint(result) {
    const selectedSprints = this.state.sprintFilter;
    let includes = true;
    if (selectedSprints.length > 0) {
      if (selectedSprints.includes('unassigned')) {
        includes = !result.current_cadence;
      } else {
        includes =
          result.current_cadence &&
          selectedSprints.includes(result.current_cadence.id);
      }
    }
    return includes;
  }

  buildFilterByTarget(option) {
    const { targetFilter } = this.state;
    let newTargetFilter = [...targetFilter];

    if (option.target && option.target.type === 'button') {
      newTargetFilter = targetFilter.filter(filter => filter === 'unassigned');
      if (!targetFilter.includes(option)) {
        newTargetFilter = this.props.targets.map(target => target.id);
        newTargetFilter.push(option);
      }
    } else if (targetFilter.includes(option)) {
      newTargetFilter = targetFilter.filter(id => id !== option);
    } else {
      newTargetFilter.push(option);
    }

    this.setState({ targetFilter: newTargetFilter }, () => {
      if (!this.props.noPreserve) this.saveComponentState();
    });
  }

  // filterByTarget(result) {
  //   const selectedTargets = this.state.targetFilter;
  //   let includes = true;
  //   if (selectedTargets.length > 0) {
  //     if (selectedTargets.includes('unassigned')) {
  //       includes = !result.target;
  //     } else {
  //       let cow = this.checkDescendantsForTargets(selectedTargets, result);
  //       includes =
  //         result.target_id && cow

  //     }
  //   }
  //   return includes;
  // }

  filterByTarget = result => {
    const selectedTargets = this.state.targetFilter;

    if (selectedTargets.length < 1) return true;
    if (selectedTargets.includes('unassigned') && !result.target_id)
      return true;
    if (selectedTargets.includes(result.target_id)) return true;
    return false;
    // let includes = true;
    // if (selectedTargets.length > 0) {
    //   includes = this.checkDescendantsForTargets(selectedTargets, result);
    //   if (selectedTargets.includes('unassigned') && !result.target_id) {
    //     includes = true;
    //   }
    // }
    // return includes;
  };

  checkDescendantsForTargets(targetIds, result) {
    const { results } = this.props;

    const descendants = extractImmediateDescendants([result.id], results, result.level);
    return [result, ...descendants]
      .map(
        descendant =>
          descendant.target && targetIds.includes(descendant.target.id)
      )
      .includes(true);
  }

  levelContent(props) {
    const content = {
      1: props.resultList,
      2: props.features,
      3: props.capabilities,
      5: props.organizations
    };
    return content[props.level];
  }

  forceRerender() {
    this.setState(prevState => ({
      ...prevState,
      oscillator: !prevState.oscillator
    }));
  }

  filterByState(result) {
    const { stateFilter } = this.state;
    if (stateFilter.length > 0) {
      return stateFilter.includes(result.state);
    }
    return true;
  }

  filterByParent(result) {
    const { parentFilter } = this.state;
    if (parentFilter.length > 0) {
      return parentFilter.includes(result.parent_id);
    }
    return true;
  }

  applyFilters(results) {
    const { showTrash } = this.props;
    let filteredResults = results;
    if (!showTrash) {
      filteredResults = results.filter(result => !result.deleted_at);
    }
    return filteredResults
      .filter(this.filterByListTeam)
      .filter(this.filterByTarget)
      .filter(this.filterByState)
      .filter(this.filterByVrs);
  }

  calculateListHeight() {
    const panel = document.querySelector('.target-plan .col-sm-4 .panel');
    const { listHeight } = this.state;

    if (panel && panel.offsetHeight - 31 !== listHeight) {
      this.setState({ listHeight: panel.offsetHeight - 31 });
    }
  }

  calculateListWidth() {
    const { listWidth } = this.state;
    if (this.topRow && this.topRow.offsetWidth !== listWidth) {
      this.setState({ listWidth: this.topRow.offsetWidth });
    }
  }

  selectColumns(selected, checked) {
    const { tableFields } = this.state;
    const field = tableFields.find(tableField => tableField.name === selected);
    if (checked) {
      field.visible = true;
    } else {
      field.visible = false;
    }
    this.setState({ tableFields }, () => {
      if (!this.props.noPreserve) this.saveComponentState();
    });
  }

  headerColumns(fieldName, field) {
    const {
      vrsFilter,
      sortField,
      sortDescending,
      sprintFilter,
      targetFilter,
      stateFilter,
      listTeamFilter,
      level
    } = this.state;
    let filterClass;
    let showFilterIcon;

    if (['value', 'rroe', 'tc', 'size', 'risk'].includes(fieldName)) {
      const defaultType = ['rroe', 'tc'].includes(fieldName)
        ? 'risk'
        : fieldName;
      let defaults = this.props.defaultOptions[`${defaultType}_defaults`];

      if (fieldName === 'size' && this.props.level > 1)
        defaults = this.props.defaultOptions.t_shirt_size_defaults;

      const fieldDefaults = defaults ? defaults.values : {};
      filterClass =
        vrsFilter[fieldName] && vrsFilter[fieldName].length > 0
          ? ''
          : 'inactive';

      showFilterIcon =
        vrsFilter[fieldName] &&
        vrsFilter[fieldName].length > 0 &&
        vrsFilter[fieldName].length !== Object.keys(fieldDefaults).length + 1;

      return (
        <div className={`header-filter ${fieldName}`}>
          {showFilterIcon && (
            <div
              className={`filter-icon ${fieldName}`}
              onClick={() => {
                vrsFilter[fieldName] = [];
                this.setState({ vrsFilter: { ...vrsFilter } }, () => {
                  if (!this.props.noPreserve) this.saveComponentState();
                });
              }}
            />
          )}
          <div className="filter-group">
            <VrsFilterDropdown
              title={field.name}
              options={fieldDefaults}
              buildFilter={this.buildVrsFilter}
              checkedOptions={vrsFilter[fieldName] || []}
              onFilterClick={this.handleVrsFilterClick}
            />
          </div>
        </div>
      );
    }

    if (fieldName === 'target') {
      showFilterIcon =
        targetFilter &&
        targetFilter.length > 0 &&
        targetFilter.length !== Object.keys(this.props.targets).length + 1;
      return (
        <div className={`header-filter ${fieldName}`}>
          {showFilterIcon && (
            <div
              className={`filter-icon ${fieldName}`}
              onClick={() =>
                this.setState({ targetFilter: [] }, () => {
                  if (!this.props.noPreserve) this.saveComponentState();
                })
              }
            />
          )}
          <div className="filter-group">
            <TargetFilter
              type={fieldName}
              options={this.props.targets}
              filter={this.buildFilterByTarget}
              checkedOptions={targetFilter}
              classes="btn-link vrs-filter"
            />
          </div>
        </div>
      );
    }
    if (fieldName === 'state') {
      filterClass = stateFilter && stateFilter.length > 0 ? '' : 'inactive';
      const stateOptions =
        level === 1 ? WorkPackageStatuses : HigherLevelStatuses;

      showFilterIcon =
        stateFilter &&
        stateFilter.length > 0 &&
        stateFilter.length !== Object.keys(stateOptions).length;
      return (
        <div className={`header-filter ${fieldName}`}>
          {showFilterIcon && (
            <div
              className={`filter-icon ${fieldName}`}
              onClick={() =>
                this.setState({ stateFilter: [] }, () => {
                  if (!this.props.noPreserve) this.saveComponentState();
                })
              }
            />
          )}
          <div className="filter-group">
            <StateFilter
              level={this.props.level}
              filter={this.buildFilterByState}
              options={stateOptions}
              checkedOptions={stateFilter}
            />
          </div>
        </div>
      );
    }

    if (fieldName === 'team') {
      showFilterIcon =
        listTeamFilter &&
        listTeamFilter.length > 0 &&
        listTeamFilter.length !==
          Object.keys(this.props.currentTeams).length + 1;
      return (
        <div className={`header-filter ${fieldName}`}>
          {showFilterIcon && (
            <div
              className={`filter-icon ${fieldName}`}
              onClick={() =>
                this.setState({ listTeamFilter: [] }, () => {
                  if (!this.props.noPreserve) this.saveComponentState();
                })
              }
            />
          )}
          <div className="filter-group">
            <ListFilter
              type={fieldName}
              options={this.props.currentTeams}
              filter={this.buildFilterByListTeam}
              checkedOptions={listTeamFilter}
              onFilterClick={this.handleListTeamFilterClick}
              showUnassigned
            />
          </div>
        </div>
      );
    }

    const selected = this.state.sortField === field.source;
    return (
      <div
        type="button"
        className={`table-header ${selected ? 'selected-sort' : ''}`}
        onClick={() => this.activateSort(field.source)}
      >
        {field.name !== 'empty' && field.name}
      </div>
    );
  }

  render() {
    const {
      source,
      visibleFields,
      level,
      parentId,
      add,
      dropDown,
      setDropdown,
      expandedItems,
      isWorkPackageFetching
    } = this.props;
    const { listWidth, listHeight, results } = this.state;
    const targetResultIds = results.reduce((acc, result) => {
      if (result.target_id === parentId) {
        acc.push(result.id)
      }
      return acc
    }, [])

    return (
      <div
        ref={e => {
          this.listHeader = e;
        }}
        className="title-container"
      >
        <div
          className="row"
          ref={e => {
            this.topRow = e;
          }}
        >
          <div className="story-row-title col-md-12">
            <div className="left-field-group">
              {visibleFields.filter(field => field.groupLeft).map(field => {
                const fieldName = field.name.toLowerCase();
                return (
                  <div
                    key={field.name}
                    className={`${fieldName}-col ${fieldName}-col-header story-list-header`}
                  >
                    {this.headerColumns(fieldName, field)}
                  </div>
                );
              })}
            </div>

            <div className="right-field-group">
              {visibleFields.filter(field => !field.groupLeft).map(field => {
                const fieldName = field.name.toLowerCase();
                return (
                  <div key={field.name} className={`${fieldName}-col`}>
                    {this.headerColumns(fieldName, field)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <StoryList
          results={this.applyFilters(results)}
          source={source}
          fields={visibleFields}
          level={level}
          parentId={parentId}
          add={add}
          dropDown={dropDown}
          setDropdown={setDropdown}
          listHeight={listHeight}
          listWidth={listWidth}
          expandedItems={expandedItems}
          isWorkPackageFetching={isWorkPackageFetching}
          resultIds={targetResultIds}
          virtualized
          allResultsTogether
          expand
          target
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sortList
    },
    dispatch
  );

const mapStateToProps = state => ({
  isWorkPackageFetching:
    state.workPackageReducer.isSending || state.workPackageReducer.isFetching,
  isDragging: state.notificationReducer.dragInProgress,
  expandedItems: state.listReducer.expandedItems,
  showTrash: state.trashReducer.showTrash,
  currentTeams: state.defaultOptionsReducer.currentTeams
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoryListHeader);
