import React from 'react';
import { Link } from 'react-router-dom';

const Plan = ({ hasScrum, currentProducts }) => {
  const navigationOptions =
    currentProducts.length > 0
      ? [
          {
            name: 'Targets',
            link: 'targets'
          } /* ,
    { name: 'Deployments', link: 'deployments' } */
        ]
      : [];
  return (
    <div className="dropdown btn-component-div">
      <button
        type="button"
        id="detail-dropdown"
        className="btn btn-default menu-header"
        aria-haspopup="true"
      >
        Cadence
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdown">
        {hasScrum && (
          <li key="cadences">
            <Link to="/cadences" className="btn btn-link">
              Sprints
            </Link>
          </li>
        )}
        {navigationOptions.map((option) => (
          <li key={option.link}>
            <Link to={`/${option.link}`} className="btn btn-link">
              {option.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Plan;
