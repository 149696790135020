import { offset } from '@floating-ui/dom';
import { userWelcomed } from '../../actions/tourActions';
import { allows } from '../../utils/authorization';
import { userTourState } from '../../utils/userHelpers';

export default [
  {
    id: 'organization-setup',
    text: '<h2>Welcome to Agile Dezign!</h2><p>You are looking at the User Story backlog wall. This is the lowest level (smallest item) backlog view. If there are no stories here, now would be a great time to add some!',
    dispatch_on: {
      done: userWelcomed
    },
    autoShow: (state) => !userTourState(state).welcomed,
    predicate: (state) =>
      !allows(
        'administer', // admins are welcomed on the org wall
        state.organizationReducer.organizations[0],
        'organization'
      ) &&
      (state.userReducer.user.teams.length ||
        state.workPackageReducer.stories.length)
  },
  {
    id: 'organization-setup',
    text: '<h2>Welcome to Agile Dezign!</h2><p>You are looking at the User Story backlog wall. This is the lowest level (smallest item) backlog view. \
    <p>This wall is blank at the moment, because it seems you are not a member of any teams.  Please ask your administrator to add you to a team, so you can begin working with Agile Dezign!',
    dispatch_on: {
      done: userWelcomed
    },
    autoShow: (state) => !userTourState(state).welcomed,
    predicate: (state) =>
      !allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      ) &&
      state.userReducer.user.teams.length === 0 &&
      state.workPackageReducer.stories.length === 0 // if an org observer, may see things without being a team member
  },
  {
    id: 'space-selector',
    text: '<p>An important menu is the Spaces menu, which determines which backlog items are visible to you in Agile Dezign. </p>\
    <p>You can select any Team Space you are a member of, and potentially choose Product spaces if products have been defined by the administrator. \
    Products enable different teams to draw work from a shared backlog.</p>',
    selector: '.menu-toggle',
    on: 'right'
  },
  {
    id: 'show-guide',
    // eslint-disable-next-line no-multi-str
    text: '<p>Guidance and coaching is available throughout Agile Dezign for setting up your Agile project, including tips based on observation of your backlog and other data.</p>\
    <p>This icon will appear if guidance is available on the current page.  It will turn into a lightbulb if there are specific tips for you based on your data.</p>\
    <p>You can show or hide the guide by clicking on this icon, and also hide the active guide by hitting the &ltesc&gt key.</p>',
    selector: '#guide-me',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    },
    predicate: (state) =>
      !allows(
        'administer',
        state.organizationReducer.organizations[0],
        'organization'
      )
  },
  {
    id: 'story-discovery',
    text: "<h2>What are you working on?</h2><p>It can be hard to start backlog planning. Don't feel that you have to figure it all out.\
    </p><p>Many people find it easier to start from the next level up, or Feature backlog. You can navigate there using this Holon button, or select 'Feature' from the List Wall menu at the top. </p>",
    selector: '.header-holon',
    on: 'bottom',
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    }
  }
  // ,
  // {
  //   id: 'feature-ranking',
  //   text: '<p>Now drag to rank the items you created with the most important ones at the top.</p>',
  //   selector: '.story-list',
  //   on: 'left',
  //   floatingUIOptions: {
  //     middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
  //   },
  //   predicate: (state) =>
  //     state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
  //       .length > 2
  // },
  // {
  //   id: 'feature-value',
  //   text: '<p>It may help to give each one a relative value, with 1 being the lowest.</p>',
  //   selector: '.icon.value',
  //   on: 'left',
  //   floatingUIOptions: {
  //     middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
  //   },
  //   predicate: (state) =>
  //     state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
  //       .length > 0
  // },
  // {
  //   id: 'feature-sizing',
  //   text: '<p>Now that you have the beginning of good list, consider shirt-sizing your features to see which are the biggest.</p>\
  //   <p>Ask your team to pick the items that seem the smallest, and size them as XS.  Similarly, pick the largest and make it an XL.</p>\
  //   <p>This will help you decide which ones need to be broken down further.<p>',
  //   selector: '.icon.size',
  //   on: 'left',
  //   floatingUIOptions: {
  //     middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
  //   },
  //   predicate: (state) =>
  //     state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
  //       .length > 9
  // },
  //  {
  //   id: 'change-level',
  //   text: '<p>If some items seem much bigger, or much smaller than the others, you can convert them into higher or lower level items\
  //   by choosing "Change to..." options on the gear menu.<p>',
  //   selector: '.row-menu',
  //   on: 'right',
  //   floatingUIOptions: {
  //     middleware: [offset({ mainAxis: 5, crossAxis: 12 })]
  //   },
  //   predicate: (state) =>
  //     state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
  //       .length > 9
  // },
  // {
  //   id: 'feature-details',
  //   text: "<p>Don't try to list out everything; just start with the most important ones that come to mind.</p>\
  //   <p>When you are done, open the most important one to add details.</p>",
  //   selector: '.id-col:first-of-type > a',
  //   on: 'bottom',
  //   floatingUIOptions: {
  //     middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
  //   },
  //   predicate: (state) =>
  //     state.workPackageReducer.features.filter((feature) => !feature.deleted_at)
  //       .length > 0
  // }
];
