import {
  WorkPackageStatuses,
  CadenceStatuses
} from '../constants/storyStatusTypes';
import IntentionTypes from '../constants/intentionTypes';
import SourceTypes from '../constants/sourceTypes';
import Store from '../store/store';
import { isKanbanMode } from './scrumHelper';

export const scrumSprint = (workPackage, isKanban) => {
  const { pathname } = window.location;

  return (
    !isKanban &&
    workPackage.cadence_ids &&
    (pathname.indexOf('/readywall') !== -1 ||
      pathname.indexOf('/workwall') !== -1)
  );
};

export const isTaskMoveableOnCardWall = () => {
  if (isKanbanMode()) return true

  const { pathname } = window.location;
  const { cadence } = Store.getState().cadenceReducer;
  const sprintWalls =
    pathname.indexOf('/readywall') !== -1 ||
    pathname.indexOf('/workwall') !== -1;
  if (sprintWalls) {
    if (cadence?.status === CadenceStatuses.IN_PROGRESS) return true;
    return false;
  }
  return true;
};

export const isTasksListSortedByRank = tasks => {
  const isSortedByRank = (value, index, arr) => {
    const arrSize = arr.length - 1;
    if (index === arrSize) return true;
    return index < arrSize && value.rank <= arr[index + 1].rank;
  };
  return tasks.every(isSortedByRank);
};

export const isTaskMovedToProgress = (movedTask, type) =>
  !movedTask.started && type === SourceTypes.PROGRESS;

export const isTaskMovedToReady = (movedTask, type) =>
  movedTask.started && type !== SourceTypes.PROGRESS;
export const getTaskIntention = status => {
  switch (status) {
    case WorkPackageStatuses.GETTING_READY:
      return IntentionTypes.GETTING_READY;
    case WorkPackageStatuses.READY:
    case WorkPackageStatuses.IN_PROGRESS:
      return IntentionTypes.DEVELOPMENT;
    case WorkPackageStatuses.COMPLETED:
      return IntentionTypes.ACCEPTING;
    case WorkPackageStatuses.ACCEPTED:
      return IntentionTypes.SHIPPING;
    case WorkPackageStatuses.SHIPPABLE:
      return IntentionTypes.DEPLOYING;
    default:
      return IntentionTypes.GETTING_READY;
  }
};

export const hasCompletedDevTask = (tasks, taskTypes) => {
  const devTasks =
    tasks &&
    tasks.filter(task => {
      const taskType = taskTypes.find(tt => tt.id === task.task_type_id);
      return taskType && taskType.intention === IntentionTypes.DEVELOPMENT;
    });
  return devTasks && devTasks.filter(task => task.completed == true).length > 0;
};
