import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { CadenceStatuses } from '../../constants/storyStatusTypes';
import { allows, allowsUser } from '../../utils/authorization';
import { isKanbanMode } from '../../selectors/teamSelector';
import Store from '../../store/store';
import { openModal } from '../../actions/modalActions';
import modalTypes from '../../constants/modalTypes';
import {
  startSprint,
  validateEndDateOfSprint
} from '../../utils/sprintHelpers';

const Manage = ({ sprints, openEditCriteria, isKanban }) => {
  const currentSprint = isKanban
    ? null
    : sprints.find((item) => item.status === CadenceStatuses.IN_PROGRESS);
  const planned = isKanban
    ? null
    : sprints.find(
        (item) =>
          item.status === CadenceStatuses.GETTING_READY && !item.deleted_at
      );
  const allowsEdit = allows('update', currentSprint || planned || {});
  const allowsEditInTeams = allowsUser('update_criteria');

  return (
    <div className="dropdown btn-component-div">
      <button
        type="button"
        id="detail-dropdown"
        className="btn btn-default menu-header"
        aria-haspopup="true"
      >
        Manage
      </button>
      <ul
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdown"
      >
        {currentSprint && allowsEdit && (
          <li onClick={() => validateEndDateOfSprint(currentSprint)}>
            <button type="button" className="btn btn-link">
              End {currentSprint.short_name}
            </button>
          </li>
        )}
        {currentSprint && allowsEdit && (
          <li
            onClick={() =>
              Store.dispatch(
                openModal(modalTypes.cadenceDetail, {
                  id: currentSprint.id
                })
              )
            }
          >
            <button type="button" className="btn btn-link">
              Edit {currentSprint.short_name}
            </button>
          </li>
        )}
        {planned && !currentSprint && allowsEdit && (
          <li onClick={() => startSprint(planned)}>
            <button type="button" className="btn btn-link">
              Start {planned.short_name}
            </button>
          </li>
        )}
        {allowsEditInTeams && (
          <>
            <li onClick={() => openEditCriteria('ready')}>
              <button type="button" className="btn btn-link">
                Define Ready Criteria
              </button>
            </li>
            <li onClick={() => openEditCriteria('done')}>
              <button type="button" className="btn btn-link">
                Define Done Criteria
              </button>
            </li>
            <li onClick={() => openEditCriteria('shippable')}>
              <button type="button" className="btn btn-link">
                Define Shippable Criteria
              </button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isKanban: isKanbanMode(state) || false
});

export default connect(mapStateToProps)(Manage);
