import { getSelectedSpace } from './reduxHelpers';

export const checkIfKanban = (selectedSpace) => {
  if (selectedSpace.type === 'product') return true;
  return selectedSpace.kanban;
};

export const checkIfScrum = (selectedSpace) => {
  if (selectedSpace.type === 'product') return false;
  return !selectedSpace.kanban;
};

export const isKanbanMode = () => {
  const space = getSelectedSpace();
  return checkIfKanban(space);
};
