// eslint-disable-next-line import/prefer-default-export
import { cloneDeep } from 'lodash';
import Store from '../store/store';
import Types from '../constants/actionTypes';
import {
  isAuthorizedWorker,
  isAuthorizedWorkerForTeam,
  isAuthorizedWorkerForProduct,
  isAuthorizedOwnerForTeam,
  isAuthorizedOwnerForProduct
} from './authorization';
import { filterKanbanFilters } from './spaceHelpers';

export const findOwnerInitials = (users, ownerId) => {
  const owner = users.find((user) => user.id === ownerId) || {};

  if (Object.keys(owner).length === 0) return null;

  return owner.initials
    ? owner.initials.toLocaleUpperCase()
    : owner.name
        .split(' ')
        .slice(0, 3)
        .map((n) => n[0])
        .join('')
        .toLocaleUpperCase();
};

export const updateUserWorkspace = () => {
  const saved_space =
    localStorage.selectedSpace !== 'undefined' && localStorage.selectedSpace
      ? JSON.parse(localStorage.getItem('selectedSpace'))
      : {};

  const {
    planwallStates: planwall_defaults,
    savedViews: saved_views,
    previousStates: previous_views,
    kanbanWallState: kanban_state,
    savedKanbanFilters
  } = Store.getState().savedViewReducer;

  const userLocation = window.location.pathname;

  let newSavedKanbanFilters = savedKanbanFilters.filter((kf) =>
    filterKanbanFilters(kf, saved_space)
  );

  const newKanbanFilter = {
    space_id: saved_space.id,
    space_type: saved_space.type,
    filter: kanban_state
  };

  newSavedKanbanFilters = [
    ...newSavedKanbanFilters,
    cloneDeep(newKanbanFilter)
  ];

  Store.dispatch({
    type: Types.UPDATE_USER_LOCATION,
    userLocation
  });

  Store.dispatch({
    type: Types.UPDATE_SAVED_KANBAN_FILTERS,
    views: newSavedKanbanFilters,
    filter: cloneDeep(newKanbanFilter.filter)
  });

  return {
    saved_space_view: {
      saved_space,
      planwall_defaults,
      saved_views,
      previous_views,
      kanban_state,
      saved_kanban_filters: newSavedKanbanFilters,
      user_location: { path: userLocation },
      tour_state: userTourState()
    }
  };
};

export const userTourState = (state = Store.getState()) =>
  state.userReducer.user?.saved_space_view.tour_state || {};

// this checks authorization against the selected space
export const authorizedWorkersForSpace = (users) =>
  users.filter((user) => isAuthorizedWorker(user));

export const authorizedWorkersForTeam = (users, teamId) =>
  users.filter((user) => isAuthorizedWorkerForTeam(user, teamId));

export const authorizedWorkersForProduct = (users, productId) =>
  users.filter((user) => isAuthorizedWorkerForProduct(user, productId));

export const authorizedOwnersForTeam = (users, teamId) =>
  users.filter((user) => isAuthorizedOwnerForTeam(user, teamId));

export const authorizedOwnersForProduct = (users, productId) =>
  users.filter((user) => isAuthorizedOwnerForProduct(user, productId));
