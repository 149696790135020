import queryString from 'query-string';
import Store from '../store/store';
import { openModal } from '../actions/modalActions';
import { findModalType } from '../constants/modalTypes';

const openModalFromParams = nextSearch => {
  const nextParams = queryString.parse(nextSearch);

  if (nextParams.modalName && nextParams.itemId) {
    const modalsInfo = Store.getState().modalReducer.modalInfo;
    let modalId = `${nextParams.modalName}_${nextParams.itemId}`;
    if (nextParams.itemId === 'undefined' || nextParams.itemId === 'NaN')
      modalId = nextParams.modalName;

    const info = modalsInfo[modalId];
    if (info && info.open) return;

    const modalType = findModalType(nextParams.modalName);
    Store.dispatch(
      openModal(modalType, { id: parseInt(nextParams.itemId, 10) })
    );
  }
};

export default openModalFromParams;
