import { offset } from '@floating-ui/dom';

export default [
  {
    id: 'organization-detail',
    text: 'Add important information for your organization on the Details tab',
    selector: 'a#details',
    on: 'bottom',
    tab: 'details'
  },
  {
    id: 'organization-members',
    text: 'Add users to your organization on the Members tab',
    selector: 'a#members',
    on: 'bottom',
    tab: 'members',
    subSteps: {
      members: [
        {
          id: 'add-member-details',
          text: 'Use the plus icon to add a new member with full details',
          selector: '#add-member-details',
          on: 'bottom',
          floatingUIOptions: {
            middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
          }
        },
        {
          id: 'add-member-quick',
          text: 'Quickly add members with default details',
          selector: '#member-name',
          on: 'top-start'
        }
      ]
    }
  },
  {
    id: 'organization-names',
    text: 'Configure the names of work item types and levels on the Types tab',
    selector: 'a#types',
    on: 'bottom',
    tab: 'types'
  }
];
