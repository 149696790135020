import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../Common/checkbox';
import { capitalizeWord } from '../../utils/string';
import useDropDownOpen from '../../hooks/useDropDownOpen';

const ListFilter = ({
  checkedOptions,
  onFilterClick,
  options,
  filter,
  showUnassigned,
  type
}) => {
  const [filterValue, setFilterValue] = useState('');
  const { open, setOpen } = useDropDownOpen();

  const filterOptions = (event) => {
    const input = event.target.value;
    setFilterValue(input);
  };

  const clearInput = () => setFilterValue('');

  const filteredOptions =
    filterValue.length > 0
      ? options.filter((option) =>
          option.short_name.toLowerCase().includes(filterValue)
        )
      : options;

  const handleButtonClick = () => {
    setOpen((prevOpen) => !prevOpen);
    onFilterClick();
  };

  return (
    <div
      id="listTeamFilter"
      className="dropdown"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        type="button"
        id="listTeamFilter"
        className="btn btn-link vrs-filter"
        onClick={handleButtonClick}
      >
        {capitalizeWord(type)}
      </button>
      {open && (
        <ul className="dropdown-menu">
          <form>
            <li>
              {filteredOptions.length > 5 && (
                <input
                  type="text"
                  name="filter"
                  value={filterValue}
                  onChange={filterOptions}
                />
              )}
              {filterValue.length > 0 && (
                <button
                  type="button"
                  className="btn btn-link red right"
                  title="Clear Input"
                  onClick={clearInput}
                >
                  X
                </button>
              )}
            </li>
            {showUnassigned && (
              <li className="options">
                <CheckBox
                  label="Unassigned"
                  value="unassigned"
                  checked={checkedOptions.includes('unassigned')}
                  handleCheckboxChange={filter}
                />
              </li>
            )}
            {filteredOptions.map((option) => (
              <li key={option.id} className="options">
                <CheckBox
                  label={option.short_name || 'Unassigned'}
                  value={option.id}
                  checked={checkedOptions.includes(option.id)}
                  handleCheckboxChange={filter}
                />
              </li>
            ))}
            <li>
              <button
                type="button"
                className="btn btn-xs btn-primary"
                onClick={filter}
              >
                All
              </button>
            </li>
          </form>
        </ul>
      )}
    </div>
  );
};

ListFilter.propTypes = {
  options: PropTypes.array,
  checkedOptions: PropTypes.array,
  filter: PropTypes.func
};

ListFilter.defaultProps = {
  options: [],
  checkedOptions: []
};
export default ListFilter;
