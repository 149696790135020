import React from 'react';
import PropTypes from 'prop-types';
import { Line } from '@vx/shape';
import { Text } from '@vx/text';

// TODO: centralize this code
const colors = {
  'Not Ready': '#be1e2d',
  'Getting Ready': '#df7f26',
  Ready: '#f2cc27',
  'In Progress': '#6f3f64',
  Completed: '#366994',
  Accepted: '#009444',
  Shippable: '#106e38',
  Live: '#939393'
};

const vrsColors = {
  value: '#1A6339',
  risk: '#A63536',
  rroe: '#A63536',
  size: '#1F50A2',
  tc: '#9A3F98'
};

class Lean extends React.Component {
  renderAverageGraph = () => {
    const {
      graphData: data,
      width = 400,
      height = 150,
      workPackage
    } = this.props;

    if (
      !data ||
      data.average_lead_time === undefined ||
      data.lead_time === undefined
    ) {
      return null;
    }

    const states = Object.keys(colors);
    const stateIndex = states.indexOf(workPackage?.state);

    const startHeight = 15;
    const y = startHeight + height / 4;

    const segmentWidth = width / 7;
    const overAverage =
      workPackage &&
      data.average_lead_time > 0 &&
      data.lead_time > data.average_lead_time;

    const hashMarkX = stateIndex < 0 ? segmentWidth : stateIndex * segmentWidth;

    return (
      <g transform="translate(15, -5)">
        <Line
          from={{ x: 0, y: y - 10 }}
          to={{ x: 0, y: y + 10 }}
          stroke="#000000"
          strokeWidth={2}
        />
        <Line
          from={{ x: 0, y }}
          to={{ x: hashMarkX, width, y }}
          stroke="#000000"
          strokeWidth={2}
        />
        <Line
          from={{ x: hashMarkX, y: y - 10 }}
          to={{ x: hashMarkX, y: y + 10 }}
          stroke={colors[workPackage.state]}
          strokeWidth={4}
        />
        <Line
          from={{ x: hashMarkX, y }}
          to={{ x: width, y }}
          stroke={overAverage ? '#be1e2d' : '#000000'}
          strokeWidth={overAverage ? 3 : 2}
        />
        <Line
          from={{ x: width, y: y - 10 }}
          to={{ x: width, y: y + 10 }}
          stroke={overAverage ? '#be1e2d' : '#000000'}
          strokeWidth={overAverage ? 3 : 2}
        />
        <Text
          x={hashMarkX - (stateIndex > 0 && stateIndex < 7 ? 23 : 0)}
          y={y + 15}
          textAnchor={stateIndex < 7 ? 'start' : 'end'}
          verticalAnchor="start"
          style={{ fontWeight: 'bold' }}
        >
          {`Day ${data.lead_time}`}
        </Text>
      </g>
    );
  };

  renderChildAverageGraph = () => {
    const {
      graphData: data,
      width = 400,
      height = 150,
      workPackage
    } = this.props;

    if (!data || data.child_average_lead_time === undefined) {
      return null;
    }

    const startHeight = 15;
    const y = startHeight + height / 4;

    return (
      <g transform="translate(15, -5)">
        <Line
          from={{ x: 0, y: y - 10 }}
          to={{ x: 0, y: y + 10 }}
          stroke="#000000"
          strokeWidth={2}
        />
        <Line
          from={{ x: 0, y }}
          to={{ x: width, y }}
          stroke="#000000"
          strokeWidth={2}
        />
        <Line
          from={{ x: width, y: y - 10 }}
          to={{ x: width, y: y + 10 }}
          stroke="#000000"
          strokeWidth={2}
        />
        <Text
          x={width + 7}
          y={y + 15}
          textAnchor="end"
          verticalAnchor="start"
          style={{ fontWeight: 'bold' }}
        >
          {data.child_average_lead_time}
        </Text>
      </g>
    );
  };

  renderReactionAndCycleTime = (teamData, childData) => {
    const { graphData: data, width = 400, height = 150 } = this.props;

    if (!data) return null;
    let graphData;
    if (childData || teamData)
      graphData = {
        cycle_time: data.child_average_cycle_time,
        lead_time: data.child_average_lead_time,
        reaction_time: data.child_average_reaction_time,
        started_time: data.child_average_started_time
      };
    else
      graphData = {
        started_time: data.started_time,
        reaction_time: data.reaction_time,
        cycle_time: data.cycle_time,
        cycle_time_wip: data.cycle_time_wip,
        lead_time: data.lead_time
      };

    if (
      graphData.reaction_time === undefined ||
      graphData.lead_time === undefined ||
      graphData.lead_time === 0
    ) {
      return null;
    }

    const cycleTime =
      graphData.cycle_time === null
        ? graphData.cycle_time_wip
        : graphData.cycle_time;

    const leadTime =
      graphData.lead_time > 0
        ? graphData.lead_time
        : cycleTime + graphData.started_time;

    const startHeight = height / 2;
    const y = startHeight + height / 4;
    const reactionTimeLength = graphData.reaction_time
      ? (graphData.reaction_time / leadTime) * width
      : 0;
    const startedTimeLength = graphData.started_time
      ? (graphData.started_time / leadTime) * width
      : 0;

    let labelX = 0;

    // eslint-disable-next-line no-return-assign
    return (
      <g transform="translate(15, -8)">
        {(graphData.reaction_time || graphData.reaction_time === 0) && [
          <Text
            y={y + 15}
            x={labelX}
            width={width === 0 ? 1 : width / 2}
            verticalAnchor="start"
          >
            {`Reaction - ${graphData.reaction_time}d`}
          </Text>
        ]}
        {(graphData.started_time || graphData.started_time === 0) && [
          <Text
            y={y + 15}
            x={(labelX += 90)}
            width={width === 0 ? 1 : width / 2 - 10}
            verticalAnchor="start"
          >
            {`Started - ${graphData.started_time}d`}
          </Text>
        ]}
        {cycleTime && [
          <Text
            y={y + 15}
            x={(labelX += 90)}
            width={width === 0 ? 1 : width / 2 - 10}
            verticalAnchor="start"
          >
            {`Cycle Time - ${
              graphData.cycle_time !== null ? cycleTime : `${cycleTime}+` // indicate cycle time is "so far"
            } days`}
          </Text>
        ]}
        <Line
          from={{ x: 0, y: y - 10 }}
          to={{ x: 0, y: y + 10 }}
          stroke="#000000"
          strokeWidth={2}
        />
        {reactionTimeLength > 0 && [
          <Line
            from={{ x: reactionTimeLength, y: y - 8 }}
            to={{ x: reactionTimeLength, y: y + 8 }}
            stroke="#DF7E28"
            strokeWidth={3}
          />,
          <Line
            from={{ x: 0, y }}
            to={{ x: reactionTimeLength, y }}
            stroke="#000000"
            strokeWidth={2}
          />
        ]}
        {startedTimeLength > 0 && [
          <Line
            from={{ x: reactionTimeLength, y }}
            to={{ x: startedTimeLength, y }}
            stroke="#000000"
            strokeWidth={2}
          />
        ]}
        {cycleTime > 0 && [
          <Line
            key={0}
            from={{ x: startedTimeLength, y: y - 8 }}
            to={{ x: startedTimeLength, y: y + 8 }}
            stroke="#009444"
            strokeWidth={3}
          />,
          <Line
            key={1}
            from={{ x: startedTimeLength, y }}
            to={{ x: width, y }}
            stroke="#000000"
            strokeWidth={2}
          />
        ]}
        {(cycleTime === null || cycleTime === 0) && [
          <Line
            key={2}
            from={{ x: 0, y }}
            to={{ x: width, y }}
            stroke="#000000"
            strokeWidth={2}
          />
        ]}
        <Line
          from={{ x: width, y: y - 10 }}
          to={{ x: width, y: y + 10 }}
          stroke="#000000"
          strokeWidth={2}
        />
        <Text
          x={width + 7}
          y={y + 15}
          textAnchor="end"
          verticalAnchor="start"
          style={{ fontWeight: 'bold' }}
        >
          {leadTime}
        </Text>
      </g>
    );
  };

  attributeText = (typeAttributes, optionsName) => {
    const { defaultOptions } = this.props;

    let name = optionsName;

    if (['tc', 'rroe'].includes(optionsName)) {
      name = 'size';
    }

    const neededOptions = defaultOptions[`${name}_defaults`].values;

    if (neededOptions[typeAttributes[optionsName]]) {
      return neededOptions[typeAttributes[optionsName]];
    }

    return null;
  };

  typeAttributeInitial = (kind, attribute) => {
    if (kind === 'Defect' && attribute === 'value') {
      return 'SV';
    }

    switch (attribute) {
      case 'value':
        return 'V';
      case 'risk':
      case 'rroe':
        return 'R';
      case 'tc':
        return 'TC';
      case 'size':
        return 'S';
      default:
        return '';
    }
  };

  generateAttributeText = (typeAttributes, validEntries) => {
    if (validEntries.every((entry) => !entry[1])) {
      return null;
    }

    const {
      resultType: { kind }
    } = this.props;

    const text = validEntries.reduce((acc, entry, index) => {
      if (!acc.length) {
        // first interation
        acc.push('( ');
        acc.push(
          <span style={{ color: vrsColors[entry[0]] }} key={index}>
            {`${this.typeAttributeInitial(kind, entry[0])}: ${
              this.attributeText(typeAttributes, entry[0]) || ''
            }`}
          </span>
        );

        if (index + 1 === validEntries.length) {
          // if this iteration is the last one
          acc.push(' )');
        }
        return acc;
      }

      acc.push(', ');
      acc.push(
        <span style={{ color: vrsColors[entry[0]] }} key={index}>
          {`${this.typeAttributeInitial(kind, entry[0])}: ${
            this.attributeText(typeAttributes, entry[0]) || ''
          }`}
        </span>
      );

      if (index + 1 === validEntries.length) {
        // if this iteration is the last one
        acc.push(' )');
      }
      return acc;
    }, []);

    return text;
  };

  render() {
    const {
      width = 400,
      height = 150,
      workPackage = {},
      graphData: data,
      childData,
      teamData
    } = this.props;
    const { resultType = {} } = this.props;

    const typeAttributes =
      workPackage.id && resultType ? resultType.type_attributes : resultType;

    const entries = Object.entries({
      value: typeAttributes.value,
      risk: typeAttributes.risk,
      size: typeAttributes.size
    });
    const validEntries = entries.filter((entry) => !!entry[1]);
    return (
      <>
        {workPackage.level == 1 && <strong>Lean Metrics</strong>}
        <div className="inner-lean-container">
          <h5>
            <strong>{childData ? 'Child Average Lead Time ' : 'Average Lead Time '}</strong>
            {!childData && validEntries.length > 0
              ? this.generateAttributeText(typeAttributes, validEntries)
              : null}{' '}
            -{' '}
            <strong>
              {teamData || childData
                ? data.child_average_lead_time
                : data.average_lead_time}{' '}
              days
            </strong>
          </h5>
          <svg width={width + 40} height={height}>
            <rect x={0} y={0} width={width + 40} height={height} fill="#DBDCDD" />
            {childData || teamData
              ? this.renderChildAverageGraph()
              : this.renderAverageGraph()}
            {this.renderReactionAndCycleTime(teamData, childData)}
          </svg>
        </div>
      </>
    );
  }
}

Lean.propTypes = {
  graphData: PropTypes.object,
  workPackage: PropTypes.object,
  resultType: PropTypes.object
};

Lean.defaultProps = {
  graphData: {}
};

export default Lean;
