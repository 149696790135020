import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toggleTrash from '../../actions/trashActions';

const Trash = props => {
  const handleClick = event => {
    event.preventDefault();
    props.toggleTrash();
  };

  const color = props.showTrash ? 'green' : '#eeeeee';

  return (
    <div className="btn-component-div">
      <button type="button" className="btn btn-link" onClick={handleClick}>
        <span
          className="glyphicon glyphicon-trash trashcan"
          style={{ color }}
        />
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  showTrash: state.trashReducer.showTrash
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleTrash }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trash);
