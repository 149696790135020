import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IoCloseSharp } from 'react-icons/io5';
import Holon from '../Buttons/holon';
import Dropdown from '../Buttons/dropdown';
import { allows } from '../../utils/authorization';

class DetailHeader extends Component {
  sumSizePoints = stories =>
    stories.reduce(
      (acc, cur) =>
        acc +
        ((cur.result_type &&
          parseInt(cur.result_type.type_attributes.size, 10)) ||
          0),
      0
    );

  handleProductDelete = () => {
    const { handleDelete, onClose, product } = this.props;
    handleDelete(product.id);
    onClose();
  };

  render() {
    const { product, stories, features, capabilities, epics } = this.props;
    const allowEdit = allows('update', product);
    const allowDelete = allows('destroy', product);

    const completedStories = stories.filter(story => story.completed);
    const completedPoints = this.sumSizePoints(completedStories);
    const totalPoints = this.sumSizePoints(stories);
    const completedFeatures = features.filter(feature => feature.completed);
    const completedCapabilities = capabilities.filter(
      capability => capability.completed
    );
    const completedEpics = epics.filter(epic => epic.completed);

    return (
      <div>
        <div className="col-sm-5">
          <div className="col-sm-1">
            <Dropdown
              blocked={product.blocked}
              handleBlock={this.handleWorkPackageBlock}
              handleRemove={this.handleWorkPackageRemove}
              handleDelete={this.handleProductDelete}
              allowDelete={allowDelete}
              allowEdit={allowEdit}
              type={product.sprint ? product.result_type.kind : ''}
            />
          </div>
          <div className="col-sm-2">
            <strong className={`modal-title ${product.blocked ? 'red' : ''}`}>
              {product.id ? `PR${product.id}` : ''}
            </strong>
          </div>
          <div className="col-sm-8">
            <div className="hours">
              <span>
                # - {completedStories.length}/{stories.length}
              </span>
            </div>
            <div className="hours">
              <span>
                {completedPoints}/{totalPoints} pt{totalPoints === 1 ? '' : 's'}
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-2 text-center">
          <Holon />
        </div>
        <div className="col-sm-5 form-inline text-center">
          <div>
            <div className="col-sm-11 vrs">
              <div className="hours">
                <span>
                  F - {completedFeatures.length}/{features.length}
                </span>
              </div>
              <div className="hours">
                <span>
                  C - {completedCapabilities.length}/{capabilities.length}
                </span>
              </div>
              <div className="hours">
                <span>
                  E - {completedEpics.length}/{epics.length}
                </span>
              </div>
            </div>
            <div className="col-sm-1 detail-close">
              <button
                type="button"
                className="btn-close"
                onClick={this.props.onClose}
              >
                <IoCloseSharp size="2em" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let stories = [];
  let features = [];
  let capabilities = [];
  let epics = [];
  const productId = ownProps.product.id;

  if (productId) {
    const productWorkPackages = state.workPackageReducer.all.filter(
      w => w.product_id === productId
    );

    stories = productWorkPackages.filter(w => w.level === 1);
    features = productWorkPackages.filter(w => w.level === 2);
    capabilities = productWorkPackages.filter(w => w.level === 3);
    epics = productWorkPackages.filter(w => w.level === 4);
  }

  return {
    stories,
    features,
    capabilities,
    epics
  };
};

export default connect(mapStateToProps)(DetailHeader);
