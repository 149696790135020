import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback
} from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';
import { isEmpty, cloneDeep, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { BarStackHorizontal } from '@vx/shape';
import { Group } from '@vx/group';
import { GridColumns } from '@vx/grid';
import { AxisTop, AxisLeft } from '@vx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import {
  updateWorkPackage,
  fetchGanttData
} from '../../actions/workPackageActions';
import SelectField from '../Common/CustomSelect/SelectField';


// Graph styles
const colorLive = 'gray';
const colorShippable = 'rgb(41, 99, 8)';
const colorAccepted = 'rgb(28, 140, 73)';
const colorCompleted = 'rgb(53, 104, 144)';
const colorInProgress = 'rgb(111, 63, 100)';
const colorReady = 'rgb(223, 176, 33)';
const colorGettingReady = 'rgb(194, 124, 1)';
const colorNotAccepted = 'gray';
const colorNotReady = 'rgb(187, 24, 37)';
const colorUncertaintyInterval = 'rgba(187, 24, 37, 0.6)';
const colorGap = 'rgba(0,0,0,0)';
const colorNoData = 'rgba(0,0,0,0.3)';
export const background = 'rgb(219, 220, 221)';
export const backgroundRed = 'rgb(187, 24, 37)';
const defaultMargin = { top: 90, left: 80, right: 40, bottom: 100 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 40,
  backgroundColor: 'rgb(252, 245, 197)',
  color: 'black',
  marginLeft: 20
};

let tooltipTimeout;

const findEarliestDate = (data) => {
  if (!Array.isArray(data)) return findEarliestDate([data]); // parent is an object, treat it as array so we can have the same logic operate on it as its children and descendants
  if (isEmpty(data)) return new Date();

  const dates = [];
  data.forEach((datum) => {
    dates.push(datum.strategic.start_date, datum.projected.start_date);
    // if (!isEmpty(datum.children)) dates.push(findEarliestDate(datum.children)); // recurse on the children, adding the earliest date from each section of the tree to the array
  });
  return dates.sort((a, b) => Date.parse(a) - Date.parse(b))[0]; // find the earliest date from this recursion and return it for consideration by caller
};

const findLargestBar = (data, keys) => {
  if (!Array.isArray(data)) return findLargestBar([data], keys); // parent is an object, treat it as array so we can have the same logic operate on it as its children and descendants
  if (isEmpty(data)) return 0;

  const bars = [];
  data.forEach((datum) => {
    const { strategic, projected, children } = datum;
    const strategicTotal = isEmpty(strategic)
      ? 0
      : keys.reduce((total, k) => total + Number(strategic[k]), 0);
    const projectedTotal = isEmpty(projected)
      ? 0
      : keys.reduce((total, k) => total + Number(projected[k]), 0);

    bars.push(projectedTotal, strategicTotal);
    if (!isEmpty(children)) bars.push(findLargestBar(children, keys)); // recurse on the children, adding the largest bar from each section of the tree to the array
  });

  // sort bars by value using lodash since Array.sort() sorts alphabetically!
  return sortBy(bars)[bars.length - 1];
};

const generateBarNames = (data) => {
  if (!Array.isArray(data)) return generateBarNames([data]); // parent is an object, treat it as array so we can have the same logic operate on it as its children and descendants
  if (isEmpty(data)) return [];

  const barNames = [];
  data.forEach((datum) => {
    const { strategic, projected, children } = datum;
    if (!isEmpty(strategic)) {
      const strategicBarName = `${strategic.name}`;
      barNames.push(strategicBarName);
    }
    if (!isEmpty(projected)) {
      const projectedBarName = `${projected.name}`;
      const uncertaintyBarName = `${projected.projected_completion.name}`;
      barNames.push(projectedBarName, uncertaintyBarName);
    }
    // if (!isEmpty(datum.children)) {
    //   const childBarNames = generateBarNames(children); // recurse on the children, adding all barNames generated by this level of the tree
    //   barNames.push(...childBarNames);
    // }
  });
  return barNames.reverse();
};

const generateBars = (data) => {
  if (!Array.isArray(data)) {
    return generateBars([data]); // parent is an object, treat it as array so we can have the same logic operate on it as its children and descendants
  }
  if (isEmpty(data)) return [];

  const bars = [];
  data.forEach((datum) => {
    const { strategic, projected } = cloneDeep(datum);
    const hasStrategic = !isEmpty(strategic);
    if (hasStrategic) {
      strategic.name = `${strategic.name}`;
      strategic.type = 'Strategic';
      bars.push(strategic);
    }
    if (!isEmpty(projected)) {
      projected.name = `${projected.name}`;
      projected.type = 'Projected';
      const uncertainty = {
        ...projected.projected_completion,
        name: `${projected.projected_completion.name}`,
        type: 'Interval',
        uncertainty_days: projected.projected_completion.uncertainty_days,
        uncertainty_end: projected.projected_completion.uncertainty_end,
        uncertainty_interval:
          projected.projected_completion.uncertainty_interval,
        has_strategic: hasStrategic
      };
      bars.push(projected, uncertainty);
    }
  });
  return bars;
};

// Height and width are important and need to be set as numbers
const Gantt = ({
  width = 500,
  height,
  margin = defaultMargin,
  workPackage,
  // eslint-disable-next-line no-shadow
  updateWorkPackage,
  // eslint-disable-next-line no-shadow
  fetchGanttData,
  data = []
}) => {
  // bounds
  const xMax = width;

  const [openDatePicker, setOpenDatePicker] = useState(false);

  const {
    showTooltip,
    hideTooltip,
    tooltipTop,
    tooltipLeft,
    tooltipOpen,
    tooltipData
  } = useTooltip();

  const pointsOrCountOptions = [
    { label: 'Story Points', value: 'points' },
    { label: 'Story Count', value: 'count' }
  ];

  const [pointsOrCount, setPointsOrCount] = useState(pointsOrCountOptions[0]);

  const datePickerRef = useRef();

  const startDate = findEarliestDate(data); // , [data]);

  const handleOffClick = useCallback((e) => {
    if (datePickerRef && !datePickerRef.current.contains(e.target)) {
      setOpenDatePicker(false);
    }
  }, []);

  useEffect(() => {
    if (openDatePicker) {
      document.addEventListener('click', handleOffClick);
    } else {
      document.removeEventListener('click', handleOffClick);
    }
  }, [openDatePicker]);

  useEffect(() => {
    if (workPackage.strategic_end_date) {
      // call api for graph data again

      const { value } = pointsOrCount;
      if (value === 'count') {
        fetchGanttData(workPackage.id, true);
      } else {
        fetchGanttData(workPackage.id, false);
      }
    }
  }, [workPackage.strategic_end_date, pointsOrCount]);

  // Booking statuses
  // order MUST align with colorScale range
  const keys = [
    'gap',
    'uncertainty_interval',
    'live',
    'shippable',
    'accepted',
    'completed',
    'in_progress',
    'ready',
    'getting_ready',
    'not_ready',
    'not_accepted',
    'no_data'
  ];

  // It calculates the x maximum value
  const largestBar = findLargestBar(data, keys);

  // Scales
  // Responsible to set the min and max of x and y axis
  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, largestBar * 1.25],
        nice: false
      }),
    [data]
  );

  const getBarName = (d) => d.name;
  const barNames = useMemo(() => generateBarNames(data), [data]);

  const yScale = useMemo(
    () =>
      scaleBand({
        domain: barNames,
        paddingInner: 1
      }),
    [data]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: keys,
        range: [
          // order must align with the array of keys order
          colorGap,
          colorUncertaintyInterval,
          colorLive,
          colorShippable,
          colorAccepted,
          colorCompleted,
          colorInProgress,
          colorReady,
          colorGettingReady,
          colorNotReady,
          colorNotAccepted,
          colorNoData
        ]
      }),
    [keys]
  );

  xScale.rangeRound([0, xMax]);
  yScale.rangeRound([130, 60]);

  const validStartDate = (currentDate) => currentDate.isAfter(startDate);

  const handleDateChange = (date) => {
    updateWorkPackage(workPackage.id, {
      // eslint-disable-next-line no-underscore-dangle
      work_package: { strategic_end_date: date._d }
    });
  };

  const toggleDatePicker = (e) => {
    const calendarNode = document.querySelector('.rdtPicker');
    if (calendarNode?.contains(e.target) && e.target.className !== 'rdtDay')
      return;
    setOpenDatePicker((prevState) => !prevState);
  };

  const closeDatePicker = () => {
    if (openDatePicker) {
      setOpenDatePicker(false);
    }
  };

  const handleMouseOverBar = (event, newData) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: newData
    });
  };

  const calculateBarVerticalPosition = (bar) => {
    const { data: barData } = bar.bar;
    if (barData.type === 'Interval')
      return bar.y - (barData.has_strategic ? 5 : 40);
    return bar.y;
  };

  const generateGanttBars = (result, groupIndex = 0, groupHeight) => (
    <Group top={groupIndex * groupHeight}>
      <BarStackHorizontal
        data={generateBars(result)}
        keys={keys}
        height={groupHeight}
        y={getBarName}
        xScale={xScale}
        yScale={scaleBand({
          rangeRound: [groupHeight, 60],
          domain: barNames,
          paddingInner: 1
        })}
        color={colorScale}
      >
        {(barStacks) => (
          <React.Fragment key="barstack-horizontal">
            {barStacks.map((barStack) =>
              barStack.bars.map((bar) => (
                <rect
                  key={bar.index}
                  x={bar.x}
                  y={calculateBarVerticalPosition(bar)}
                  width={bar.width}
                  height={30}
                  fill={bar.color}
                  onMouseMove={(e) => {
                    if (tooltipTimeout) clearTimeout(tooltipTimeout);
                    handleMouseOverBar(e, bar.bar.data.tooltip_data[bar.key]);
                  }}
                  onMouseLeave={() => {
                    tooltipTimeout = window.setTimeout(() => {
                      hideTooltip();
                    }, 300);
                  }}
                />
              ))
            )}
          </React.Fragment>
        )}
      </BarStackHorizontal>
    </Group>
  );

  return width < 10 ? null : (
    <div className={`gantt-chart gantt-level-${workPackage.level}`}>
      <div className="gantt-selectors" onClick={(e) => e.stopPropagation()}>
        <div className="strat-end-date-selector">
          <strong>Strategic End Date: </strong>
          <div
            className="gantt-date-container"
            onClick={toggleDatePicker}
            ref={datePickerRef}
          >
            <DateTime
              dateFormat="MM-DD-YYYY"
              open={openDatePicker}
              timeFormat={false}
              isValidDate={validStartDate}
              onChange={handleDateChange}
              value={moment(workPackage.strategic_end_date)}
            />
            <div className="date-caret" />
          </div>
        </div>
        <div
          className="story-points-dropdown-container"
          onClick={closeDatePicker}
        >
          <SelectField
            options={pointsOrCountOptions}
            selectedOption={pointsOrCount}
            handleOptionChange={setPointsOrCount}
          />
        </div>
      </div>
      <svg
        width={width}
        height={height + 20}
        style={{ overflow: 'visible', paddingLeft: '27px' }}
      >
        <rect y="20" width={width} height={height} fill={background} />
        <GridColumns
          top={20}
          scale={xScale}
          height={height}
          stroke="black"
          strokeOpacity={0.3}
          numTicks={7}
          strokeDasharray="2"
        />
        <Group left={0}>
          <AxisTop
            labelOffset={50}
            top={20}
            numTicks={7}
            scale={xScale}
            // x axis is formatted to show the dates based on number of days
            tickFormat={(d) =>
              moment(startDate).add(d, 'days').format('MM/DD/YY')}
            stroke="black"
            tickStroke="black"
            strokeWidth={3}
            tickLabelProps={() => ({
              fill: 'black',
              fontSize: 12,
              textAnchor: 'middle'
            })}
          />
          {generateGanttBars(data, 0, 130)}
          <AxisLeft
            scale={yScale}
            left={140}
            top={15}
            tickClassName="gantt-bar-label"
            tickLabelProps={() => ({
              fill: background,
              fontSize: 12,
              textAnchor: 'end'
            })}
            stroke="rgba(0,0,0,0)"
            tickStroke="rgba(0,0,0,0)"
          />
        </Group>
      </svg>
      {tooltipOpen && tooltipData?.length && (
        <TooltipWithBounds
          top={tooltipTop + 20}
          left={tooltipLeft}
          key={Math.random()}
          style={tooltipStyles}
        >
          {tooltipData.map((resultLabel, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`tooltip-label-${index}`}>{resultLabel}</div>
          ))}
        </TooltipWithBounds>
      )}
    </div>
  );
};

export default connect(null, { updateWorkPackage, fetchGanttData })(Gantt);
