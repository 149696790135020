import Types from '../constants/actionTypes';

const initialState = {
  showTrash: false
};

const trashReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.TOGGLE_TRASH:
      return { ...state, showTrash: !state.showTrash };
    default:
      return state;
  }
};

export default trashReducer;
