import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDefaultOrgLevels } from '../../selectors/organizationSelector';

const Kanban = ({ defaultOrgLevels }) => (
  <div className="dropdown btn-component-div">
    <button
      type="button"
      id="detail-dropdown"
      className="btn btn-default menu-header"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Card Wall
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdown">
      <li>
        <Link to="/cardwall/kanban/r/1" className="btn btn-link">
          Kanban
        </Link>
      </li>
      {defaultOrgLevels
        .sort((a, b) => (a.level < b.level ? -1 : a.level == b.level ? 0 : 1))
        .reduce((acc, currentLevel, index) => {
          if (currentLevel.level === 1) return acc;
          acc.push(
            <li key={index}>
              <Link
                to={`/cardwall/kanban/r/${currentLevel.level}`}
                className="btn btn-link"
              >
                {currentLevel.name} Kanban
              </Link>
            </li>
          );
          return acc;
        }, [])}
    </ul>
  </div>
);

const mapStateToProps = (state) => {
  const defaultOrgLevels = getDefaultOrgLevels(state);
  return { defaultOrgLevels };
};

export default connect(mapStateToProps)(Kanban);
