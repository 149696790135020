import React from 'react';
import StoryList from '../PlanWall/storyList';
import AddStory from '../Common/addStory';

const CadenceStoriesTab = ({
  results,
  tableFields,
  dropDown,
  setDropdown,
  cadence,
  currentUser,
  allowEdit,
  organizationLevel
}) => {
  return (
    <div role="tabpanel" className="table" id="stories">
      <div className="stories-list">
        <StoryList
          results={results}
          source="CadenceDetail"
          fields={tableFields}
          dropDown={dropDown}
          setDropdown={setDropdown}
        />
        <AddStory
          cadenceId={cadence.id}
          ownerId={currentUser.id}
          disabled={!allowEdit}
          level={1}
          organizationLevel={organizationLevel}
          label="STORY"
          placement='cadence'
        />
      </div>
    </div>
  );
};

export default CadenceStoriesTab;
