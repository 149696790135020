import { offset } from '@floating-ui/dom';
import _ from 'lodash';
import Shepherd from './shepherd';
import { showingTourStep, closingTour } from '../actions/tourActions';
import Store from '../store/store';

export const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 12 })]
    }
  },
  useModalOverlay: false
};

export const createTour = () => {
  const tour = new Shepherd.Tour({
    useModalOverlay: false,
    defaultStepOptions: tourOptions
  });
  return tour;
};

const createStepButtons = (tour, first, last, stepDefinition) => {
  const buttons = [];
  if (!first) {
    buttons.push({ text: 'Previous', action: tour.back.bind(tour) });
  }
  if (stepDefinition.navigate) {
    const { text, route } = stepDefinition.navigate;
    buttons.push({ text, route });
  }
  if (!last) {
    buttons.push({ text: 'Next', action: tour.next.bind(tour) });
  }
  return buttons;
};

const arrowStyling = (position) => {
  if (!position) return '';
  if (position.startsWith('left')) return 'arrow-right';
  if (position.startsWith('right')) return 'arrow-left';
  if (position.startsWith('top')) return 'arrow-down';
  if (position.startsWith('bottom')) return 'arrow-up';
  return '';
};

const addStep = (tour, stepDefinition, firstStep, lastStep) => {
  const step = {
    ...stepDefinition,
    attachTo: {
      element: stepDefinition.selector,
      on: stepDefinition.on
    },
    buttons: createStepButtons(tour, firstStep, lastStep, stepDefinition).map(
      (button) => {
        let { action } = button;
        switch (button.action) {
          case 'next':
            action = tour.next.bind(tour);
            break;
          case 'back':
            action = tour.back.bind(tour);
            break;
          case 'cancel':
            action = tour.cancel.bind(tour);
            break;
          case 'complete':
            action = tour.complete.bind(tour);
            break;
          default:
        }
        return { ...button, action };
      }
    ),
    classes: arrowStyling(stepDefinition.on),
    when: {
      show: () => {
        // console.log("show", stepDefinition.id);
        Store.dispatch(showingTourStep(stepDefinition.id));
      },
      // hide: () => {  == hide gets triggered by show for some reason
      //   // console.log("hide", stepDefinition.id);
      //   if (stepDefinition.dispatch_on?.done)
      //     Store.dispatch(stepDefinition.dispatch_on.done);
      // },
      cancel: () => {
        // -- cancel is triggered by escape, but not guideme button click
        // console.log("cancel", stepDefinition.id);
        Store.dispatch(closingTour(stepDefinition.id));
        if (stepDefinition.dispatch_on?.done)
          Store.dispatch(stepDefinition.dispatch_on.done);
      }
    }
  };

  tour.addStep(step);
};

export const addSteps = (tour, steps, tab = 'none') => {
  let nextStep = null;
  steps.forEach((step, index) => {
    if (step.tab === tab) nextStep = step.id;
    const tabSteps = (step.subSteps && step.subSteps[tab]) || [];
    addStep(
      tour,
      step,
      index === 0,
      index === steps.length - 1 && tabSteps.length === 0
    );
    if (tabSteps.length > 0) {
      tabSteps.forEach((subStep, subIndex) =>
        addStep(
          tour,
          subStep,
          false,
          index === steps.length - 1 && subIndex === tabSteps.length - 1
        )
      );
    }
  });
  return nextStep;
};

export const selectStepsByPredicate = (stepDefinitions, state) => {
  const result = [];
  stepDefinitions.forEach((stepDefinition) => {
    if (stepDefinition.predicate) {
      try {
        if (stepDefinition.predicate(state)) {
          result.push(stepDefinition);
        }
      } catch (error) {
        console.error(
          'Error calling predicate function for step',
          stepDefinition.id,
          error
        );
      }
    } else {
      result.push(stepDefinition);
    }
  });
  return result;
};

export const autoShowSteps = (stepDefinitions, state) => {
  const result = [];
  stepDefinitions.forEach((stepDefinition) => {
    if (stepDefinition.autoShow) {
      try {
        if (stepDefinition.autoShow(state)) {
          result.push(stepDefinition);
        }
      } catch (error) {
        console.error(
          'Error calling autoShow function for step',
          stepDefinition.id,
          error
        );
      }
    }
  });
  return result;
};

export const compareSteps = (stepDefinitions, tour = {}) => {
  const tourStepIDs = tour.steps.map((step) => step.id);
  const stepDefIDs = stepDefinitions.map((stepDef) => stepDef.id);

  const changedOrAddedSteps = _.difference(stepDefIDs, tourStepIDs);
  const removedSteps = _.difference(tourStepIDs, stepDefIDs);

  return { changedOrAddedSteps, removedSteps };
};

// export const compareSteps = (stepDefinitions, tour = {}) => {
//   // Check if old and new steps have the same IDs in the same order
//   const stepsIdentical =
//     tour.steps.length === stepDefinitions.length &&
//     tour.steps.every((step, index) => step.id === stepDefinitions[index].id);

//   if (stepsIdentical) return 0;
//   if (stepDefinitions.length < tour.steps.length) return -1;
//   return 1; // note this will also occur if the steps length is the same, but the ids changed. This is intentional, indicating something to pay attention to .
// };
