import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
// import SearchableSelect from './searchableSelect';
import CustomSelect from './CustomSelect/SelectField';

const SelectField = ({
  label,
  options,
  clearable,
  allowEdit,
  handleChange,
  value,
  labelClass,
  searchContainerClass,
  placeholder,
  type_short
}) => {
  const allOptions = options;
  if (clearable) allOptions.unshift({ label: '', value: null });

  const handleSelect = (option) => {
    handleChange(option.value, `${camelCase(label)}_id`);
  };

  return (
    <div className="row detail-select">
      <div className={labelClass}>
        <label htmlFor="owner">{label}</label>
      </div>
      <div className={searchContainerClass}>
        <CustomSelect
          type_short={type_short}
          selectedOption={value}
          handleOptionChange={handleSelect}
          disabled={!allowEdit}
          options={allOptions}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  clearable: PropTypes.bool,
  allowEdit: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.object,
  placeholder: PropTypes.string,
  labelClass: PropTypes.string,
  searchContainerClass: PropTypes.string
};

SelectField.defaultProps = {
  options: [],
  value: {},
  clearable: true,
  placeholder: '',
  labelClass: 'col-sm-3',
  searchContainerClass: 'col-sm-9'
};

export default SelectField;
