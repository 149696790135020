import { getSelectedSpace } from './reduxHelpers';

export const isProductAssociatedWithSpace = (currValue) => {
  const selectedSpace = getSelectedSpace();

  if (selectedSpace.type === 'product') {
    return selectedSpace.id === currValue;
  }

  if (!currValue) return true;

  const productIds = selectedSpace.products.map((p) => p.id);
  return productIds.includes(currValue);
};

export const isTeamAssociatedWithSpace = (currValue) => {
  const selectedSpace = getSelectedSpace();

  if (selectedSpace.type === 'team') {
    return selectedSpace.id === currValue;
  }

  if (!currValue) return true;

  const teamIds = selectedSpace.teams.map((t) => t.id);
  return teamIds.includes(currValue);
};

export const filterKanbanFilters = (filter, space) => {
  if (filter.space_id === space.id) {
    return filter.space_type !== space.type;
  }

  if (filter.space_type === space.type) {
    return filter.space_id !== space.id;
  }

  return true;
};

export const newKanbanFilters = (
  selectedSpace,
  savedKanbanFilters,
  kanbanWallState
) => {
  let newFilters = savedKanbanFilters.filter((kf) =>
    filterKanbanFilters(kf, selectedSpace)
  );
  newFilters = [
    ...newFilters,
    {
      space_id: selectedSpace.id,
      space_type: selectedSpace.type,
      filter: kanbanWallState
    }
  ];
  return newFilters;
};
