import CogMenu from '../components/Buttons/cogMenu';
import StateMenu from '../components/Buttons/stateMenu';
import VrsMenu from '../components/Buttons/vrsMenu';
import TypeMenu from '../components/Buttons/typeMenu';
import ChangeOwner from '../components/Buttons/changeOwner';
import UpdateMember from '../components/Buttons/updateMember';

const dropDownTypes = {
  cogMenu: { name: 'cogMenu', component: CogMenu },
  stateMenu: { name: 'stateMenu', component: StateMenu },
  vrsMenu: { name: 'vrsMenu', component: VrsMenu },
  typeMenu: { name: 'typeMenu', component: TypeMenu },
  changeOwner: { name: 'changeOwner', component: ChangeOwner },
  updateMember: { name: 'updateMember', component: UpdateMember }
};

export default dropDownTypes;
