import React from 'react';

const CadenceTasksTab = ({}) => {
  return (
    <div role="tabpanel" id="tasks">
      YET TO BE IMPLEMENTED
    </div>
  );
};

export default CadenceTasksTab;
