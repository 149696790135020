import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { findOwnerInitials } from '../../utils/userHelpers';
import { isColorBright, convertHexToRgb } from '../../utils/colorHelpers';

const UserInitialsButton = ({
  className,
  onClick,
  isDisabled,
  userId,
  users
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const initials = findOwnerInitials(users, userId);
  const user = users.find((u) => u.id === userId) || {};
  const generateColor = () => {
    const rgbObject = convertHexToRgb(user.color);
    if (!rgbObject) return {};

    const useBlackFont = isColorBright(rgbObject.r, rgbObject.g, rgbObject.b);

    return {
      backgroundColor: `rgb(${rgbObject.r},${rgbObject.g},${rgbObject.b})`,
      color: `${useBlackFont ? 'black' : 'white'}`
    };
  };

  const initialsElement = (
    <div className="initial" style={generateColor()}>
      {initials || '\u00A0'}
    </div>
  );
  const tooltipInitials = (
    <Tooltip
      isOpen={showTooltip}
      direction="up"
      content={
        <div className="custome-tooltip"> {user.nickname || user.name}</div>
      }
    >
      {initialsElement}
    </Tooltip>
  );

  return (
    <button
      type="button"
      className={`btn btn-link ${className}`}
      disabled={isDisabled}
      onClick={onClick}
      onMouseEnter={() => {
        if (initials && !showTooltip) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => {
        if (showTooltip) {
          setShowTooltip(false);
        }
      }}
    >
      {tooltipInitials}
    </button>
  );
};

UserInitialsButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  userId: PropTypes.number,
  users: PropTypes.array
};
UserInitialsButton.defaultProps = {
  className: '',
  isDisabled: false
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(UserInitialsButton);
