import React from 'react';
import PropTypes from 'prop-types';

const DropZone = ({ isListVisible, isStoryOver, listHeight, listWidth }) => (
  <div
    className="is-drop-zone"
    style={{
      position: isListVisible ? 'absolute' : 'relative',
      display: isStoryOver ? 'flex' : 'none',
      width: listWidth > 286 ? `${listWidth}px` : `286px`,
      height: isListVisible ? `${listHeight}px` : '200px',
      cursor: 'copy'
    }}
  />
);

DropZone.propTypes = {
  isListVisible: PropTypes.bool,
  isStoryOver: PropTypes.bool,
  listHeight: PropTypes.number,
  listWidth: PropTypes.number
};

DropZone.defaultProps = {
  listWidth: 286
};

export default DropZone;
