import { createSelector } from 'reselect';
import { allowsThroughOrganizationOrTeam } from '../utils/authorization';

export const getProducts = (state) => state.productReducer.products;
const getOrgParentId = (_, props) => props.parentId;

const getOrgFromProps = (_, props) => props.organization;
const getShowTrash = (state) => state.trashReducer.showTrash;

// this function is used for the profile modal
export const filterProductsByOrg = createSelector(
  [getProducts, getOrgParentId, getShowTrash],
  (products, orgId, showTrash) => {
    return products
      .filter((product) => product.organization_id === orgId)
      .filter((product) => {
        if (showTrash) return true;
        return !product.deleted_at;
      });
  }
);

export const filterProductsByOrgForOrgWall = createSelector(
  [getProducts, getOrgFromProps, getShowTrash],
  (products, organization, showTrash) => {
    return products
      .filter((p) => p.organization_id === organization.id)
      .filter((p) => {
        if (showTrash) return true;
        return !p.deleted_at;
      });
  }
);

export const getUpdatableProducts = createSelector(getProducts, (products) => {
  return products.filter(
    (product) =>
      product.teams.some((t) =>
        allowsThroughOrganizationOrTeam('update', null, t.id)
      ) ||
      allowsThroughOrganizationOrTeam('update', product.organization_id, null)
  );
});
