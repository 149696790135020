let queue = [];
let processing = false;

function setQueue(newQueue) {
  queue = newQueue;
}

export function addToQueue(fn, params) {
  queue.push(
    queueLength =>
      new Promise((resolve, reject) => {
        fn(...params, queueLength)
          .then(() => {
            resolve('done');
          })
          .catch(error => {
            reject(error);
          });
      })
  );
  return queue;
}

export async function executeQueue(callback = () => null) {
  if (queue.length > 0) {
    processing = true;
    const result = await queue[queue.length - 1](queue.length - 1);
    if (result === 'done') {
      queue.shift();
      executeQueue(callback);
    } else {
      setQueue([]);
    }
  } else {
    processing = false;
    callback();
  }
}

export function currentQueue() {
  return queue;
}

export function isProcessing() {
  return processing;
}
