import React from 'react';
import PropTypes from 'prop-types';
import Default from '../../constants/defaults';

const payload = (originalData, type, newValue) => {
  const newData = {};
  newData[type] = newValue;
  return { type_attributes: newData };
};

const VrsMenu = (props) => {
  const { classes, handleSelect, attributes, field, options, level } = props;
  const displayOptions =
    level === 1
      ? Object.keys(options)
          .filter((key) => key <= Default.LIMIT_VRS_VALUE)
          .reduce(
            (obj, key) => ({
              ...obj,
              [key]: options[key]
            }),
            {}
          )
      : options;

  const generateBackgroundColor = () => {
    switch (field) {
      case 'value':
        return '#96cca1';
      case 'risk':
      case 'rroe':
        return '#ce9191';
      case 'size':
        return '#8cb8ba';
      case 'tc':
        return '#c09fc1';
      default:
    }
  };

  const generateCheckmarkBorder = () => {
    let color;
    switch (field) {
      case 'value':
        color = '#196634';
        break;
      case 'risk':
      case 'rroe':
        color = '#661818';
        break;
      case 'size':
        color = '#175266';
        break;
      case 'tc':
        color = '#632863';
        break;
      default:
    }

    return { border: `1px solid ${color}` };
  };

  const generateSelectedStyle = (option, optionsObject) => {
    if (typeof optionsObject[option] === Number) {
      return Number(attributes[field]) === optionsObject[option];
    }

    return Number(attributes[field]) === Number(option);
  };

  return (
    <ul
      className={`dropdown dropdown-menu result-dropdown-menu ${classes}`}
      style={{ backgroundColor: generateBackgroundColor() }}
    >
      {Object.keys(displayOptions).map((key) => {
        const checked = generateSelectedStyle(key, displayOptions);

        return (
          <li
            key={key}
            onClick={() => handleSelect(payload(attributes, field, key))}
          >
            <label className="custom-checkbox">
              {displayOptions[key]}
              <input type="checkbox" value={key} checked={checked} readOnly />
              <span className="checkmark" style={generateCheckmarkBorder()} />
            </label>
          </li>
        );
      })}
      <button
        className="vrs-dropdown-clear"
        onClick={() => handleSelect(payload(attributes, field, null))}
        style={generateCheckmarkBorder()}
      >
        {' '}
        Clear
      </button>
    </ul>
  );
};

VrsMenu.propTypes = {
  field: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  attributes: PropTypes.object,
  classes: PropTypes.string
};

VrsMenu.defaultProps = {
  classes: ''
};

export default VrsMenu;
