import { resultCardId } from './string';

// eslint-disable-next-line import/prefer-default-export
export function filterBySearchText(workPackages, searchText) {
  const includesSearchText = wp =>
    wp.short_name.toLowerCase().indexOf(searchText) !== -1 ||
    resultCardId(wp)
      .toLowerCase()
      .indexOf(searchText) !== -1;

  return workPackages.filter(includesSearchText);
}
