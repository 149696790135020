import React from 'react';
import PropTypes from 'prop-types';

const Option = ({
  option,
  index,
  active,
  setActiveIndex,
  handleOptionClick
}) => {
  const handleMouseEnter = () => {
    setActiveIndex(index);
  };

  const handleOnClick = () => {
    handleOptionClick(option.label);
  };

  return (
    <div
      className={`custom-select-option ${active ? 'active' : ''} ${
        option.disabled ? 'disabled' : ''
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleOnClick}
    >
      <span className="dropdown-option">{option.label}</span>
    </div>
  );
};

Option.propTypes = {
  option: PropTypes.object,
  index: PropTypes.number,
  active: PropTypes.bool,
  setActiveIndex: PropTypes.func,
  handleOptionClick: PropTypes.func
};

export default Option;
