import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { orderBy } from 'lodash';
import { userLimitModal } from '../../actions/organizationActions';
import newMenuButton from '../../../assets/images/new-menu-button.png';
import newTask from '../../../assets/images/new-task-icon.png';
import newStory from '../../../assets/images/new-story-icon.png';
import newFeature from '../../../assets/images/new-feature-icon.png';
import newCapability from '../../../assets/images/new-capability-icon.png';
import newEpic from '../../../assets/images/new-epic-icon.png';
import newProduct from '../../../assets/images/new-product-icon.png';
import newSprint from '../../../assets/images/new-sprint-icon.png';
import newTarget from '../../../assets/images/new-target-icon.png';
import newUser from '../../../assets/images/new-user-icon.png';
import newTeam from '../../../assets/images/new-team-icon.png';
import { openModal } from '../../actions/modalActions';
import modalTypes from '../../constants/modalTypes';
import { allowsUser } from '../../utils/authorization';
import { createNewTeamHelper } from '../../utils/teamHelpers';
import { isKanbanMode } from '../../selectors/teamSelector';
import { getSelectedSpace } from '../../utils/reduxHelpers';

const New = ({
  addItem,
  organization,
  isKanban,
  selectedSpace,
  currentProducts,
  user,
  ...props
}) => {
  const allowsCreateWorkPackage = allowsUser('create_work_packages');
  const allowsCreateCadence = allowsUser('create_cadences');
  const allowsCreateTarget = allowsUser('create_targets');
  const allowsCreateUser = allowsUser('create_user_organizations');
  const allowsCreateTeam = allowsUser('create_targets');
  const allowsAdmin = allowsUser('administer_organizations');
  const organizationLevels = organization
    ? orderBy(organization.organization_levels, ['level', 'name'])
    : [];

  // introduced hook logic to handle clicking on new team option on list.
  const [open, setOpen] = useState(false);

  if (
    (!allowsCreateWorkPackage &&
      !allowsCreateCadence &&
      !allowsCreateTarget &&
      !allowsCreateUser &&
      !allowsCreateTarget) ||
    selectedSpace.id === 'unassigned'
  )
    return null;

  const addUser = () => {
    const { member_limit, active_member_count } = organization;
    if (member_limit && active_member_count >= member_limit) {
      props.userLimitModal(member_limit);
      return;
    }

    props.openModal(
      modalTypes.newUser,
      { parentId: organization.id, isNew: true },
      'user'
    );
  };

  // Note - it it not entirely clear how the toggle works for this to render... but it does
  return (
    <div className="dropdown btn-component-div new-menu">
      <button
        type="button"
        id="detail-dropdown"
        className="btn new-menu-button"
        onMouseEnter={() => setOpen(true)}
      >
        <img
          src={newMenuButton}
          className="new-menu-button-image"
          alt="add items"
        />
      </button>
      {open && (
        <ul className="new-menu-dropdown">
          {allowsCreateWorkPackage && (
            <>
              <li className="category">
                <span>WORK</span>
              </li>
              {/*<li>
                <button
                  type="button"
                  className="new-menu-button-small"
                  onClick={() =>
                    props.openModal(
                      modalTypes.newTask,
                      { newMenu: true, owner: user },
                      'newTask'
                    )}
                >
                  <div className="new-menu-dropdown-image">
                    <img src={newTask} alt="New Task" />
                  </div>
                  <span>Task</span>
                </button>
              </li>*/}
              {organizationLevels
                .filter((orgLevel) => !orgLevel.deleted_at)
                .map((orgLevel) => {
                  let imgSource = newStory;
                  if (orgLevel.level === 2) imgSource = newFeature;
                  if (orgLevel.level === 3) imgSource = newCapability;
                  if (orgLevel.level === 4) imgSource = newEpic;
                  return (
                    <li key={orgLevel.id}>
                      <button
                        type="button"
                        className="new-menu-button-small"
                        onClick={() => addItem(orgLevel.level, orgLevel.id)}
                      >
                        <div className="new-menu-dropdown-image">
                          <img src={imgSource} alt="New Story" />
                        </div>
                        <span>{orgLevel.name}</span>
                      </button>
                    </li>
                  );
                })}
            </>
          )}
          {allowsAdmin && (
            <>
              <li>
                <button
                  type="button"
                  className="new-menu-button-small"
                  onClick={() =>
                    props.openModal(
                      modalTypes.newProduct,
                      {
                        teamMemberWall: true,
                        parentId: organization.id
                      },
                      'productCard'
                    )}
                >
                  <div className="new-menu-dropdown-image">
                    <img src={newProduct} alt="New Product" />
                  </div>
                  <span>Product</span>
                </button>
              </li>
            </>
          )}
          {((allowsCreateCadence && !isKanban) || allowsCreateTarget) && (
            <>
              <li className="category">
                <span>TIME</span>
              </li>
              {allowsCreateCadence && !isKanban && (
                <li>
                  <button
                    type="button"
                    className="new-menu-button-small"
                    onClick={() =>
                      props.openModal(modalTypes.cadenceDetail, {
                        cadence: {}
                      })}
                  >
                    <div className="new-menu-dropdown-image">
                      <img src={newSprint} alt="New Sprint" />
                    </div>
                    <span>Sprint</span>
                  </button>
                </li>
              )}
              {allowsCreateTarget && currentProducts.length > 0 && (
                <li>
                  <button
                    type="button"
                    className="new-menu-button-small"
                    onClick={() =>
                      props.openModal(modalTypes.targetDetail, {
                        target: {}
                      })}
                  >
                    <div className="new-menu-dropdown-image">
                      <img src={newTarget} alt="New Target" />
                    </div>
                    <span>Target</span>
                  </button>
                </li>
              )}
            </>
          )}
          {allowsCreateUser && allowsCreateTeam && (
            <>
              <li className="category">
                <span>PEOPLE</span>
              </li>
              {allowsCreateUser && (
                <li>
                  <button
                    type="button"
                    className="new-menu-button-small"
                    onClick={addUser}
                  >
                    <div className="new-menu-dropdown-image">
                      <img src={newUser} alt="New User" />
                    </div>
                    <span>User</span>
                  </button>
                </li>
              )}
              {allowsCreateTeam && (
                <li>
                  <button
                    type="button"
                    className="new-menu-button-small"
                    onClick={() => {
                      props.openModal(
                        modalTypes.smallNew,
                        {
                          type: 'team',
                          submitFn: createNewTeamHelper,
                          id: 'newTeamCard',
                          organizationId: organization.id
                        },
                        'newTeamCard'
                      );
                      setOpen(false);
                    }}
                  >
                    <div className="new-menu-dropdown-image">
                      <img src={newTeam} alt="New Team" />
                    </div>
                    <span>Team</span>
                  </button>
                </li>
              )}
            </>
          )}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const selectedSpace = getSelectedSpace();
  const orgId = selectedSpace.organization_id;
  const organization = orgId
    ? state.organizationReducer.organizations.find((org) => {
        return org.id === orgId;
      })
    : undefined;
  return {
    organization,
    isKanban: isKanbanMode(state),
    currentProducts: state.defaultOptionsReducer.currentProducts,
    selectedSpace,
    user: state.userReducer.user
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal,
      userLimitModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(New);
